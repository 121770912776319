import { useQuery } from '@tanstack/react-query'
import templateInvoiceApi from 'api/templateInvoiceApi'
import _ from 'lodash'
import { useState } from 'react'

const keyFactory = {
  base: { scope: ['shared', 'ticketTemplates'] },
  list: (f) => [
    {
      ...keyFactory.base,
      params: f,
    },
  ],
}

export default function useGetTicketTemplates({ ticketType = 'HD' }) {
  const filterParams = {
    invoiceType: 'TICKET',
    page: 1,
    limit: 100,
    q: '',
    invoiceClassify: ticketType,
  }
  return useQuery({
    staleTime: 1000 * 50, // Stay fresh for 50 seconds
    refetchOnWindowFocus: true,
    queryKey: keyFactory.list(filterParams),
    queryFn: () => templateInvoiceApi.getTemplates(filterParams),
    select: (res) => {
      // if (res.result !== 'success') return []
      return _.isEmpty(res?.templates)
        ? []
        : _.map(res?.templates, (template) => {
            const { form, hasTaCode, year, type, managementCode } = template
            const serial = form + hasTaCode + year + type + managementCode
            return { ...template, serial }
          })
    },
  })
}
