import { useLocalStorage } from '@uidotdev/usehooks'
import { Checkbox, Flex, Popover, Typography } from 'antd'
import { setDynamicColumns, setVisualAppears } from 'app/tableSettingSlice'
import Utils from 'general/utils/Utils'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import CustomAntButton from '../Button/CustomAntButton'
import CustomAntInput from '../Forms/CustomAntInput/CustomAntInput'
import DnDColumnList from './components/DnDColumnList'
import { arrayMove, transformingElements } from './functions/helpers'

/**
 * @template T
 * @param {Object} props
 * @param {Array<T>} props.defaultColumns
 * @param {string} props.localStorageName
 * @returns {JSX.Element}
 */

function AppDnD({ defaultColumns, localStorageName }) {
  // ===== HOOKS =====
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { debouncedSearch, search, setSearch } = useSearchDebounce('', 300)
  const accountId = useAppSelector((s) => s.auth.currentAccount.accountId)
  const visualAppears = useAppSelector((s) => s.table.visualAppears)
  const key = useMemo(
    () => `${accountId}/${localStorageName}`,
    [accountId, localStorageName],
  )
  const detectLastItemIsActionCol =
    _.last(defaultColumns)?.disabled ||
    _.last(defaultColumns)?.name.toLocaleLowerCase() ===
      t('Action').toLocaleLowerCase() ||
    _.last(defaultColumns)?.name === ''

  const [isOpen, setIsOpen] = useState(false)
  const defaultElements = useMemo(
    () =>
      transformingElements(
        detectLastItemIsActionCol ? _.initial(defaultColumns) : defaultColumns,
      ),
    [defaultColumns, detectLastItemIsActionCol],
  )

  /** Get local storage if there is any, otherwise set it to transformed element */
  const [snapshot, setSnapshot] = useLocalStorage(key, defaultElements)

  /**
   * Giá trị nháp của cột, dùng để thay đổi và chỉnh sửa trước
   * khi người dùng bấm lưu
   */
  const [draftColumns, setDraftColumns] = useState(snapshot)
  const [trackingState, setTrackingState] = useState(snapshot)

  /** @type {import('react-beautiful-dnd').OnDragEndResponder} */
  function onDragEnd(result) {
    const { destination, source } = result
    if (!destination) return false

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return false
    // if (destination.index === visualAppears.length - 1) return false
    const newOrder = arrayMove(visualAppears, source.index, destination.index)
    setDraftColumns(newOrder)
  }

  function handleSetDefault() {
    setDraftColumns(snapshot)
    setSearch('')
  }

  function handleCancel() {
    setDraftColumns(snapshot)
    setSearch('')
  }

  function handleSaveColumnState() {
    setSnapshot(visualAppears)
    setTrackingState(visualAppears)
    setSearch('')
    setIsOpen(false)
  }

  // ===== EFFECTS =====

  useEffect(() => {
    const filteredColumn = _.map(draftColumns, (column) => {
      return {
        ...column,
        isAppear: _.includes(
          Utils.convertString(column.name),
          Utils.convertString(debouncedSearch),
        ),
      }
    })
    dispatch(setVisualAppears(filteredColumn))
  }, [debouncedSearch, dispatch, draftColumns])

  useEffect(() => {
    dispatch(setDynamicColumns(trackingState))
  }, [dispatch, trackingState])

  return (
    <Popover
      arrow={false}
      placement="bottomRight"
      trigger="click"
      getPopupContainer={(trigger) => trigger.parentNode}
      open={isOpen}
      onOpenChange={(state) => {
        setIsOpen(state)
        handleCancel()
      }}
      overlayInnerStyle={{
        padding: 0,
      }}
      title={
        <Flex justify={'space-between'} align="center" className="p-6">
          <Typography.Title level={4} className="">
            Thiết lập cột
          </Typography.Title>
          <CustomAntButton
            customStyle={{
              minWidth: 'fit-content',
            }}
            iconCn="fa-regular fa-times"
            antProps={{
              type: 'text',
              onClick: () => {
                setIsOpen(false)
                handleCancel()
              },
            }}
          />
        </Flex>
      }
      content={
        <section>
          <div className="mx-6 mb-6">
            <CustomAntInput
              type="search"
              borderRadius="sm"
              inputProps={{
                name: 'dragAndDropSearch',
                autoComplete: 'off',
                placeholder: 'Tìm kiếm cột',
                value: search,
                onChange: (e) => setSearch(e.target.value),
              }}
            />
          </div>
          <DragDropContext
            onDragEnd={onDragEnd}
            enableDefaultSensors
            dragHandleUsageInstructions="Drag to reorder"
          >
            <Droppable droppableId="droppable" direction="vertical">
              {(droppableProvided) => (
                <ul
                  {...droppableProvided.droppableProps}
                  className="list-group mx-6 max-h-350px overflow-auto"
                  ref={droppableProvided.innerRef}
                >
                  <li className="list-group-item p-3 position-sticky top-0 z-2">
                    <Checkbox
                      checked={_.every(visualAppears, (v) => !v.hidden)}
                      onChange={({ target }) => {
                        setDraftColumns(
                          _.map(visualAppears, (v) => {
                            return {
                              ...v,
                              hidden: !target.checked,
                            }
                          }),
                        )
                      }}
                    >
                      Tất cả
                    </Checkbox>
                  </li>
                  <DnDColumnList />
                  {droppableProvided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
          {/* set default button */}
          <Flex
            wrap="wrap"
            justify="space-between"
            align="center"
            className="gap-3 py-3 px-6"
          >
            <CustomAntButton
              text="Đặt về mặc định"
              variant="success"
              antProps={{
                type: 'primary',
                onClick: handleSetDefault,
              }}
            />
            <CustomAntButton
              text="Lưu"
              antProps={{
                type: 'primary',
                onClick: handleSaveColumnState,
              }}
            />
          </Flex>
        </section>
      }
    >
      <CustomAntButton
        iconCn="fa-regular fa-gear"
        antProps={{
          type: 'default',
          className: 'p-3',
          shape: 'round',
          onClick: () => setIsOpen((prev) => !prev),
        }}
        customStyle={{
          minWidth: 'fit-content',
        }}
      />
    </Popover>
  )
}

export default AppDnD
