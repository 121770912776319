import { useMutation } from '@tanstack/react-query'
import { Divider } from 'antd'
import { Checkbox, Image, Space, Tooltip, Typography } from 'antd'
import invoiceApi from 'api/invoiceApi'
import {
  setAppNotInstalledToolNotiModalShow,
  setAppSpinning,
} from 'app/appSlice'
import HSM from 'assets/images/Einvoice/hsm.svg'
import NoSignMethod from 'assets/images/Einvoice/no-sign-icon.png'
import USBToken from 'assets/images/Einvoice/usb-icon.png'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import CustomButton from 'general/components/Button/CustomButton'
import BaseTextField from 'general/components/Forms/BaseTextField'
import FormLabel from 'general/components/Forms/FormLabel'
import GroupTextField from 'general/components/Forms/GroupTextTextField'
import { DIGITAL_SIGN_METHOD } from 'general/constants/AppConstants'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { shallowEqual } from 'react-redux'
import './style.scss'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import { useSelector } from 'react-redux'
import { thunkGetCompanyProfile } from 'features/System/systemSlice'
/**
 *
 * @param {object} props
 * @param {string} props.title
 * @param {boolean} props.show
 * @param {function} props.onHide
 * @param {function} props.onIssueSuccess
 * @param {*} props.invoice
 */
function IssueInvoiceFromPosModal({
  title,
  show,
  invoice,
  onHide,
  onIssueSuccess,
  ...restProps
}) {
  const dispatch = useAppDispatch()

  // const { activeSigningMethod, taxCode } = useAppSelector(
  //   (s) => s.system.company,
  //   shallowEqual,
  // )

  const [taxCode, setTaxCode] = useState()
  const [activeSigningMethod, setActiveSigningMethod] = useState()

  const [showCc, setShowCc] = useState(false)
  const [showBcc, setShowBcc] = useState(false)
  const [selectedMethod, setSelectedMethod] = useState(
    activeSigningMethod === DIGITAL_SIGN_METHOD.USB
      ? DIGITAL_SIGN_METHOD.USB
      : DIGITAL_SIGN_METHOD.HSM,
  )
  const [inforSave, setInforSave] = useState({ disabled: false })

  const { currentCompany } = useSelector((state) => state?.auth)
  useEffect(() => {
    if (currentCompany.companyId) {
      setTaxCode(currentCompany?.taxCode)
      const fetchData = async () => {
        const dataCompany = await dispatch(
          thunkGetCompanyProfile({ companyId: currentCompany.companyId }),
        )
        setActiveSigningMethod(dataCompany?.payload?.activeSigningMethod)
        setSelectedMethod(dataCompany?.payload?.activeSigningMethod)
      }
      fetchData()
    }
  }, [currentCompany?.taxCode, currentCompany?.companyId]) // Mảng dependencies chứa `text`

  useEffect(() => {
    if (!show) {
      setInforSave({ disabled: false })
    }
  }, [show]) // Mảng dependencies chứa `text`

  const formik = useFormik({
    initialValues: {
      sendTaxImmediately: true,
      sendEmailToCustomer: false,
      customerName: invoice?.customerName ?? '',
      customerEmail: invoice?.customerEmail ?? '',
      customerEmailCc: '',
      customerEmailBcc: '',
    },
    // validationSchema,
    onSubmit: async (values) => {
      if (values.sendEmailToCustomer) {
        if (!values.customerEmail) {
          ToastHelper.showError('Vui lòng nhập email')
          setInforSave({ disabled: false })
          return
        }
        if (!Utils.isValidEmail(values.customerEmail)) {
          ToastHelper.showError('Email sai định dạng')
          setInforSave({ disabled: false })
          return
        }
      }

      let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
      let sendEmailInfos = [
        values.sendEmailToCustomer
          ? {
              invoiceId: invoice.invoiceId,
              customerName: values.customerName,
              customerEmail: values.customerEmail,
              customerEmailCc: values.customerEmailCc,
              customerEmailBcc: values.customerEmailBcc,
            }
          : null,
      ].filter((item) => !!item)

      switch (selectedMethod) {
        case null:
        case DIGITAL_SIGN_METHOD.HSM:
          dispatch(setAppSpinning(true))
          try {
            let response = {}
            if (selectedMethod == null) {
              let res = await invoiceApi.issueInvoicesMTT({
                invoiceIds: [invoice.invoiceId],
                sendEmailInfos,
              })
              if (res?.data) res = res.data

              response.success = res.successIssueInvoices
              response.error = res.errorIssueInvoices
            } else {
              let res = await invoiceApi.hsmFromPosInvoicesSign({
                invoiceIds: [invoice.invoiceId],
                sendEmailInfos,
              })
              if (res?.data) res = res.data

              response.success = res.successSignInvoices
              response.error = res.errorSignInvoices
            }
            dispatch(setAppSpinning(false))

            if (
              !!response?.success?.find(
                ({ invoiceId }) => invoiceId == invoice.invoiceId,
              )
            ) {
              ToastHelper.showSuccess('Phát hành hóa đơn thành công')

              if (values.sendTaxImmediately) {
                if (activeSigningMethod == 'HSM') {
                  dispatch(setAppSpinning(true))
                  try {
                    await invoiceApi.hsmSignSummaryInvoice({
                      invoiceIds: [invoice.invoiceId],
                    })
                    dispatch(setAppSpinning(false))

                    ToastHelper.showSuccess(
                      'Gửi dữ liệu MTT đến CQT thành công',
                    )
                  } catch (e) {
                    dispatch(setAppSpinning(false))
                    console.log(e)
                    return
                  }
                } else {
                  let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},summaryInvoiceSignedBySeller,${invoice.invoiceId}`
                  Utils.openToolSignSummaryInvoice(urlSchema, accessToken, () =>
                    dispatch(setAppNotInstalledToolNotiModalShow(true)),
                  )
                  // onHide()
                  return
                }
              }

              onHide()
              onIssueSuccess()
              return
            }

            ToastHelper.showError(response?.error?.at(0)?.reason)
          } catch (e) {
            console.log(e)
            dispatch(setAppSpinning(false))
          } finally {
            setInforSave({ disabled: false })
          }
          break
        case DIGITAL_SIGN_METHOD.USB:
          if (values.sendTaxImmediately) {
            let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},summaryInvoiceSignedBySeller,${invoice.invoiceId},SIGN-INVOICE`

            Utils.openToolSignSummaryInvoice(
              urlSchema,
              accessToken,
              values.sendEmailToCustomer && JSON.stringify(sendEmailInfos),
              () => dispatch(setAppNotInstalledToolNotiModalShow(true)),
            )
          } else {
            let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${taxCode},${invoice.invoiceId},MTT`

            Utils.openToolSignInvoice(
              urlSchema,
              accessToken,
              values.sendEmailToCustomer && JSON.stringify(sendEmailInfos),
              () => dispatch(setAppNotInstalledToolNotiModalShow(true)),
            )
          }
          break
      }
    },
    enableReinitialize: true,
    validateOnChange: false,
  })

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/svg+xml': ['.svg'],
    },
    maxFiles: 1,
    onDrop: async (files) => {
      await new Promise((resolve) => {
        const img = new Image()
        img.src = URL.createObjectURL(files[0])
        img.onload = ({ target: img }) => {
          const { width, height } = img

          let logoContainer = document.getElementById('lc-frame')
          let containerWidth = logoContainer?.clientWidth
          let containerHeight = logoContainer?.clientHeight

          let imageBiggerThanContainer =
            width > containerWidth * 0.96 || height > containerHeight
          let scalingFactor = imageBiggerThanContainer
            ? Math.min(
                (containerWidth * 0.96) / width,
                containerHeight / height,
              )
            : 1
          let imageWidth = scalingFactor * width
          let imageHeight = scalingFactor * height
          let x = (containerWidth - width * scalingFactor) / 2
          let y = (containerHeight - height * scalingFactor) / 2

          // dispatch(
          //   setCustomBackgroundInfo({
          //     file: files[0],
          //     opacity: 35,
          //     position: 'center',
          //     x,
          //     y,
          //     width: imageWidth,
          //     height: imageHeight,
          //   }),
          // )
          resolve()
        }
      })
    },
  })

  const sendMailMutation = useMutation({
    mutationKey: ['sent-invoice'],
    mutationFn: ({
      customerName,
      customerEmail,
      customerEmailCc,
      customerEmailBcc,
      _invoiceId,
    }) =>
      invoiceApi.sendInvoiceToCustomer({
        invoiceId: invoice.invoiceId ?? _invoiceId,
        customerName,
        customerEmail,
        ccEmails: customerEmailCc?.split(';')?.filter((item) => item) || [],
        bccEmails: customerEmailBcc?.split(';')?.filter((item) => item) || [],
      }),
    onSettled: () => dispatch(setAppSpinning(false)),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSuccess: (res) => {
      if (res?.status !== 200 && res.result !== 'success') {
        ToastHelper.showError('Đã có lỗi trong quá trình gửi mail')
        throw new Error(res.statusText ?? res.result)
      }
      onHide()
      ToastHelper.showSuccess('Gửi email thành công')
    },
    onError: (e) => {
      throw new Error(e.message)
    },
  })

  return (
    <Modal
      className="IssueInvoiceFromPosModal"
      {...restProps}
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-bottom-0" closeButton>
        <span className="font-weight-bolder" style={{ fontSize: '18px' }}>
          Phát hành hóa đơn
        </span>
      </Modal.Header>

      <Modal.Body className="pt-0">
        <div className="d-flex flex-row justify-content-between p-3 bg-secondary">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row align-items-center justify-content-start">
              <FormLabel height="30px" width="120px" fontWeight="600">
                Ký hiệu:
              </FormLabel>
              <span style={{ fontSize: '14px', color: '#333333' }}>
                {invoice?.serial}
              </span>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-start">
              <FormLabel height="30px" width="120px" fontWeight="600">
                Ngày hóa đơn:
              </FormLabel>
              <span
                style={{
                  fontSize: '14px',
                  color: '#333333',
                  fontWeight: '400',
                }}
              >
                {dayjs(invoice?.date).format('L')}
              </span>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-start">
              <FormLabel height="30px" width="120px" fontWeight="600">
                Khách hàng:
              </FormLabel>
              <span
                style={{
                  fontSize: '14px',
                  color: '#333333',
                  fontWeight: '400',
                }}
              >
                {invoice?.customerCompanyName}
              </span>
            </div>
          </div>

          <div className="d-flex flex-column align-items-end justify-content-between">
            <div className="d-flex flex-column align-items-end">
              <FormLabel height="30px" width="100%" fontWeight="500">
                Tổng tiền thanh toán
              </FormLabel>
              <span
                style={{
                  fontSize: '14px',
                  color: '#333333',
                  fontWeight: '700',
                }}
              >
                {Utils.formatNumber(invoice?.totalAfterTax)}
                {invoice?.currency}
              </span>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column pt-3" style={{ gap: '10px' }}>
          <div className="d-flex flex-row align-items-center cursor-pointer">
            <Checkbox
              checked={formik.values.sendEmailToCustomer}
              onChange={(e) => {
                formik.setFieldValue('sendEmailToCustomer', !!e.target.checked)
              }}
              className="mr-3"
            >
              <span className="fw-bold">Gửi hóa đơn cho khách hàng</span>
            </Checkbox>
          </div>

          <div className="d-flex flex-row align-items-center">
            <FormLabel height="30px" width="130px" fontWeight="500">
              Tên người nhận:
              {!!formik.values?.sendEmailToCustomer && (
                <span className="ml-1" style={{ color: 'red' }}>
                  *
                </span>
              )}
            </FormLabel>
            <div className="w-100">
              <BaseTextField
                className="w-100"
                name="customerName"
                disabled={!formik.values.sendEmailToCustomer}
                fieldProps={formik.getFieldProps('customerName')}
                fieldMeta={formik.getFieldMeta('customerName')}
                fieldHelper={formik.getFieldHelpers('customerName')}
              />
            </div>
          </div>

          <div className="d-flex flex-row align-items-start justify-content-start w-100">
            <FormLabel
              className="mt-1"
              height="30px"
              width="130px"
              fontWeight="500"
            >
              Email
              {!!formik.values?.sendEmailToCustomer && (
                <span className="ml-1" style={{ color: 'red' }}>
                  *
                </span>
              )}
            </FormLabel>
            <div className="w-100">
              <GroupTextField
                className="w-100"
                name="customerEmail"
                disabled={!formik.values.sendEmailToCustomer}
                fieldProps={formik.getFieldProps('customerEmail')}
                fieldMeta={formik.getFieldMeta('customerEmail')}
                fieldHelper={formik.getFieldHelpers('customerEmail')}
                inputGroupEnd={
                  <Space className="border-left">
                    <CustomAntButton
                      text="CC"
                      customStyle={{
                        minWidth: 'unset',
                        color: show,
                      }}
                      antProps={{
                        type: 'text',
                        onClick: _.isNil(formik.values.sendEmailToCustomer)
                          ? null
                          : () => setShowCc((prev) => !prev),
                      }}
                    />
                    <CustomAntButton
                      text="BCC"
                      customStyle={{
                        minWidth: 'unset',
                      }}
                      antProps={{
                        type: 'text',
                        onClick: _.isNil(formik.values.sendEmailToCustomer)
                          ? null
                          : () => setShowBcc((prev) => !prev),
                      }}
                    />
                  </Space>
                }
              />

              {showCc && (
                <GroupTextField
                  className="mt-4"
                  name="customerEmailCc"
                  fieldProps={formik.getFieldProps('customerEmailCc')}
                  fieldMeta={formik.getFieldMeta('customerEmailCc')}
                  fieldHelper={formik.getFieldHelpers('customerEmailCc')}
                  inputGroupText="Cc"
                  placeholder="Mỗi email cách nhau bởi dấu ;"
                />
              )}

              {showBcc && (
                <GroupTextField
                  className="mt-4"
                  name="customerEmailBcc"
                  fieldProps={formik.getFieldProps('customerEmailBcc')}
                  fieldMeta={formik.getFieldMeta('customerEmailBcc')}
                  fieldHelper={formik.getFieldHelpers('customerEmailBcc')}
                  inputGroupText="Bcc"
                  placeholder="Mỗi email cách nhau bởi dấu ;"
                />
              )}
            </div>
          </div>

          <div className="d-flex flex-row align-items-start justify-content-start w-100">
            <FormLabel height="30px" width="130px" fontWeight="500"></FormLabel>

            <div className="w-100">
              <div
                {...getRootProps({
                  className: 'dropzone',
                })}
                className="d-inline-flex flex-row justify-content-center align-items-center bg-secondary p-3 rounded cursor-pointer"
              >
                <input
                  {...getInputProps()}
                  id="upload-custom-background-input"
                />
                <i
                  className="fa-regular fa-paperclip-vertical mr-2"
                  style={{ fontSize: `1.2em`, color: 'inherit' }}
                ></i>
                <p className="mb-0">Đính kèm biên bản</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3 rounded-2 bg-light-secondary h-100px d-flex container py-3 gap-2">
          <Space size={'middle'}>
            <span>
              <Image
                src={
                  selectedMethod === null
                    ? NoSignMethod
                    : selectedMethod === DIGITAL_SIGN_METHOD.USB
                      ? USBToken
                      : HSM
                }
                alt="Không sử dụng ký số"
                preview={false}
                width={42}
                height={42}
              />
            </span>
            <span>
              {selectedMethod === null ? (
                <>
                  <h5>Không ký số khi phát hành hóa đơn</h5>
                  <p>Chỉ áp dụng với loại HĐĐT khởi tạo từ máy tính tiền</p>
                </>
              ) : selectedMethod === DIGITAL_SIGN_METHOD.USB ? (
                <>
                  <h5>Ký số trực tiếp qua USB TOKEN</h5>
                  <p>Hãy chắc chắn bạn đã mở VietinvoiceSign và cắm USB</p>
                </>
              ) : (
                <>
                  <h5>Ký số thông qua máy chủ chữ ký số HSM</h5>
                </>
              )}
            </span>
          </Space>
          <div className="flex-grow-1"></div>
          <Divider type="vertical" className="h-100 bg-light-dark" />
          <Space className=" border-2" direction="vertical">
            <Typography.Text strong>Thay đổi hình thức ký số</Typography.Text>
            <Space>
              <Tooltip title="Không sử dụng ký số">
                <Button variant="icon" onClick={() => setSelectedMethod(null)}>
                  <Image
                    src={NoSignMethod}
                    alt="Không sử dụng ký số"
                    preview={false}
                    width={30}
                    height={30}
                    className={Utils.cn(
                      selectedMethod === null ? '' : 'opacity-50',
                    )}
                  />
                </Button>
              </Tooltip>
              <Tooltip
                title={Utils.cn(
                  'Ký số thông qua',
                  activeSigningMethod === DIGITAL_SIGN_METHOD.USB
                    ? 'Usb token'
                    : activeSigningMethod === DIGITAL_SIGN_METHOD.HSM
                      ? 'Hsm'
                      : '',
                )}
              >
                <Button
                  variant="icon"
                  onClick={() =>
                    setSelectedMethod(
                      activeSigningMethod === DIGITAL_SIGN_METHOD.USB
                        ? DIGITAL_SIGN_METHOD.USB
                        : DIGITAL_SIGN_METHOD.HSM,
                    )
                  }
                >
                  <Image
                    src={
                      activeSigningMethod === DIGITAL_SIGN_METHOD.USB
                        ? USBToken
                        : activeSigningMethod === DIGITAL_SIGN_METHOD.HSM
                          ? HSM
                          : ''
                    }
                    alt="Ký số USB"
                    preview={false}
                    width={30}
                    height={30}
                    className={Utils.cn(
                      !_.isNil(selectedMethod) ? '' : 'opacity-50',
                    )}
                  />
                </Button>
              </Tooltip>
            </Space>
          </Space>
        </div>

        <div className="d-flex flex-row justify-content-end align-items-center cursor-pointer mt-3">
          <Checkbox
            checked={formik.values.sendTaxImmediately}
            onChange={(e) => {
              formik.setFieldValue('sendTaxImmediately', !!e.target.checked)
            }}
          >
            <span className="fw-bold">Gửi hóa đơn đến cơ quan thuế</span>
          </Checkbox>
        </div>
      </Modal.Body>

      <Modal.Footer className="py-2">
        <CustomButton
          backgroundColor="white"
          text="Huỷ bỏ"
          width="90px"
          color="#304FFD"
          onClick={onHide}
        ></CustomButton>

        <CustomButton
          disabled={inforSave.disabled}
          loading={inforSave.disabled}
          backgroundColor="#304FFD"
          text="Phát hành"
          width="100px"
          color="white"
          type="submit"
          onClick={() => {
            setInforSave({ disabled: true })
            formik.submitForm()
          }}
        ></CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

export default IssueInvoiceFromPosModal
