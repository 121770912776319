import { setAppSpinning } from 'app/appSlice'
import { setOnlineStatus } from 'app/authSlice'
import { thunkGetDigitalCertificatesFromTool } from 'app/toolSlice'
import AppConfigs from 'general/constants/AppConfigs'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import Global from 'general/utils/Global'
import Utils from 'general/utils/Utils'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import ToastHelper from './ToastHelper'

let store
// var W3CWebSocket = require('websocket').w3cwebsocket

const sTag = '[WebsocketHelper]'

class WebsocketHelper {
  // MARK: --- Params ---
  wsClient = null
  mTimeout = null

  // MARK: --- Functions ---
  // constructor
  constructor() {
    this.initWebsocket()
  }

  initWebsocket() {
    console.log(AppConfigs.wsUrl)
    this.wsClient = new W3CWebSocket(AppConfigs.wsUrl, '')

    this.wsClient.onerror = () => {
      console.log(`${sTag} connection error`)
      store?.dispatch(setOnlineStatus(false))
      this.autoReconnect()
    }

    this.wsClient.onopen = () => {
      console.log(`${sTag} websocket client connected`)

      // auto re login
      const current = store?.getState()?.auth?.currentAccount
      if (current && current.email) {
        this.loginByToken(
          current.email,
          localStorage.getItem(PreferenceKeys.accessToken),
        )
      }
    }

    this.wsClient.onclose = () => {
      console.log(`${sTag} echo-protocol client closed`)
      store?.dispatch(setOnlineStatus(false))
      this.autoReconnect()
    }

    this.wsClient.onmessage = (e) => {
      const data = e.data
      if (typeof data === 'string') {
        try {
          this.processReceivedMessage(JSON.parse(data))
        } catch (error) {
          console.log(error)
        }
      }
    }
  }

  // auto reconnect websocket
  autoReconnect() {
    if (this.mTimeout) {
      clearTimeout(this.mTimeout)
      this.mTimeout = null
    }
    this.mTimeout = setTimeout(() => {
      this.initWebsocket()
    }, 5000)
  }

  // MARK: --- Public functions ---
  // login websocket
  login(email, password) {
    if (this.wsClient.readyState === 1) {
      const data = {
        code: '00',
        email: email,
        password: password,
      }
      console.log({ data })
      this.wsClient.send(JSON.stringify(data))
    }
  }

  // login websocket by token
  loginByToken(email, token) {
    if (this.wsClient.readyState === 1) {
      const data = {
        code: '00',
        email: email,
        accessToken: token,
      }
      // console.log({ data });
      this.wsClient.send(JSON.stringify(data))
    }
  }

  // log out websocket
  logout() {
    if (this.wsClient.readyState === 1) {
      const data = {
        code: '01',
      }
      this.wsClient.send(JSON.stringify(data))
    }
  }

  /**
   * Gui thong diep mo usb signing url
   * @param {number} registrationId
   */
  onAddingDigitalCertificate(accessToken, jwtToken) {
    if (this.wsClient.readyState === 1) {
      const data = {
        code: '04',
        accessToken,
        jwtToken,
      }
      this.wsClient.send(JSON.stringify(data))
    }
  }

  onExecutingSignRegistration(registrationId, jwtToken) {
    if (this.wsClient.readyState === 1) {
      const data = {
        code: '05',
        registrationId,
        jwtToken,
      }
      this.wsClient.send(JSON.stringify(data))
    }
  }

  onExecutingSignInvoice(accessToken, sendEmailInfosString) {
    if (this.wsClient.readyState === 1) {
      const data = {
        code: '06',
        accessToken,
        sendEmailInfosString,
      }
      this.wsClient.send(JSON.stringify(data))
    }
  }
  onExecutingSignErrAnnouce(accessToken) {
    if (this.wsClient.readyState === 1) {
      const data = {
        code: '07',
        accessToken,
      }
      this.wsClient.send(JSON.stringify(data))
    }
  }

  onExecutingSignCancellationReport(reportId, jwtToken) {
    if (this.wsClient.readyState === 1) {
      const data = {
        code: '08',
        reportId,
        jwtToken,
      }
      this.wsClient.send(JSON.stringify(data))
    }
  }

  onExecutingSignAdjustReport(reportId, jwtToken) {
    if (this.wsClient.readyState === 1) {
      const data = {
        code: '09',
        reportId,
        jwtToken,
      }
      this.wsClient.send(JSON.stringify(data))
    }
  }

  onExecutingSignTicket(jwtToken) {
    if (this.wsClient.readyState === 1) {
      const data = {
        code: '10',
        accessToken: jwtToken,
      }
      this.wsClient.send(JSON.stringify(data))
    }
  }

  onExecutingSignSummaryInvoice(accessToken, sendEmailInfosString) {
    if (this.wsClient.readyState === 1) {
      const data = {
        code: '12',
        accessToken,
        sendEmailInfosString,
      }
      this.wsClient.send(JSON.stringify(data))
    }
  }

  onExecutingSignSummaryTemplatePaper(accessToken) {
    if (this.wsClient.readyState === 1) {
      console.log('Dsdsds')
      const data = {
        code: '13',
        accessToken,
      }
      this.wsClient.send(JSON.stringify(data))
    }
  }

  onExecutingSignSummaryAccountingDocments(accessToken) {
    if (this.wsClient.readyState === 1) {
      const data = {
        code: '14',
        accessToken,
      }
      this.wsClient.send(JSON.stringify(data))
    }
  }

  // MARK: --- Utils functions ---
  processReceivedMessage(data) {
    try {
      console.log(`${sTag} received: ${JSON.stringify(data)}`)
    } catch (error) {
      console.log(error)
    }
    if (data) {
      let { code, result, message, invoiceType, classify } = data
      console.log({ data })
      switch (code) {
        case '01':
          if (result === 'success') {
            store?.dispatch(setOnlineStatus(false))
          }
          break
        case '04':
          if (result === 'success') {
            store?.dispatch(thunkGetDigitalCertificatesFromTool())
          }
          break
        case '05':
          if (result === 'success') {
            store.dispatch(setAppSpinning(false))
            clearTimeout(Global.gExecutingSignRegistrationTimeOut)
            ToastHelper.showSuccess('Ký tờ khai thành công')

            if (store?.getState()?.auth?.activeInvoice?.invoice)
              Utils.openPage('/hoa-don-dau-ra/to-khai')
            if (store?.getState()?.auth?.activeInvoice?.ticket)
              Utils.openPage('/hoa-don-dau-ra/tem-ve/to-khai')
          }
          break
        case '06':
          if (result === 'success') {
            store.dispatch(setAppSpinning(false))
            // clearTimeout(Global.gExecutingSignInvoiceTimeOut);
            ToastHelper.showSuccess('Ký hoá đơn thành công')
            if (classify == 'MTT') {
              Utils.openPage(
                '/hoa-don-dau-ra/hoa-don/may-tinh-tien/danh-sach-hoa-don',
              )
            } else if (classify === 'PXK') {
              Utils.openPage('/hoa-don-dau-ra/hoa-don/phieu-xuat-kho')
            } else {
              Utils.openPage('/hoa-don-dau-ra/hoa-don/danh-sach-hoa-don')
            }
          }
          break
        case '07':
          if (result === 'success') {
            store.dispatch(setAppSpinning(false))
            clearTimeout(Global.gExecutingSignErrAnnouceTimeOut)
            ToastHelper.showSuccess('Ký thông báo sai sót thành công')
            if (invoiceType == 'TICKET') {
              Utils.openPage(
                '/hoa-don-dau-ra/tem-ve/xu-ly-ve/thong-bao-sai-sot',
              )
            } else {
              Utils.openPage(
                '/hoa-don-dau-ra/xu-ly-hoa-don/danh-sach-thong-bao-sai-sot',
              )
            }
          }
          break
        case '08':
          if (result === 'success') {
            let { guestSign, invoiceId, ticketId, jwtToken } = data
            store.dispatch(setAppSpinning(false))
            clearTimeout(Global.gExecutingSignInvoiceReportTimeOut)

            ToastHelper.showSuccess('Ký biên bản hủy thành công')

            if (ticketId) {
              if (guestSign) {
                Utils.openPage(
                  `/tem-ve/bien-ban-huy/ky/${ticketId}?jwtToken=${jwtToken}`,
                )
              } else {
                Utils.openPage(
                  `/hoa-don-dau-ra/tem-ve/ve-dien-tu/bien-ban-huy/xem/${ticketId}`,
                )
              }

              return
            }

            if (guestSign) {
              Utils.openPage(
                `/bien-ban-huy/ky/${invoiceId}?jwtToken=${jwtToken}`,
              )
            } else {
              Utils.openPage(
                `/hoa-don-dau-ra/hoa-don/bien-ban-huy/xem/${invoiceId}`,
              )
            }
          }
          break
        case '09':
          if (result === 'success') {
            let { guestSign, reportId, invoiceId, originTicketId, jwtToken } =
              data
            store.dispatch(setAppSpinning(false))
            clearTimeout(Global.gExecutingSignInvoiceReportTimeOut)

            ToastHelper.showSuccess('Ký biên bản điều chỉnh thành công')
            if (guestSign) {
              Utils.openPage(
                `/bien-ban-dieu-chinh/ky/${
                  invoiceId || originTicketId
                }?jwtToken=${jwtToken}`,
              )
            } else {
              if (invoiceId) {
                Utils.openPage(
                  `/hoa-don-dau-ra/hoa-don/bien-ban-dieu-chinh/xem/${invoiceId}`,
                )
              } else if (originTicketId) {
                Utils.openPage(
                  `/hoa-don-dau-ra/tem-ve/ve-dien-tu/bien-ban-dieu-chinh/xem/${originTicketId}?reportId=${reportId}`,
                )
              }
            }
          }
          break
        case '10':
          if (result === 'success') {
            let { guestSign, reportId, invoiceId, originTicketId, jwtToken } =
              data
            store.dispatch(setAppSpinning(false))
            ToastHelper.showSuccess('Xuất vé thành công')
            window.location.reload()
          }
          break

        case '12':
          if (result === 'success') {
            store.dispatch(setAppSpinning(false))
            // clearTimeout(Global.gExecutingSignInvoiceTimeOut);
            ToastHelper.showSuccess(
              'Ký thông điệp gửi hóa đơn máy tính tiền thành công',
            )
            Utils.openPage(
              '/hoa-don-dau-ra/hoa-don/may-tinh-tien/danh-sach-hoa-don',
            )
          }
          break
        case '13':
          if (result === 'success') {
            ToastHelper.showSuccess('Phát hành mẫu thành công')
            Utils.openPage('/hoa-don-dau-ra/chung-tu-tncn/thiet-lap/mau')
          } else {
            ToastHelper.showError('Phát hành mẫu thất bại')
          }
          break
        case '14':
          if (result === 'success') {
            ToastHelper.showSuccess('Phát hành chứng từ thành công')
            Utils.openPage(
              // '/hoa-don-dau-ra/chung-tu-tncn/khau-tru-thue-thu-nhap-ca-nhan?tab=2',
              '/hoa-don-dau-ra/chung-tu-tncn/khau-tru-thue-thu-nhap-ca-nhan',
            )
          } else {
            ToastHelper.showError('Phát hành chứng từ thất bại')
          }
          break
        case '403':
          if (result === 'failed') {
            ToastHelper.showError(message)
          }
          break
        default:
          break
      }
    }
  }
}

// prevents modification to properties and values of an object
const wsHelperInstance = new WebsocketHelper()

// Object.freeze(wsHelperInstance);

// export
export default wsHelperInstance

export const injectStore = (_store) => {
  store = _store
}
