import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import invoiceApi from 'api/invoiceApi'
import { transactionApi } from 'api/transactionApi'
import { setAppSpinning } from 'app/appSlice'
import dayjs from 'dayjs'
import { MESSAGE_SUCCESS } from 'general/constants/AppConstants'
import {
  calcMaxCellWidth,
  createMergeRow,
  generateCellObject,
  generateCellStyle,
  setRowsHeight,
} from 'general/helpers/ExcelsHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import { utils, writeFile } from 'xlsx-js-style'
import { renderStatus } from './helpers'
import { transactionActions } from './slice'

export const transactionQueries = {
  base: {
    scope: ['invoice', 'transaction'],
  },
  lists: (params) =>
    queryOptions({
      staleTime: 1000 * 30,
      queryKey: [
        transactionQueries.base,
        { entity: 'list-transaction' },
        params,
      ],
      queryFn: ({ signal }) => transactionApi.get(params, signal),
      select: (res) => {
        return {
          total: _.get(res, 'data.total', 0),
          rows: !res?.data?.dataLogPetros
            ? []
            : _.map(res?.data?.dataLogPetros, (row, idx) => ({
                stt: idx + 1,
                dataLogPetroId: row?.dataLogPetroId,
                companyTaxCode: row?.companyTaxCode,
                stationCode: row?.stationCode,
                stationName: row?.stationName,
                saleDate: row?.saleDate,
                nozzleCode: row?.nozzleCode,
                nozzleName: row?.nozzleName,
                quantity: row?.quantity,
                unitPriceAfterTax: row?.unitPriceAfterTax,
                totalAmountAfterTax: row?.totalAmountAfterTax,
                productCode: row?.productCode,
                productName: row?.productName,
                partnerKey: row?.partnerKey,
                status: row?.status,
                keyApi: row?.keyApi,
                reasonCancel: row?.reasonCancel,
                dateCancel: row?.dateCancel,
                isMarked: row?.isMarked,
                noteMarked: row?.noteMarked,
                saleNumber: row?.saleNumber,
                invoiceId:
                  row?.dataLogPetroOfDataLogPetroInvoice?.at(-1)?.invoiceId,
                invoiceNo:
                  row?.dataLogPetroOfDataLogPetroInvoice?.at(-1)
                    ?.dataLogPetroInvoiceByInvoice?.no,
                dataLogPetroOfDataLogPetroInvoice:
                  row?.dataLogPetroOfDataLogPetroInvoice,
              })),
        }
      },
    }),
  stationList: (params) =>
    queryOptions({
      staleTime: 1000 * 30,
      queryKey: [transactionQueries.base, { entity: 'list-station' }, params],
      queryFn: ({ signal }) => transactionApi.getStations(params, signal),
      select: (res) => {
        return _.isEmpty(res?.data?.stationPetros) &&
          _.isNil(res?.data?.stationPetros)
          ? []
          : _.concat(
              [{ value: null, label: 'Tất cả' }],
              _.map(res?.data?.stationPetros, (row) => ({
                value: row?.stationCode,
                label: `${row?.stationName} - ${row?.stationCode}`,
                stationPetroId: row?.stationPetroId,
              })),
            )
      },
    }),
  nozzleList: (stationPetroId) =>
    queryOptions({
      staleTime: 1000 * 30,
      enabled: !_.isEmpty(stationPetroId),
      queryKey: [
        transactionQueries.base,
        { entity: 'list-nozzle' },
        stationPetroId,
      ],
      queryFn: ({ signal }) =>
        transactionApi.getNozzles(stationPetroId, signal),
      select: (res) => {
        return _.isEmpty(res?.data?.nozzles) && _.isNil(res?.data?.nozzles)
          ? []
          : _.concat(
              [{ value: null, label: 'Tất cả' }],
              _.map(res?.data?.nozzles, (row) => ({
                value: row?.nozzleCode,
                label: row?.nozzleName ?? row?.nozzleCode,
                stationPetroId: row?.stationPetroId,
              })),
            )
      },
    }),
  latestInvoiceTemplateId: () =>
    queryOptions({
      queryKey: [
        transactionQueries.base,
        { entity: 'latest-invoice-template' },
      ],
      queryFn: ({ signal }) => invoiceApi.latest({}, signal),
      staleTime: 1000 * 60,
      select: (res) => {
        return res?.rows?.invoiceTemplateId ?? null
      },
    }),
}

export const useMutationCancelInvoice = () => {
  const { invalidate } = useInvalidateQuery()
  const dispatch = useAppDispatch()
  return useMutation({
    mutationKey: [transactionQueries.base, { action: 'cancel-invoice' }],
    mutationFn: (params) => transactionApi.cancelInvoice(params),
    onSuccess: (res) => {
      if (res.result !== 'success') return null
      ToastHelper.showSuccess('Hủy giao dịch thành công')
      dispatch(transactionActions.setSelectedRow(null))
      dispatch(transactionActions.setSelectedRows([]))
      dispatch(transactionActions.setClearSelectedRows())
      void invalidate()
    },
  })
}

export const useMutationToggleMark = () => {
  const { invalidate } = useInvalidateQuery()
  const dispatch = useAppDispatch()
  return useMutation({
    mutationKey: [transactionQueries.base, { action: 'toggle-mark' }],
    mutationFn: (params) => transactionApi.toggleMarkInvoice(params),
    onSuccess: (res, variable) => {
      if (res.result !== 'success') return null
      ToastHelper.showSuccess(
        variable.body.isMarked
          ? 'Đánh dấu thành công'
          : 'Hủy đánh dấu thành công',
      )
      dispatch(transactionActions.setSelectedRow(null))
      dispatch(transactionActions.setSelectedRows([]))
      dispatch(transactionActions.setClearSelectedRows())
      void invalidate()
    },
  })
}

export const useMutationExportExcel = (defaultColumn) => {
  const { email } = useAppSelector((s) => s.auth.currentAccount)
  return useMutation({
    mutationKey: [transactionQueries.base, { action: 'export-excel' }],
    mutationFn: (params) => transactionApi.get(params),
    onSuccess: (res) => {
      try {
        if (res.result === 'success') {
          const columns = _.initial(defaultColumn)
          const dataLogs = res.data.dataLogPetros
          const numberFormat = (config) =>
            config === 0 ? '#,##0' : `#,##0.${_.repeat('0', config)}`

          // ===== TITLES =====
          const titles = generateCellObject(
            'Trạng thái xuất hóa đơn'.toUpperCase(),
            's',
            generateCellStyle({
              alignment: {
                horizontal: 'center',
                vertical: 'center',
              },
              font: {
                bold: true,
                name: 'Times New Roman',
                sz: 18,
              },
              isTextWrap: true,
            }),
          )
          const description = generateCellObject(
            `(${dayjs().format('LL')})`,
            's',
            generateCellStyle({
              alignment: {
                horizontal: 'center',
                vertical: 'center',
              },
              font: {
                name: 'Times New Roman',
                sz: 12,
                italic: true,
              },
            }),
          )

          // ===== HEADER =====
          const headerStyle = generateCellStyle({
            alignment: {
              horizontal: 'center',
              vertical: 'center',
            },
            font: {
              bold: true,
              name: 'Times New Roman',
            },
            isTextWrap: true,
            border: {
              directions: 'around',
              widthStyle: 'medium',
            },
          })

          const headers = _.map(columns, (col) =>
            generateCellObject(col.name, 's', headerStyle),
          )

          // ===== BODY =====

          const cellStyles = generateCellStyle({
            alignment: {
              horizontal: 'center',
              vertical: 'center',
            },
            font: {
              name: 'Times New Roman',
            },
            border: {
              directions: 'around',
              widthStyle: 'thin',
            },
            isTextWrap: true,
          })

          const numberFormatStyle = _.merge({}, cellStyles, {
            alignment: {
              horizontal: 'right',
            },
          })

          const cells = _.map(dataLogs, (row) => {
            return _.map(columns, (col) => {
              let rowValue = _.get(row, col.id, '')
              rowValue = _.isNil(rowValue) ? '' : rowValue
              switch (col.id) {
                default:
                  return generateCellObject(rowValue, 's', cellStyles)
                case 'code':
                  rowValue = row?.saleNumber
                  return generateCellObject(rowValue, 's', cellStyles)
                case 'nozzleName':
                  rowValue = row.nozzleName ?? row.nozzleCode
                  return generateCellObject(rowValue, 's', cellStyles)
                case 'quantity':
                  return generateCellObject(
                    rowValue,
                    'n',
                    numberFormatStyle,
                    numberFormat(3),
                  )
                case 'unitPriceAfterTax':
                case 'totalAmountAfterTax':
                  if (_.isNumber(rowValue)) {
                    rowValue = Utils.formatNumber(rowValue)
                      .replace(/\./g, '')
                      .replace(/,/g, '.')
                    return generateCellObject(
                      rowValue,
                      'n',
                      numberFormatStyle,
                      numberFormat(0),
                    )
                  }
                  return generateCellObject(rowValue, 's', cellStyles)
                case 'saleDate':
                  return dayjs(rowValue).isValid()
                    ? generateCellObject(
                        rowValue,
                        'd',
                        cellStyles,
                        'DD/MM/YYYY hh:mm',
                      )
                    : generateCellObject(rowValue, 's', cellStyles)
                case 'userCreated':
                  return generateCellObject(email, 's', cellStyles)
                case 'status':
                  rowValue = renderStatus(row.status).text
                  return generateCellObject(rowValue, 's', cellStyles)
                // xử lí lấy thông tin số hóa đơn
                case 'invoiceNo':
                  rowValue =
                    row.dataLogPetroOfDataLogPetroInvoice[0]
                      ?.dataLogPetroInvoiceByInvoice?.no ?? ''
                  return generateCellObject(rowValue, 's', cellStyles)
              }
            })
          })

          // ===== EXPORT =====
          const aoa = [[titles], [description], headers, ...cells]
          const workbook = utils.book_new()
          const worksheet = utils.aoa_to_sheet(aoa)

          worksheet['!merges'] = [
            createMergeRow(
              { startIndex: 0, endIndex: 0 },
              { startIndex: 0, endIndex: columns.length - 1 },
            ),
            createMergeRow(
              { startIndex: 1, endIndex: 1 },
              { startIndex: 0, endIndex: columns.length - 1 },
            ),
          ]
          worksheet['!cols'] = calcMaxCellWidth(aoa, worksheet['!ref'], 10)
          worksheet['!rows'] = setRowsHeight(worksheet['!rows'], [
            { level: 0, height: 30 },
            { level: 1, height: 20 },
          ])
          utils.book_append_sheet(workbook, worksheet, 'Hóa đơn')

          writeFile(
            workbook,
            `Danh_sach_giao_dich-${dayjs().format('L')}.xlsx`,
            {
              cellStyles: true,
            },
          )
        }
      } catch (error) {
        ToastHelper.showError('Xuất file thất bại: ' + error.message)
      }
    },
  })
}

export const useMutationIssueInvoiceFromDataLog = () => {
  const dispatch = useAppDispatch()
  const { invalidate } = useInvalidateQuery()
  return useMutation({
    mutationKey: ['invoice', 'listTransaction', 'issueInvoiceFromDataLog'],
    mutationFn: (params) =>
      invoiceApi.issueFromLogPetro({
        dataLogPetroIds: _.isArray(params) ? params : [params],
      }),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (err) => console.error(err.message),
    onSuccess: (res, params) => {
      if (res.data) res = res.data
      // Kí hàng loạt
      if (_.isArray(params)) {
        // queryClient.invalidateQueries(
        //   keyFactory.lists('', { ...filterParams, page: 1 }),
        // )
        // let { errorIssuedLogPetros, successIssuedLogPetros } = res
        // let describeText = `Hóa đơn kí thành công: <ul class="mb-0">${successIssuedLogPetros
        //   .map(
        //     ({ no, serial }) =>
        //       `<li>Hóa đơn ký hiệu: ${serial}, số: ${no}</li>`,
        //   )
        //   .join('')}</ul>
        //                     Hóa đơn ký thất bại: <ul class="mb-0">${errorIssuedLogPetros
        //                       .map(
        //                         ({ no, serial, reason }) =>
        //                           `<li>Hóa đơn ký hiệu: ${serial}, số: ${no}.</br> Lí do ký lỗi: ${reason}</li>`,
        //                       )
        //                       .join('')}</ul>`
        // dispatch(
        //   showNotification({
        //     title: 'Thông báo',
        //     describeText,
        //     icon: 'fa-light fa-circle-info text-primary',
        //   }),
        // )
      } else {
        /* Kí một hóa đơn */
        // Kí thành công
        if (
          !!res?.successIssuedLogPetros?.find(
            ({ dataLogPetroId }) => dataLogPetroId == params,
          )
        ) {
          ToastHelper.showSuccess(MESSAGE_SUCCESS.ISSUED_INVOICE)
          // reload page
          invalidate()
          dispatch(transactionActions.setSelectedRows([]))
          dispatch(transactionActions.setClearSelectedRows())
          dispatch(transactionActions.setSelectedRow(null))
          return
        }
        // Kí thất bại
        return ToastHelper.showError(res?.errorIssuedLogPetros?.at(0)?.reason)
      }
    },
  })
}

export const useMutationViewInvoice = () => {
  return useMutation({
    mutationKey: [transactionQueries.base, { action: 'view-invoice' }],
    mutationFn: (params) => invoiceApi.exportInvoiceById(params),
    onSuccess: (res) => {
      if (_.isNil(res)) return
      const url = URL.createObjectURL(res)
      window.open(url, '_blank')
    },
  })
}

export const useInvalidateQuery = () => {
  const queryClient = useQueryClient()
  return {
    invalidate: () => queryClient.invalidateQueries(transactionQueries.base),
  }
}

export const useMutationRemove = () => {
  const { invalidate } = useInvalidateQuery()
  const dispatch = useAppDispatch()
  return useMutation({
    mutationKey: [transactionQueries.base, { action: 'toggle-mark' }],
    mutationFn: (params) => transactionApi.remove(params),
    onSuccess: (res, variable) => {
      if (res.result !== 'success') return null
      ToastHelper.showSuccess('Xóa log bơm thành công')
      dispatch(transactionActions.setSelectedRow(null))
      dispatch(transactionActions.setSelectedRows([]))
      dispatch(transactionActions.setClearSelectedRows())
      void invalidate()
    },
  })
}
