import { Space } from 'antd'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
// import SelectOptions from 'general/constants/SelectOptions'
import Utils from 'general/utils/Utils'
import useFilter from 'hooks/useFilter'
// import { useMemo } from 'react'
import { InvoiceTable } from './components/InvoiceTable'
import Modals from './components/Modals'
import { defaultValues, filterOptions, renderInvoiceType } from './constant'
import useAppDnD from 'hooks/useAppDnD'
import useGetSerials from 'hooks/Queries/useGetSerials'
// import SelectOptions from 'general/constants/SelectOptions'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import CustomAntSelect from 'general/components/CustomAntSelect'
import { useEffect, useState } from 'react'
import useSearchDebounce from 'hooks/useSearchDebounce'
import {
  useMutationPullBulkMessage,
  useQueryListInvoice,
} from './components/queries'
import _ from 'lodash'
// import { useMutationGetBulkMessage } from 'features/Invoice/screens/ListInvoice/queries'
import { ActionsTable } from './components/InvoiceTable/ActionsTable'
import dayjs from 'dayjs'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
import PreferenceKeys from 'general/constants/PreferenceKeys'

export const TvanHandle = () => {
  // base state
  const [tvanState, setTvanState] = useState({})
  const [modalsState, setModalsState] = useState({})
  const [filterParams, setFilterParams] = useFilter(
    'TvanHandle',
    'gFiltersTvanHandle',
  )
  const { data: serials } = useGetSerials()
  const { mutate: checkMessages, isPending: checkingBulkMessage } =
    useMutationPullBulkMessage()

  const defaultColumns = [
    {
      id: 'sellerTaxCode',
      name: 'Mã số thuế',
      minWidth: '110px',
      center: true,
      omit: false,
      cell: (row) => {
        return (
          <p
            style={{
              textAlign: 'center',
            }}
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
          >
            {row?.sellerTaxCode}
          </p>
        )
      },
    },
    {
      id: 'serial',
      name: 'Ký hiệu',
      minWidth: '110px',
      center: true,
      omit: false,
      cell: (row) => {
        return (
          <p
            style={{
              textAlign: 'center',
            }}
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
          >
            {row?.serial}
          </p>
        )
      },
    },
    {
      id: 'no',
      name: 'Số',
      minWidth: '110px',
      center: true,
      omit: false,
      cell: (row) => {
        return (
          <p
            style={{
              textAlign: 'center',
            }}
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
          >
            {row?.no}
          </p>
        )
      },
    },
    {
      id: 'messageId',
      name: 'Mã thông điệp',
      minWidth: '250px',
      center: true,
      omit: false,
      cell: (row) => {
        return (
          <p
            style={{
              textAlign: 'center',
            }}
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
          >
            {row?.messageId}
          </p>
        )
      },
    },
    {
      id: 'taxAuthorityCode',
      name: 'Mã CQT',
      center: true,
      omit: false,
      minWidth: '15rem',
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
          >
            {row?.taxAuthorityCode}
          </p>
        )
      },
    },
    {
      id: 'type',
      name: 'Loại hóa đơn',
      center: true,
      omit: false,
      minWidth: '115px',
      cell: (row) => {
        return (
          <div className="w-100 align-items-center justify-content-center">
            {renderInvoiceType(row?.type)}
          </div>
        )
      },
    },
    {
      id: 'issueStatus',
      name: 'Trạng thái phát hành',
      center: true,
      omit: false,
      minWidth: '150px',
      cell: (row) => {
        const { color, text } = InvoiceHelper.renderIssueStatus(row.issueStatus)
        return (
          <p data-tag="allowRowEvents" className={`badge badge-light-${color}`}>
            {text}
          </p>
        )
      },
    },
    {
      id: 'sendTaxStatus',
      name: 'Trạng thái gửi CQT',
      center: true,
      omit: false,
      minWidth: '150px',
      cell: (row) => {
        const { color, text } = InvoiceHelper.renderSendTaxStatus(
          row.sendTaxStatus,
        )
        return (
          <p data-tag="allowRowEvents" className={`badge badge-light-${color}`}>
            {text}
          </p>
        )
      },
    },
    {
      id: 'date',
      name: 'Ngày tạo',
      center: true,
      omit: false,
      minWidth: '115px',
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
          >
            {dayjs(row?.date).format('DD/MM/YYYY')}
          </p>
        )
      },
    },
    {
      id: 'dateSellerSign',
      name: 'Ngày ký',
      center: true,
      omit: false,
      minWidth: '115px',
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
          >
            {dayjs(row?.dateSellerSign).format('DD/MM/YYYY')}
          </p>
        )
      },
    },
    {
      id: 'lookupCode',
      name: 'Mã tra cứu',
      center: true,
      omit: false,
      minWidth: '150px',
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-red-75 font-weight-normal m-0 text-maxline-3 "
          >
            {row?.lookupCode}
          </p>
        )
      },
    },
    {
      name: 'Hành động',
      center: true,
      disabled: true,
      compact: true,
      minWidth: '200px',
      grow: 2,
      style: {
        justifyContent: 'flex-end',
      },
      cell: (row) => (
        <ActionsTable
          row={row}
          tvanState={tvanState}
          setTvanState={({ key, value }) => handleChange({ key, value })}
          modalsState={modalsState}
          setModalsState={({ key, value }) =>
            handleChangeModalState({ key, value })
          }
          refetchListInvoice={refetchListInvoice}
        />
      ),
    },
  ]
  const dynamicColumns = useAppDnD(defaultColumns)

  const handleChange = ({ key, value }) => {
    const cloneTvanState = { ...tvanState }
    cloneTvanState[key] = value
    setTvanState(cloneTvanState)
  }
  const handleChangeModalState = ({ key, value }) => {
    const cloneModasState = { ...modalsState }
    cloneModasState[key] = value
    setModalsState(cloneModasState)
  }
  // hiển thị
  // Gán dữ liệu ban đầu
  useEffect(() => {
    const cloneTvanState = { ...tvanState }
    cloneTvanState.keySearch =
      cloneTvanState.keySearch ?? filterOptions[0].value
    setTvanState(cloneTvanState)
  }, [])
  // lấy danh sách hóa đơn
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const {
    data: invoiceListData,
    isGettingListData,
    isRefetching,
    refetchListInvoice,
  } = useQueryListInvoice({
    ...filterParams,
    [tvanState.keySearch]: debouncedSearch,
    // [tvanState.keySearch]: tvanState.searchValue,
  })
  // Làm mới dữ liệu hóa đơn
  useEffect(() => {
    refetchListInvoice()
  }, [
    filterParams,
    refetchListInvoice,
    tvanState.keySearch,
    tvanState.searchValue,
    debouncedSearch,
  ])
  // Lọc
  // bảng
  // kéo mã
  // chuyển về gửi lỗi
  // cập nhật thông điệp
  return (
    <ContentContainer wrapperClassName={'list-issued-ticket'}>
      <ContentContainer.Header
        titleContent={'Xử lý truyền nhận Tvan'}
        toolBar={
          <Space className="d-flex">
            <CustomAntButton
              iconCn={Utils.cn(
                'far fa-rotate',
                checkingBulkMessage && 'fa-spin',
              )}
              text="Kéo mã Tvan"
              antProps={{
                type: 'default',
                title: 'Kéo mã',
                className: 'p-3',
                shape: 'round',
                disabled: checkingBulkMessage,
                onClick: () =>
                  checkMessages(_.pick(filterParams, ['startDate', 'endDate'])),
              }}
              customStyle={{
                minWidth: 'fit-content',
              }}
            />

            <AppDnD
              defaultColumns={defaultColumns}
              localStorageName={PreferenceKeys.TvanHandle}
            />
          </Space>
        }
      />
      <Space className="ml-5">
        <Space>
          {/* <SelectOptions options={SelectOptions.serials} /> */}
          <CustomAntInput
            type="search"
            borderRadius="sm"
            width={150}
            inputProps={{
              value: search,
              onChange: (e) => setSearch(e.target.value),
            }}
          />
          <CustomAntSelect
            className="adjustReplaceTicket-select"
            options={filterOptions}
            placeholder="Chọn tiêu chí tìm kiếm"
            value={tvanState.keySearch}
            onChange={(val) => handleChange({ key: 'keySearch', value: val })}
          />
          {/* <SelectOptions options={SelectOptions.serials} /> */}
        </Space>
        <FilterPopover
          menuPosition="bottomLeft"
          defaultValues={defaultValues(serials)}
          savedKey={'gFiltersTvanHandle'}
          onApply={setFilterParams}
        />
      </Space>

      {/* toolBar=
        {
          <FilterPopover
            menuPosition="bottomLeft"
            defaultValues={defaultValues}
            savedKey={'gFiltersInvoiceList'}
            onApply={() => setFilterParams}
          />
        } */}
      {/* {renderInvoiceActionButtons()} */}
      {/* <FilterPopover
        menuPosition="bottomLeft"
        defaultValues={defaultValues}
        savedKey={'gFiltersInvoiceList'}
        onApply={() => setFilterParams}
      /> */}
      <ContentContainer.Body>
        <InvoiceTable
          defaultColumns={dynamicColumns}
          dataSource={invoiceListData}
          isLoading={isGettingListData || isRefetching}
          tvanState={tvanState}
          setTvanState={({ key, value }) => handleChange({ key, value })}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
        <Modals
          tvanState={tvanState}
          setTvanState={({ key, value }) => handleChange({ key, value })}
          modalsState={modalsState}
          setModalsState={({ key, value }) =>
            handleChangeModalState({ key, value })
          }
          refetchListInvoice={refetchListInvoice}
          row={tvanState.activeRow}
        />
      </ContentContainer.Body>
    </ContentContainer>
  )
}
