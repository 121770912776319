import { useMutationCheckMessage } from 'features/Invoice/screens/ListInvoice/queries'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import {
  INVOICE_STATUS,
  INVOICE_TYPE,
  SUMMARY_INVOICE_STATUS,
} from 'general/constants/AppConstants'
import Utils from 'general/utils/Utils'

export const ActionsTable = ({ row, setTvanState, setModalsState }) => {
  // kém mã cho 1 hóa đơn
  const { mutate: pullInvoiceMessage, isPending: isPullingInvoiceMessage } =
    useMutationCheckMessage()
  return (
    <>
      {([INVOICE_STATUS.WAITING_FOR_AUTHORITY].includes(row.issueStatus) ||
        [SUMMARY_INVOICE_STATUS.WAITING_FOR_AUTHORITY].includes(
          row.sendTaxStatus,
        )) &&
      row.type !== INVOICE_TYPE.DELETED ? (
        <>
          <TableAction
            titleActionText="Kéo mã cho hóa đơn này"
            icon={
              <IconAction
                className={`${Utils.cn('far fa-rotate', isPullingInvoiceMessage && 'fa-spin')}`}
              />
            }
            onClick={() => {
              setTvanState({ key: 'activeRow', value: row })
              pullInvoiceMessage(row.messageId)
            }}
          />
        </>
      ) : null}
      {([
        INVOICE_STATUS.WAITING_FOR_AUTHORITY,
        INVOICE_STATUS.SENDING_TAX,
      ].includes(row.issueStatus) ||
        [SUMMARY_INVOICE_STATUS.WAITING_FOR_AUTHORITY].includes(
          row.sendTaxStatus,
        )) &&
        row.type !== INVOICE_TYPE.DELETED && (
          <TableAction
            titleActionText="Chuyển hóa đơn về gửi lỗi"
            icon={<IconAction className="fa-solid fa-turn-down-right" />}
            onClick={() => {
              setTvanState({ key: 'activeRow', value: row })
              setModalsState({ key: 'moveStatusModal', value: true })
            }}
          />
        )}
      {([SUMMARY_INVOICE_STATUS.INVOICE_INVALID].includes(row.sendTaxStatus) || // hóa đơn từ máy tính tiền
        [
          // hóa đơn thường
          INVOICE_STATUS.AUTHORITY_CODE_NOT_GIVEN,
          INVOICE_STATUS.AUTHORITY_DENY,
        ].includes(row.issueStatus)) &&
      row.type !== INVOICE_TYPE.DELETED ? (
        <TableAction
          titleActionText="Cập nhật mã thông điệp"
          icon={<IconAction className="fa-sharp-duotone fa-light fa-pen" />}
          onClick={() => {
            setTvanState({ key: 'activeRow', value: row })
            // pullInvoiceMessage(row.messageId)
            setModalsState({ key: 'updateMessageModal', value: true })
          }}
        />
      ) : null}
    </>
  )
}
