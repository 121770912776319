import Empty from 'general/components/Empty'
import AppResource from 'general/constants/AppResource'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CreateTicket } from './screens/CreateTicket'
import CreateTicketReport from './screens/CreateTicketReport'
import ImportTicket from './screens/ImportTicket'
import ListIssuedTicket from './screens/ListIssuedTicket'

function Ticket({ ticketType = 'HD' }) {
  // MARK: --- Params ---
  const { t } = useTranslation()

  return (
    <Routes>
      <Route path="" element={<Navigate to="xuat-ve" />} />
      <Route
        path={'xuat-ve'}
        element={
          <CreateTicket ticketType={ticketType} key={1} />
          //   isCreateInvoice ? <CreateInvoice key={1} /> : <UnauthorizedScreen />
        }
      />

      <Route
        path={'cap-nhat/:ticketDraftId'}
        element={
          <CreateTicket key={2} />
          //   isCreateInvoice ? <CreateInvoice key={1} /> : <UnauthorizedScreen />
        }
      />

      <Route
        path={'ve-da-xuat'}
        element={
          <ListIssuedTicket ticketType={ticketType} />
          //   isCreateInvoice ? <CreateInvoice key={1} /> : <UnauthorizedScreen />
        }
      />

      <Route
        path={'nhap-khau'}
        element={<ImportTicket ticketType={ticketType} />}
      />
      <Route
        path={'bien-ban-huy/them-moi/:ticketId'}
        element={<CreateTicketReport />}
      />
      <Route
        path={'bien-ban-huy/cap-nhat/:ticketId'}
        element={<CreateTicketReport />}
      />
      <Route
        path={'bien-ban-huy/xem/:ticketId'}
        element={<CreateTicketReport />}
      />
      <Route
        path={'bien-ban-dieu-chinh/them-moi/:ticketId'}
        element={<CreateTicketReport />}
      />
      <Route
        path={'bien-ban-dieu-chinh/cap-nhat/:ticketId'}
        element={<CreateTicketReport />}
      />
      <Route
        path={'bien-ban-dieu-chinh/xem/:ticketId'}
        element={<CreateTicketReport />}
      />

      <Route
        path="*"
        element={
          <Container fluid className="h-100 d-flex">
            <Row className="justify-content-center align-items-center flex-grow-1">
              <Empty
                text={t('PageNotFound')}
                buttonText={t('Refresh')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
              />
            </Row>
          </Container>
        }
      />
    </Routes>
  )
}

export default Ticket
