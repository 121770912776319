import CustomButton from 'general/components/Button/CustomButton'
import { Footer } from './styles'
import { useQuery } from '@tanstack/react-query'
import axiosClient from 'api/axiosClient'

/**
 * PropTypes for InvoiceFooter component.
 * @typedef {Object} InvoiceFooterProps
 * @property {boolean} buttonAddExtensionField - Indicates if the button to add extension field is visible.
 * @property {boolean} buttonSendDraftInvoice - Indicates if the button to send draft invoice is visible.
 * @property {boolean} buttonPreview - Indicates if the preview button is visible.
 * @property {boolean} buttonCancel - Indicates if the cancel button is visible.
 * @property {boolean} buttonSaveAndSign - Indicates if the save and sign button is visible.
 * @property {boolean} buttonCreate - Indicates if the create button is visible.
 * @property {boolean} buttonPrint - Indicates if the print button is visible.
 * @property {Function} buttonAddExtensionFieldOnClick - Function to call when the add extension field button is clicked.
 * @property {Function} buttonSendDraftInvoiceOnClick - Function to call when the send draft invoice button is clicked.
 * @property {Function} buttonPreviewOnClick - Function to call when the preview button is clicked.
 * @property {Function} buttonCancelOnClick - Function to call when the cancel button is clicked.
 * @property {Function} buttonSaveAndSignOnClick - Function to call when the save and sign button is clicked.
 * @property {Function} buttonCreateOnClick - Function to call when the create button is clicked.
 * @property {Function} buttonPrintOnClick - Function to call when the print button is clicked.
 */

/**
 * @param {InvoiceFooterProps} props
 */
function InvoiceFooter(props) {
  const {
    buttonAddExtensionFieldOnClick,
    buttonPrintOnClick,
    buttonCancelOnClick,
    buttonCreateOnClick,
    buttonPreviewOnClick,
    buttonSaveAndSignOnClick,
    buttonSendDraftInvoiceOnClick,
    buttonAddExtensionField = false,
    buttonSendDraftInvoice = false,
    buttonPreview = false,
    buttonCancel = false,
    buttonSaveAndSign = false,
    buttonPrint = false,
    buttonCreate = false,
    inforActionSaveAndSign,
    setInforActionSaveAndSign,
  } = props
  const { data: banners, isLoading } = useQuery({
    staleTime: 1000 * 60 * 5,
    queryKey: ['banner'],
    queryFn: ({ signal }) =>
      axiosClient.get('/notification-marquee/show', {
        signal,
      }),
  })

  if (isLoading) {
    return null
  }
  return (
    <Footer id="footer">
      <div
        className="d-flex flex-row justify-content-start"
        style={{
          gap: '10px',
          marginBottom: banners?.data?.length > 0 ? '30px' : '0px',
        }}
      >
        {buttonAddExtensionField && (
          <CustomButton
            text="Thêm trường mở rộng"
            backgroundColor="transparent"
            showBorder={false}
            // color={"red"}
            icon={'fa-regular fa-cog'}
            gap="6px"
            onClick={buttonAddExtensionFieldOnClick}
            color="#041847"
          />
        )}
        {buttonSendDraftInvoice && (
          <CustomButton
            text="Gửi hoá đơn nháp"
            backgroundColor="transparent"
            showBorder={false}
            // color={"red"}
            icon={'fa-regular fa-envelope'}
            gap="6px"
            color="#041847"
            onClick={buttonSendDraftInvoiceOnClick}
          />
        )}
        {buttonPreview && (
          <CustomButton
            text="Xem trước"
            backgroundColor="transparent"
            showBorder={false}
            // color={"red"}
            icon={'fa-regular fa-eye'}
            color="#041847"
            gap="6px"
            onClick={buttonPreviewOnClick}
          />
        )}
      </div>

      <div
        className="d-flex flex-row justify-content-start"
        style={{
          gap: '10px',
          marginBottom: banners?.data?.length > 0 ? '30px' : '0px',
        }}
      >
        {buttonPrint && (
          <CustomButton
            text="In"
            color="#304FFD"
            borderColor="#304FFD"
            width="100px"
            showBorder={false}
            onClick={buttonPrintOnClick}
          />
        )}
        {buttonCancel && (
          <CustomButton
            text="Huỷ bỏ"
            color="#304FFD"
            backgroundColor="transparent"
            width="100px"
            showBorder={false}
            onClick={buttonCancelOnClick}
          />
        )}
        {buttonCreate && (
          <CustomButton
            loading={inforActionSaveAndSign?.disabled}
            disabled={inforActionSaveAndSign?.disabled}
            text="Lưu"
            color="#304FFD"
            borderColor="#304FFD"
            width="100px"
            onClick={buttonCreateOnClick}
          />
        )}
        {buttonSaveAndSign && (
          <CustomButton
            loading={inforActionSaveAndSign?.disabled}
            disabled={inforActionSaveAndSign?.disabled}
            text="Lưu và ký"
            backgroundColor="#304FFD"
            color="white"
            width="150px"
            onClick={buttonSaveAndSignOnClick}
          />
        )}
      </div>
    </Footer>
  )
}

export default InvoiceFooter
