import { useQuery } from '@tanstack/react-query'
import templateInvoiceApi from 'api/templateInvoiceApi'
import ToastHelper from 'general/helpers/ToastHelper'
import { useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import { useMemo } from 'react'

/**
 *
 * @param {'POS' | 'ALL' | 'PXK'} type
 * @returns
 */
export default function useGetSerials(type, categoryTaxType) {
  const systemType = useAppSelector((s) => s.auth.activeInvoice)
  const params = useMemo(() => {
    switch (type) {
      case 'ALL':
        return {
          invoiceClassify: 'ALL',
        }
      case 'MTT':
      case 'POS':
        return {
          categoryTaxType,
          // categoryForm: 1,
          invoiceClassify: 'MTT',
        }
      case 'PXK':
        return {
          invoiceClassify: 'PXK',
        }
    }
  }, [categoryTaxType, type])

  return useQuery({
    staleTime: 1000 * 60,
    enabled: systemType.invoice,
    queryKey: ['serials', params],
    queryFn: async ({ signal }) => {
      const res = await templateInvoiceApi.getTemplateSerials(params, signal)
      if (res?.data.length === 0) {
        ToastHelper.showError(
          'Bạn chưa thực hiện đăng ký mẫu hóa đơn hoặc không được phân quyền sử dụng mẫu hóa đơn nào. Hãy thực hiện đăng ký trước khi lập hóa đơn',
        )
      }
      return res
    },
    select: (res) => {
      return _.isUndefined(res?.data) || _.isNull(res?.data)
        ? []
        : _.concat(
            [{ label: 'Tất cả', value: null }],
            _.map(res?.data, (item) => ({
              ...item,
              label: item.serial,
              value: item.serial,
              invoiceTemplateId: item?.invoiceTemplateId,
              invoiceTemplateName: item?.name,
            })),
          )
    },
  })
}
