import TvanHandleApi from 'api/TvanHandleApi'
import ModalConfirm from 'general/components/Modal/ModalConfirm'
import ToastHelper from 'general/helpers/ToastHelper'
import { memo, useCallback, useRef } from 'react'
import { useFormik } from 'formik'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import { Typography } from 'antd'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import * as Yup from 'yup'
import { REGEX_TVAN_MESSAGE } from 'general/constants/AppRegex'
const Modals = ({
  tvanState,
  modalsState,
  setModalsState,
  refetchListInvoice,
}) => {
  const moveToSendErrorHandler = useCallback(async ({ invoiceId }) => {
    // Your logic to move to send invoice goes here
    // For example, you can call an API endpoint to move to send invoice
    // await sendInvoiceToHandler(payload)
    try {
      const { result, message } = await TvanHandleApi.moveToSendError({
        invoiceId,
      })

      if (result === 'success') {
        ToastHelper.showSuccess(message)
      }
    } catch (error) {
      console.error(
        'Occured error at moveToSendErrorHandler function: ' + error,
      )
    }
    refetchListInvoice()
  }, [])
  const updateMessage = useCallback(async (values) => {
    try {
      const { result, message } = await TvanHandleApi.updateMessage(values)
      if (result === 'success') {
        ToastHelper.showSuccess(message)
      }
    } catch (error) {
      console.error(error.message)
    }
    setModalsState({ key: 'updateMessageModal', value: false })
    refetchListInvoice()
  }, [])

  const updateMessageForm = useFormik({
    initialValues: {
      messageId: '',
    },
    validationSchema: Yup.object({
      messageId: Yup.string()
        .matches(REGEX_TVAN_MESSAGE, 'Mã thông điệp không hợp lệ, hãy thử lại!')
        .required('Mã thông điệp không được để trống'),
    }),
    onSubmit: async (values) => {
      await updateMessage({
        invoiceId: tvanState.activeRow?.invoiceId,
        messageId: values.messageId.trim(),
      })
    },
  })
  const thisModalRef = useRef()

  return (
    <>
      <ModalConfirm
        header="Xác nhận"
        content={`Bạn cần chắc chắn rằng hóa đơn này đang ở trạng thái chờ CQT, 
                  trạng thái Tvan là chờ đối soát CQT và trên thuế chưa tồn tại hóa đơn
                  Bạn vẫn muốn thực hiện chứ?`}
        show={modalsState.moveStatusModal}
        setShow={(status) =>
          setModalsState({ key: 'moveStatusModal', value: status })
        }
        handleConfirm={(e) => {
          e.preventDefault()
          moveToSendErrorHandler({ invoiceId: tvanState.activeRow?.invoiceId })
          setModalsState({ key: 'moveStatusModal', value: false })
        }}
      />

      <DraggableModal
        ref={thisModalRef}
        applyText="Đồng ý"
        cancelText="Hủy bỏ"
        handleApplyChanges={() => updateMessageForm.submitForm()}
        title="Cập nhật mã thông điệp"
        isOpen={modalsState.updateMessageModal}
        onOpenChange={() => {
          setModalsState({ key: 'updateMessageModal', value: false })
          updateMessageForm.resetForm()
        }}
        description="Bạn cần chắc chắn rằng hóa đơn này đã có thông điệp đúng trên Tvan
        và đã được thuế chấp nhận (Đã có dữ liệu trên trang thuế). 
        Sau đó, hãy nhập mã thông điệp đúng vào input bên dưới"
        children={
          <>
            <Typography.Text className="mr-2" strong>
              Mã thông điệp đúng
            </Typography.Text>
            <CustomAntInput
              type="text"
              placeholder="Nhập mã thông điệp đúng"
              inputProps={{
                ...updateMessageForm.getFieldMeta('messageId'),
                onChange: (e) => {
                  updateMessageForm.setFieldValue('messageId', e.target.value)
                },
              }}
            />
            {updateMessageForm.getFieldMeta('messageId').touched &&
              updateMessageForm.getFieldMeta('messageId').error && (
                <Typography.Text type="danger" className="font-size-sm">
                  {updateMessageForm.getFieldMeta('messageId').error}
                </Typography.Text>
              )}
          </>
        }
      />
    </>
  )
}
export default memo(Modals)
