import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import invoiceApi from 'api/invoiceApi'
import TvanHandleApi from 'api/TvanHandleApi'
import { showNotification } from 'app/appSlice'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'

const keyFactory = {
  base: { scope: ['invoice', 'listIssuedTicket'] },
  lists: () => ({
    ...keyFactory.base,
    entity: 'invoice',
  }),
  pullMessages: () => ({
    ...keyFactory.base,
    entity: 'taxAuthorityMessages',
  }),
}

export const useQueryListInvoice = (filterParams) => {
  const toTableData = (row, idx) => ({
    ...row,
    id: idx + 1,
    no: row?.no ? InvoiceHelper.padNumber(row.no, 8) : null,
  })

  const queryResult = useQuery({
    queryKey: keyFactory.lists(filterParams),
    queryFn: () =>
      TvanHandleApi.getListInvoice({
        ...filterParams,
      }),
    select: (res) => ({
      total: _.get(res?.metadata, 'count', 0),
      tableData: !res?.metadata?.rows
        ? []
        : _.map(res.metadata?.rows, toTableData),
      statistics: _.get(res, 'statistics', {}),
    }),
  })
  const { data, isLoading, isFetching, refetch } = queryResult
  return {
    invoiceListData: data?.tableData,
    isGettingListData: isLoading,
    isRefetchingListData: isFetching,
    refetchListInvoice: refetch,
    ...queryResult,
  }
}

export const useMutationPullBulkMessage = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const filterParams = useAppSelector((s) => s.filter.TvanHandle)
  return useMutation({
    mutationKey: keyFactory.pullMessages(),
    mutationFn: ({ startDate, endDate }) =>
      TvanHandleApi.tvanCheckMessages({
        startDate,
        endDate,
        isCompany: false,
      }),
    onError: (err) => console.error(err.message),
    onSuccess: (res) => {
      console.log('response when pull messages sucessfully', res)
      let describeText = `Kiểm tra mã thành công cho ${res?.count} hóa đơn.`
      dispatch(showNotification({ describeText }))
      queryClient.invalidateQueries(
        keyFactory.lists('', { ...filterParams, page: 1 }),
      )
    },
  })
}
