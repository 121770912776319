import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Space, theme, Tooltip } from 'antd'
import invoiceReportApi from 'api/invoiceReportApi'
import { setAppSpinning } from 'app/appSlice'
import dayjs from 'dayjs'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import FindInvoiceModal from 'general/components/Modal/FindInvoiceModal'
import ModalUnauthorized from 'general/components/Modal/ModalUnauthorized'
import SendInvoiceReportModal from 'general/components/Modal/SendInvoiceReportModal'
import { INVOICE_TYPE_TO_QUERY } from 'general/constants/AppConstants'
import SelectOptions from 'general/constants/SelectOptions'
import InvoiceReportHelper from 'general/helpers/InvoiceReportHelper'
import Utils from 'general/utils/Utils'
import useFilter from 'hooks/useFilter'
import useRouter from 'hooks/useRouter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { queries } from './queries'
import './style.scss'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
import invoiceApi from 'api/invoiceApi'
import ToastHelper from 'general/helpers/ToastHelper'

function ListAdjustedInvoice() {
  const { navigate } = useRouter()
  const dispatch = useDispatch()
  const [FindInvoiceModalShow, setFindInvoiceModalShow] = useState(false)
  const [showSendAdjustReportModal, setShowSendAdjustReportModal] =
    useState(false)
  const { token } = theme.useToken()

  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const [activeAdjustInvoice, setActiveAdjustInvoice] = useState()
  const [showNoRight, setShowNoRight] = useState(false)
  const listFunction = useSelector((state) => state?.function?.listFunctions)
  const userFunction = useSelector((state) => state?.function?.userFunctions)
  const [isCreateAdjustedInvoice] = useMemo(() => {
    return Utils.checkFunction(
      [
        {
          listFunctionCode: 'INVOICE_CREATE_ADJUSTED_INVOICE',
        },
      ],
      userFunction,
      listFunction,
    )
  }, [userFunction, listFunction])
  const [filter, setFilter] = useFilter(
    'adjustedInvoiceList',
    'gFilterListAdjustedInvoice',
  )

  const queryClient = useQueryClient()

  const {
    data: listAdjustedData,
    isLoading: gettingListData,
    isFetching,
  } = useQuery(queries.lists(filter))

  const { mutate: previewPdfReport } = useMutation({
    mutationKey: 'previewPdfReport',
    mutationFn: (reportId) =>
      invoiceReportApi.preview({
        reportId,
      }),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: (result) =>
      Utils.openFileInNewTab({
        blobData: result,
        fileName: 'Biên bản điều chỉnh.pdf',
        fileType: 'application/pdf',
      }),
  })
  /** @type {FilterDefaultValueProp} */
  const defaultValues = [
    {
      label: 'Trạng thái lập hoá đơn điều chỉnh',
      alias: 'Trạng thái lập HĐ điều chỉnh',
      formName: 'isReplace',
      options: SelectOptions.isReplace,
      value: SelectOptions.isReplace[0].value,
    },
    {
      label: 'Trạng thái biên bản',
      alias: 'Trạng thái biên bản',
      formName: 'reportStatus',
      options: SelectOptions.reportStatus,
      value: SelectOptions.reportStatus[0].value,
    },
  ]

  const columns = useMemo(
    () => [
      {
        name: <span className="red-header">Đơn vị lập hoá đơn</span>,
        width: '300px',
        center: true,
        className: 'red-header',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.sellerName}
            </p>
          )
        },
      },
      {
        name: <span className="red-header">Ký hiệu</span>,
        // sortable: false,
        // right: true,
        width: '110px',
        center: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.serial}
            </p>
          )
        },
      },
      {
        name: <span className="red-header">Ngày HĐ</span>,
        // sortable: false,
        width: '120px',
        center: true,
        cell: (row) => {
          const date = dayjs(row?.date)
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {/* {row?.date.slice(0, 10)} */}
              {date.isValid() ? date.format('L') : ''}
            </p>
          )
        },
      },
      {
        name: <span className="red-header">Số</span>,
        width: '110px',
        center: true,
        cell: (row) => {
          const no = row?.no && Utils.padString(row?.no, 8, '0')
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {no}
            </p>
          )
        },
      },
      {
        name: <span className="red-header">Mã CQT</span>,
        width: '200px',
        center: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.taxAuthorityCode}
            </p>
          )
        },
      },
      {
        name: <span className="text-primary">Khách hàng</span>,
        // width: '200px',
        width: '170px',
        style: { justifyContent: 'space-between' },
        center: true,
        cell: (row) => {
          return (
            <>
              <p
                data-tag="allowRowEvents"
                className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
              >
                {row?.Relate?.customerCompanyName}
              </p>
              {/* {_.isEmpty(row?.Relate?.customerCompanyName) ? null : (
                <TableAction
                  titleActionText="Xem hóa đơn bị điều chỉnh"
                  icon={<IconAction className="fa-light fa-eye" />}
                  onClick={() => {
                    Utils.viewPdfInvoice(row, dispatch, setAppSpinning)
                  }}
                />
              )} */}
              {/* {row.isInternal && (
                <TableAction
                  titleActionText="Xem hóa đơn bị điều chỉnh"
                  icon={<IconAction className="fa-light fa-eye" />}
                  onClick={() => {
                    Utils.viewPdfInvoice(row, dispatch, setAppSpinning)
                  }}
                />
              )} */}
            </>
          )
        },
      },
      {
        name: '',
        width: '60px',
        style: { justifyContent: 'space-between' },
        center: true,
        cell: (row) => {
          return (
            <>
              {row.isInternal && (
                <TableAction
                  titleActionText="Xem hóa đơn bị điều chỉnh"
                  icon={<IconAction className="fa-light fa-eye" />}
                  onClick={() => {
                    Utils.viewPdfInvoice(row, dispatch, setAppSpinning)
                  }}
                />
              )}
            </>
          )
        },
      },
      {
        name: <span className="text-primary">Biên bản điện tử</span>,
        width: '160px',
        center: true,
        cell: (row) => {
          const no = row?.report?.no && Utils.padString(row?.report?.no, 8, '0')
          return (
            <div className="d-flex w-100 justify-content-between align-items-center">
              <Tooltip
                title={InvoiceReportHelper.renderInvoiceReportStatus(
                  row?.reportStatus,
                )}
                placement="bottom"
              >
                <p
                  data-tag="allowRowEvents"
                  className={`text-hover-decoration font-weight-normal m-0 text-maxline-3 mr-4 ${
                    no ? 'text-danger' : 'text-primary'
                  }`}
                  onClick={() => {
                    // if (no) {
                    let invoiceId = row?.invoiceId
                    if (row?.reportStatus == 1) {
                      navigate(
                        `/hoa-don-dau-ra/hoa-don/bien-ban-dieu-chinh/them-moi/${invoiceId}`,
                      )
                    }

                    if (row?.reportStatus == 2) {
                      navigate(
                        `/hoa-don-dau-ra/hoa-don/bien-ban-dieu-chinh/cap-nhat/${invoiceId}`,
                      )
                    }

                    if (![1, 2].includes(row?.reportStatus)) {
                      navigate(
                        `/hoa-don-dau-ra/hoa-don/bien-ban-dieu-chinh/xem/${invoiceId}`,
                      )
                    }
                    // }
                  }}
                >
                  {no ?? 'Lập biên bản'}
                </p>
              </Tooltip>
              {![1, 2].includes(row?.reportStatus) && (
                <TableAction
                  icon={<IconAction className="fa-light fa-eye" />}
                  titleActionText="Xem biên bản điện tử"
                  onClick={() => previewPdfReport(row?.report?.reportId)}
                />
              )}
            </div>
          )
        },
      },
      {
        name: <span className="text-primary">Ký hiệu</span>,
        width: '110px',
        center: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.Relate?.serial}
            </p>
          )
        },
      },
      {
        name: <span className="text-primary">Ngày HĐ</span>,
        width: '120px',
        center: true,
        cell: (row) => {
          const date = dayjs(row?.Relate?.date)
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {date.isValid() ? date.format('L') : ''}
            </p>
          )
        },
      },
      {
        name: <span className="text-primary">Số</span>,
        width: '110px',
        center: true,
        cell: (row) => {
          const no = row?.Relate?.no && Utils.padString(row?.Relate?.no, 8, '0')
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {no}
            </p>
          )
        },
      },
      {
        name: <span className="text-primary">Mã CQT</span>,
        width: '200px',
        center: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.Relate?.taxAuthorityCode}
            </p>
          )
        },
      },
      {
        name: <span className="text-primary">Lý do điều chỉnh</span>,
        width: '200px',
        center: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.Relate?.description}
            </p>
          )
        },
      },
      {
        name: '',
        minWidth: '100px',
        center: true,
        compact: true,
        cell: (row) => {
          return (
            <Space align="center">
              {/* <TableAction
                icon={
                  <IconAction
                    className="fa-light fa-eye"
                    style={{ color: token.colorError }}
                  />
                }
                titleActionText="Xem hoá đơn bị điều chỉnh"
                onClick={() => {
                  Utils.viewPdfInvoice(row, dispatch, setAppSpinning)
                }}
              /> */}
              <TableAction
                icon={
                  <IconAction
                    className="fa-light fa-eye"
                    style={{
                      color: token.colorPrimary,
                    }}
                  />
                }
                titleActionText="Xem hoá đơn điều chỉnh"
                onClick={() => {
                  Utils.viewPdfInvoice(row?.Relate, dispatch, setAppSpinning)
                }}
              />
              {[3, 4].includes(row?.reportStatus) && (
                <TableAction
                  titleActionText="Gửi biên bản điện tử"
                  icon={<IconAction className="fa-light fa-paper-plane" />}
                  onClick={() => {
                    setActiveAdjustInvoice(row)
                    setShowSendAdjustReportModal(true)
                  }}
                />
              )}
            </Space>
          )
        },
      },
    ],
    [
      dispatch,
      navigate,
      previewPdfReport,
      token.colorError,
      token.colorPrimary,
    ],
  )

  useEffect(() => {
    const startColumnFirstHeader = 1
    const columnsSpanFirstHeader = 5
    const startColumnSecondHeader = 6
    const columnsSpanSecondHeader = 13
    const addHeaderTable = () => {
      const firstDivToRemove = document.querySelector('div.table-red-header')
      const secondDivToRemove = document.querySelector(
        'div.table-header-normal',
      )

      if (firstDivToRemove) {
        firstDivToRemove.remove()
      }
      if (secondDivToRemove) {
        secondDivToRemove.remove()
      }
      let firstHeaderWidth = 0
      let secondHeaderWidth = 0

      for (let i = startColumnFirstHeader; i <= columnsSpanFirstHeader; i++) {
        const div = document.querySelector(`div[data-column-id="${i}"]`)
        if (div) {
          firstHeaderWidth += div.getBoundingClientRect().width
        }
      }
      for (let i = startColumnSecondHeader; i <= columnsSpanSecondHeader; i++) {
        const div = document.querySelector(`div[data-column-id="${i}"]`)
        if (div) {
          secondHeaderWidth += div.getBoundingClientRect().width
        }
      }
      const rowDiv = document.querySelector('div[role="rowgroup"]')
      if (rowDiv) {
        const hasRowGroup =
          rowDiv.parentNode.querySelector('#rowGroupContainer')
        let containerDiv
        if (!hasRowGroup) {
          containerDiv = document.createElement('div')
          containerDiv.classList.add('d-flex')
          containerDiv.id = 'rowGroupContainer'
        } else
          containerDiv = rowDiv.parentNode.querySelector('#rowGroupContainer')

        const firstHeaderDiv = document.createElement('div')
        // firstHeaderDiv.classList.add('border')
        firstHeaderDiv.classList.add('table-red-header')
        firstHeaderDiv.style.width = `${Math.floor(firstHeaderWidth)}px`
        const spanElementFirstHeader = document.createElement('span')
        spanElementFirstHeader.textContent = 'Hoá đơn bị điều chỉnh'
        firstHeaderDiv.appendChild(spanElementFirstHeader)

        const secondHeaderDiv = document.createElement('div')
        // secondHeaderDiv.classList.add('border')
        secondHeaderDiv.classList.add('table-header-normal')
        secondHeaderDiv.style.width = `${Math.ceil(secondHeaderWidth)}px`
        const spanElementSecondHeader = document.createElement('span')
        spanElementSecondHeader.textContent = 'Hoá đơn điều chỉnh'
        secondHeaderDiv.appendChild(spanElementSecondHeader)

        containerDiv.appendChild(firstHeaderDiv)
        containerDiv.appendChild(secondHeaderDiv)
        // Insert the newDiv before the rowDiv
        rowDiv.parentNode.insertBefore(containerDiv, rowDiv)
        // firstHeaderDiv.parentNode.insertBefore(secondHeaderDiv, firstHeaderDiv.nextSibling);
      }
    }
    !gettingListData && addHeaderTable()
  }, [gettingListData])

  useEffect(() => {
    setFilter({
      q: debouncedSearch,
      page: 1,
    })
  }, [debouncedSearch, setFilter])

  const navigateHandler = (row, classify) => {
    switch (classify) {
      case 'PXK':
        return navigate(
          `/hoa-don-dau-ra/xu-ly-hoa-don/phieu-xuat-kho/dieu-chinh/${row?.invoiceId}`,
        )
      case 'MTT':
        return navigate(
          `/hoa-don-dau-ra/xu-ly-hoa-don/may-tinh-tien/dieu-chinh/${row?.invoiceId}`,
        )
      default:
        return navigate(
          `/hoa-don-dau-ra/xu-ly-hoa-don/dieu-chinh/${row?.invoiceId}`,
        )
    }
  }

  return (
    <ContentContainer wrapperClassName="list-adjusted-invoice">
      <ContentContainer.Header
        titleContent={'Bảng kê hóa đơn đã điều chỉnh'}
        toolBar={
          <Space>
            <CustomAntButton
              text="Lập hoá đơn điều chỉnh"
              iconCn="fa-light fa-plus"
              antProps={{
                type: 'primary',
                onClick: () => {
                  if (isCreateAdjustedInvoice) setFindInvoiceModalShow(true)
                  if (!isCreateAdjustedInvoice) setShowNoRight(true)
                },
              }}
            />
          </Space>
        }
      >
        <Space>
          <CustomAntInput
            borderRadius="sm"
            type="search"
            loading={isFetching}
            inputProps={{
              value: search,
              onChange: (e) => setSearch(e.target.value),
            }}
          />
          <FilterPopover
            defaultValues={defaultValues}
            onApply={setFilter}
            savedKey={'gFilterListAdjustedInvoice'}
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          wrapperClassName="table-replaced-invoice"
          columns={columns}
          dataSource={listAdjustedData?.rows}
          isLoading={gettingListData}
          persistTableHead
          pagination={{
            currentPage: filter?.page,
            rowsPerPage: filter?.limit,
            total: listAdjustedData?.total,
            onChangePage: (newPage) => {
              setFilter({
                page: parseInt(newPage),
              })
            },
            onChangeRowsPerPage: (newPerPage) => {
              setFilter({
                limit: parseInt(newPerPage),
                page: 1,
              })
            },
          }}
        />
      </ContentContainer.Body>
      <FindInvoiceModal
        show={FindInvoiceModalShow}
        onHide={() => setFindInvoiceModalShow(false)}
        title="Chọn hoá đơn điều chỉnh"
        onRowClicked={(row, classify) => {
          navigateHandler(row, classify)
        }}
        checkBoxShow={false}
        invoiceType={INVOICE_TYPE_TO_QUERY.CAN_ADJUSTED}
        buttonAgreeVisibility={true}
        buttonAgreeOnlick={() => {}}
        buttonAgreeTab2Onlick={async (invoice) => {
          invoice = InvoiceHelper.validateInvoiceFromExternal(invoice)
          if (!invoice) return

          /* Check external */
          try {
            dispatch(setAppSpinning(true))
            let res = await invoiceApi.checkExternal(invoice)
            dispatch(setAppSpinning(false))

            if (!res?.data) return

            if (res.data == true) {
              const queryParams = new URLSearchParams(invoice)
              navigate(
                `/hoa-don-dau-ra/xu-ly-hoa-don/dieu-chinh-khac-he-thong/${queryParams.toString()}`,
              )
              return
            }

            navigate(`/hoa-don-dau-ra/xu-ly-hoa-don/dieu-chinh/${res.data}`)
          } catch (error) {
            dispatch(setAppSpinning(false))
            ToastHelper.showError(error.message)
          }
        }}
      />

      <SendInvoiceReportModal
        reportType={'ADJUST'}
        invoice={activeAdjustInvoice}
        show={showSendAdjustReportModal}
        onHide={() => setShowSendAdjustReportModal(false)}
        addonsSubmitAction={() =>
          queryClient.invalidateQueries(queries.lists().queryKey)
        }
      />
      <ModalUnauthorized show={showNoRight} setShow={setShowNoRight} />
    </ContentContainer>
  )
}

export default ListAdjustedInvoice
