import axiosClient from './axiosClient'

export const transactionApi = {
  get: (params, signal) =>
    axiosClient.get('/data-log/list', {
      params,
      signal,
    }),
  getStations: (params, signal) =>
    axiosClient.get('/station-petro/list', {
      params,
      signal,
    }),
  getNozzles: (stationPetroId, signal) =>
    axiosClient.get('/station-petro/nozzle', {
      params: { stationPetroId },
      signal,
    }),
  cancelInvoice: (params) =>
    axiosClient.patch('/data-log/cancel-transaction', params.body, {
      params: {
        petroId: params.stationPetroId,
      },
    }),

  toggleMarkInvoice: ({ body, stationPetroId }) =>
    axiosClient.patch(
      '/data-log/toggle-mark',
      {
        isMarked: body.isMarked,
        noteMarked: body.note,
      },
      {
        params: {
          petroId: stationPetroId,
        },
      },
    ),

  remove: (params, signal) =>
    axiosClient.delete('/data-log/remove', {
      params,
      signal,
    }),
}
