import { useQuery } from '@tanstack/react-query'
import templateInvoiceApi from 'api/templateInvoiceApi'
import { useAppSelector } from 'hooks/useRedux'
import _null from 'lodash/isNull'
import _undefined from 'lodash/isUndefined'
import _reduce from 'lodash/reduce'
import { shallowEqual } from 'react-redux'

/**
 * @param {{
 * includeAllOption: boolean,
 *  categoryTicketTypePrice?: number,
 *  disabled ?: boolean }
 * } props
 */

export default function useGetTicketSerial(props) {
  const systemType = useAppSelector(
    (state) => state.auth.activeInvoice,
    shallowEqual,
  )
  return useQuery({
    enabled: systemType?.ticket && !props.disabled,
    queryKey: ['ticketSerials', props],
    queryFn: () => templateInvoiceApi.getTicketSerials(props),
    select: (res) => {
      return _null(res?.data) || _undefined(res?.data)
        ? []
        : _reduce(
            res.data,
            (prev, curr) => [
              ...prev,
              {
                name: curr.name,
                label: `${curr.serial} - ${curr.name}`,
                value: curr.serial,
                invoiceTemplateId: curr.invoiceTemplateId,

                routeType: curr.routeType,
                category: curr.category,
                category_ticket: curr.category_ticket,
              },
            ],
            props?.includeAllOption
              ? [
                  {
                    label: 'Tất cả',
                    name: 'Tất cả',
                    value: null,
                    invoiceTemplateId: null,
                  },
                ]
              : [],
          )
    },
  })
}
