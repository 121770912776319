import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Table } from 'antd'
import extendFieldApi from 'api/extendFieldApi'
import invoiceApi from 'api/invoiceApi'
import templateInvoiceApi from 'api/templateInvoiceApi'
import { setAppSpinning } from 'app/appSlice'
import customSelectTableCellStyle from 'assets/styles/customSelectTableCellStyle'
import dayjs from 'dayjs'
import {
  thunkGetGoodsGroupList,
  thunkGetGoodsList,
  thunkGetMoneyTypeList,
} from 'features/Category/categorySlice'
import { useFormik } from 'formik'
import InvoiceFooter from 'general/components/eInvoiceComponents/AppFooter/InvoiceFooter'
import BaseTextFieldNoFormik from 'general/components/Forms/BaseTextFieldNoFormik'
import InputNumberNoFormik from 'general/components/Forms/InputNumerNoFormik'
import AddCustomFieldModal from 'general/components/Modal/AddCustomFieldModal'
import ModalConfirm from 'general/components/Modal/ModalConfirm'
import SendInvoiceModal from 'general/components/Modal/SendInvoiceModal'
import SelectOptions from 'general/constants/SelectOptions'
import ToastHelper from 'general/helpers/ToastHelper'
import Global from 'general/utils/Global'
import Utils, { regexTaxCode } from 'general/utils/Utils'
// import moment from 'moment';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import CustomAntSelect from 'general/components/CustomAntSelect'
import IssueInvoiceFromPosModal from 'general/components/Modal/IssueInvoiceFromPosModal'
import {
  ACTION_TYPE,
  DISCOUNT_TYPE,
  INVOICE_TYPE,
} from 'general/constants/AppConstants'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import useRouter from 'hooks/useRouter'
import _, { result } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import GoodsAutoComplete from './Components/AutoComplete/GoodsAutoComplete'
import FinalMoneyDisplay from './Components/FinalMoneyDisplay'
import InvoiceGeneralInfo from './Components/InvoiceGeneralInfo'
import DeliveryGeneralInfo from './Components/DeliveryGeneralInfo'
import InvoiceTableGeneralInfo from './Components/InvoiceTableGeneralInfo'
import SelectGeneralDiscountAndTaxRate from './Components/SelectGeneralDiscountAndTaxRate'
import CreatedInvoiceUnit from './Components/TreeSelect/CreatedInvoiceUnit'
import calculateExchangedMoneyTableData from './Function/CalculateTableData'
import createNewInvoice from './Function/CreateNewInvoice'
import updateInvoice from './Function/UpdateInvoice'
import './style.scss'
import TableHospitalInfo from './Components/TableHospitalInfo'
import { useParams } from 'react-router-dom'

var submitAction
function CreateInvoice() {
  const [invoiceType, setInvoiceType] = useState()
  const { currentAccount } = useAppSelector((state) => state?.auth)
  const { company } = useAppSelector((state) => state.system)
  const invoiceFooterRef = useRef(null)

  const dispatch = useAppDispatch()
  const { navigate, query, pathname } = useRouter()
  const { invoiceInfo } = useParams()
  const partsName = pathname.split('/')
  const isFromPOS = useMemo(
    () => pathname.includes('may-tinh-tien'),
    [pathname],
  )
  const isDeliveryBill = useMemo(
    () => pathname.includes('phieu-xuat-kho'),
    [pathname],
  )

  const { invoiceId } = query
  const moneyTypes = useAppSelector((state) => state?.category?.moneyTypes)
  const { formatNumber, currency, VATConfig, invoiceSetting } = useAppSelector(
    (state) => state?.systemConfig,
  )
  const showPriceAfterTax = invoiceSetting?.priceAfterTax
  const [buyerInfoExtendFields, setBuyerInfoExtendFields] = useState([])
  const [tableDetailExtendFields, setTableDetailExtendFields] = useState([])
  const [sendDraftInvoiceModalShow, setSendDraftInvoiceModalShow] =
    useState(false)
  const [AddCustomFieldModalShow, setAddCustomFieldModalShow] = useState(false)
  const [templateInvoiceSerials, setTemplateInvoiceSerials] = useState([])
  const [currencies, setCurrencies] = useState([...SelectOptions.currency])
  const [fetchedInvoiceInfo, setFetchedInvoiceInfo] = useState({})
  const [discountTypeArray, setDiscountTypeArray] = useState(
    SelectOptions.discountType,
  )
  const [actionType, setActionType] = useState('')
  const [latestInvoice, setLatestInvoice] = useState()
  const [latestInvoiceTemplate, setLatestInvoiceTemplate] = useState()
  const [createdInvoiceId, setCreatedInvoiceId] = useState()
  const [
    showModalConfirmSaveToSendInvoice,
    setShowModalConfirmSaveToSendInvoice,
  ] = useState(false)
  const [showModalConfirmDuplicateName, setShowModalConfirmDuplicateName] =
    useState(false)
  const [checkedHHDV, setCheckedHHDV] = useState(false)
  const [invoiceData, setInvoiceData] = useState(true)
  const [persistent, setPersistent] = useState('quantityPersistent')
  const [inputValue, setInputValue] = useState('')
  const [isChangeTaxRate, setIsChangeTaxRate] = useState(false)
  const [isChangeTable, setIsChangeTable] = useState(false)
  const [issueInvoiceFromPosModalInfo, setIssueInvoiceFromPosModalInfo] =
    useState({ show: false, invoiceId: null })
  const [inforActionSaveAndSign, setInforActionSaveAndSign] = useState({
    disabled: false,
  })

  const fetchInvoiceData = async (invoiceId) => {
    try {
      const res = await invoiceApi.findById(invoiceId)
      if (res?.result === 'success') {
        setInvoiceData(res.data)
        return res.data
      } else {
        navigate(navigateHandler())
        ToastHelper.showError('Không tìm thấy dữ liệu')
      }
    } catch (err) {
      navigate(navigateHandler())
      ToastHelper.showError('Có lỗi khi tìm dữ liệu')
    }
  }

  const onCheckTaxReduction = () => {
    formik.setFieldValue('isTaxReduction', !formik.values.isTaxReduction)
    let newTableData = [...tableData]

    newTableData.forEach((item) => {
      item.taxReduction = 0
      item.taxReductionRate = 0
      item.taxReductionExchange = 0
    })
    setTableData(newTableData)
  }

  const onChangeCheckHHDV = (e) => {
    setCheckedHHDV(e.target.checked)
  }
  const onChangePayment = (e) => {
    formik.setFieldValue('isPayment', e.target.checked)
  }

  const onToggleMulTaxRate = (e) => {
    formik.setFieldValue('multipleTaxRate', e.target.checked)
    // if (!e.target.checked) {
    let taxRate = formik.values.taxRate
    if (
      (taxRate == '0' || taxRate == '5' || taxRate == '8' || taxRate == '10') &&
      (formik.values.taxType === 1 ||
        formik.values.taxType === 2 ||
        (formik.values.taxType === 3 && !formik.values.multipleTaxRate) ||
        formik.values.multipleTaxRate) &&
      parseInt(formik.values?.serial?.charAt(0)) === 1
    ) {
      taxRate = parseInt(taxRate)
    } else if (taxRate !== 'KHAC') {
      taxRate = 0
    }
    let newTableData = [...tableData]
    newTableData.forEach((item) => {
      item.taxRate = taxRate
      item.unitPrice = (item.unitPriceAfterTax / (100 + taxRate)) * 100
      item.totalUnitPrice = item.unitPrice * item.quantity
      item.taxMoney = (item.totalUnitPrice * taxRate) / 100
    })
    let totalPriceAfterTax = formik.values?.totalAfterTax
    let totalPrice = (totalPriceAfterTax / (100 + taxRate)) * 100
    let taxMoney = (totalPrice * taxRate) / 100
    formik.setFieldValue('total', totalPrice)
    formik.setFieldValue('taxMoney', taxMoney)
    setTableData(newTableData)
    // }
  }

  // MARK --- Function ---

  async function fetchExtendFields() {
    let res = await extendFieldApi.getExtendFields()

    if (!res?.extendFields) {
      setTableDetailExtendFields([])
      setBuyerInfoExtendFields([])
      return
    }

    setTableDetailExtendFields(
      res.extendFields.filter(({ regionName }) =>
        ['tableDetail', 'tableDetail1'].includes(regionName),
      ),
    )
    setBuyerInfoExtendFields(
      res.extendFields.filter(({ regionName }) => regionName == 'buyerInfo'),
    )
  }
  useEffect(() => {
    const fetchingResources = async () => {
      try {
        unwrapResult(
          dispatch(thunkGetGoodsGroupList(Global.gFiltersGoodsGroupList)),
        )
        unwrapResult(dispatch(thunkGetGoodsList()))
      } catch (error) {
        console.error(error)
      }
    }
    fetchingResources()
  }, [dispatch])

  useEffect(() => {
    dispatch(thunkGetMoneyTypeList(Global.gFiltersMoneyTypeList))
    fetchTemplateSerial()

    if (partsName.includes('them-moi')) {
      setActionType(ACTION_TYPE.CREATE)
    }
    if (
      partsName.includes('dieu-chinh') ||
      partsName.includes('dieu-chinh-khac-he-thong')
    ) {
      setActionType(ACTION_TYPE.ADJUST)
    }
    if (
      partsName.includes('thay-the') ||
      partsName.includes('thay-the-khac-he-thong')
    ) {
      setActionType(ACTION_TYPE.REPLACE)
    }
    if (partsName.includes('cap-nhat')) {
      setActionType(ACTION_TYPE.UPDATE)
    }

    // huy
    fetchExtendFields()
    // // lay ten hang hoa
    // setStateGoods(row?.name ? optionsGoods.find((option) => option.value === row?.name)
    //   : null)
  }, [])

  const fetchLatestInvoice = async () => {
    try {
      let classify
      switch (window.location.pathname) {
        case '/hoa-don-dau-ra/hoa-don/them-moi':
          classify = 'HD'
          break
        case '/hoa-don-dau-ra/hoa-don/may-tinh-tien/them-moi':
          classify = 'MTT'
          break
        case '/hoa-don-dau-ra/hoa-don/phieu-xuat-kho/them-moi':
          classify = 'PXK'
          break
        default:
      }
      const res = await invoiceApi.latest({ ...(classify && { classify }) })
      if (res?.result === 'success') {
        setLatestInvoice(res.rows)
        setInvoiceType(res.rows?.invoice_template?.type)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const fetchLatestInvoiceTemplate = async (invoiceTemplateId) => {
    try {
      const res = await invoiceApi.findLatestInvoiceRelease(invoiceTemplateId)
      if (res?.result === 'success') {
        setLatestInvoiceTemplate(res.rows)
        // setTemplateIdUsed(res.rows?.invoiceTemplateId);
      }
    } catch (err) {
      console.log(err)
    }
  }

  const systemTaxRate = useMemo(() => {
    return VATConfig?.value?.replace('%', '') ?? '0'
  }, [VATConfig])

  const hasDuplicateValueForKey = (arr, key) => {
    const values = _.map(arr, key)
    const uniqueValues = _.uniq(values)
    return values.length !== uniqueValues.length
  }

  useEffect(() => {
    if (partsName.includes('them-moi') && !invoiceId) {
      setTableData([{ ...tableData[0], taxRate: systemTaxRate }])
    }
  }, [systemTaxRate, invoiceId])

  useEffect(() => {
    if (partsName.includes('them-moi') && !invoiceId) {
      fetchLatestInvoice()
    }
    if (invoiceId) {
      fetchInvoiceData(invoiceId).then((data) => {
        if (data) {
          const { invoiceProduct, extend_fields, ...restInvoiceInfo } = data
          if (data.type === INVOICE_TYPE.ADJUST) {
            formik.setFieldValue('autoCalculate', false)
          }
          if (
            partsName.includes('dieu-chinh') ||
            partsName.includes('dieu-chinh-khac-he-thong')
          ) {
            data &&
              data?.invoiceProduct &&
              setTableData([
                {
                  no: 1,
                  type: 1,
                  name: '',
                  unit: '',
                  unitPrice: 0,
                  quantity: 0,
                  discount: 0,
                  totalUnitPrice: 0,
                  taxRate: data?.taxRate,
                  taxMoney: 0,
                  taxReduction: 0,
                  taxReductionRate: 0,
                  discountMoney: 0,
                  productId: 0,
                  totalAfterTaxVnese: '',
                },
              ])
          } else {
            data &&
              data.invoiceProduct &&
              setTableData([
                ...data.invoiceProduct.map((product) => {
                  if (product.extend_fields?.length) {
                    for (let field of product.extend_fields) {
                      product[field?.name] = {
                        value: field?.invoice_product_extend_field?.value,
                      }
                    }
                  }

                  return product
                }),
              ])
          }
          setFetchedInvoiceInfo(restInvoiceInfo)
          setInvoiceType(data.invoice_template?.type)
          setInvoiceExtendFields(extend_fields)
        }
      })
    }
  }, [invoiceId])

  useEffect(() => {
    if (!invoiceInfo) return

    setFetchedInvoiceInfo(
      invoiceInfo.split('&').reduce(
        (result, item) => ({
          ...result,
          [item.split('=')[0]]: item.split('=')[1],
        }),
        { type: 0 },
      ),
    )
  }, [invoiceInfo])

  const [invoiceExtendFields, setInvoiceExtendFields] = useState([])
  const [tableData, setTableData] = useState([
    {
      no: 1,
      type: 1,
      name: '',
      specification: '',
      inventoryItemCode: '',
      actualExport: 0,
      actualImport: 0,
      unit: '',
      unitPrice: 0,
      quantity: 0,
      discount: 0,
      totalUnitPrice: 0,
      unitPriceAfterTax: 0,
      totalUnitPriceAfterTax: 0,
      taxRate: systemTaxRate ?? '0',
      taxReduction: 0,
      taxReductionExchange: 0,
      taxReductionRate: 0,
      taxMoney: 0,
      discountMoney: 0,
      productId: 0,
      exchangeRate: 1,
      exchangedTotalUnitPriceAfterTax: 0,
      exchangedTotalUnitPrice: 0,
      exchangedTaxMoney: 0,
      exchangedDiscountMoney: 0,
    },
  ])
  const today = new Date()
  const invoiceTemplate = useMemo(() => {
    if (latestInvoice) {
      return templateInvoiceSerials?.find(
        ({ invoiceTemplateId }) =>
          invoiceTemplateId == latestInvoice.invoiceTemplateId,
      )
    }
    if (!fetchedInvoiceInfo.invoiceTemplateId)
      return templateInvoiceSerials?.at(0)

    return templateInvoiceSerials?.find(
      ({ invoiceTemplateId }) =>
        invoiceTemplateId == fetchedInvoiceInfo.invoiceTemplateId,
    )
  }, [templateInvoiceSerials, fetchedInvoiceInfo, latestInvoice])

  const checkIsAdjustInvoice = (type) => {
    return (
      partsName.includes('dieu-chinh') ||
      partsName.includes('dieu-chinh-khac-he-thong') ||
      (partsName.includes('cap-nhat') && type === INVOICE_TYPE.ADJUST)
    )
  }

  const resetButtonSaveAndSign = () => {
    if (submitAction == 'SaveAndSign') {
      setInforActionSaveAndSign({ disabled: false })
    }
  }

  const formik = useFormik({
    initialValues: {
      // invoiceId: '',
      serial: !Utils.isObjectNull(invoiceId)
        ? invoiceTemplate?.serial
        : latestInvoice?.serial,
      invoiceTemplateId: !Utils.isObjectNull(invoiceId)
        ? invoiceTemplate?.invoiceTemplateId
        : latestInvoice?.invoiceTemplateId,
      invoiceTemplateName: invoiceTemplate?.name,
      type: partsName.includes('them-moi') ? 0 : fetchedInvoiceInfo?.type,
      taxType: invoiceTemplate?.category?.taxType,
      currency: fetchedInvoiceInfo?.currency ?? 'VNĐ',
      exchangeRate: fetchedInvoiceInfo?.exchangeRate ?? 1,
      date: Utils.formatDate(
        actionType === ACTION_TYPE.UPDATE ? fetchedInvoiceInfo?.date : today,
      ),
      sellerName: fetchedInvoiceInfo?.sellerName ?? company?.companyName,
      sellerTaxCode: fetchedInvoiceInfo?.sellerTaxCode ?? company?.taxCode,
      sellerFullAddress:
        fetchedInvoiceInfo?.sellerFullAddress ?? company?.businessPermitAddress,
      sellerPhone: fetchedInvoiceInfo?.sellerPhone ?? currentAccount?.phone,
      sellerEmail: fetchedInvoiceInfo?.sellerEmail ?? currentAccount?.email,
      sellerAccountNumber:
        fetchedInvoiceInfo?.sellerAccountNumber ?? company?.bankAccountNumber,
      sellerBankName: fetchedInvoiceInfo?.sellerBankName ?? company.bankName,
      sellerFax: fetchedInvoiceInfo?.sellerFax ?? company?.fax,
      sellerWebsite: fetchedInvoiceInfo?.sellerWebsite ?? company?.website,
      customerCompanyName: fetchedInvoiceInfo?.customerCompanyName ?? '',
      customerName: fetchedInvoiceInfo?.customerName ?? '',
      customerTaxCode: fetchedInvoiceInfo?.customerTaxCode ?? '',
      customerId: fetchedInvoiceInfo?.customerId ?? '',
      customerFullAddress: fetchedInvoiceInfo?.customerFullAddress ?? '',
      customerPhone: fetchedInvoiceInfo?.customerPhone ?? '',
      customerEmail: fetchedInvoiceInfo?.customerEmail ?? '',
      customerAccountNumber: fetchedInvoiceInfo?.customerAccountNumber ?? '',
      customerBankName: fetchedInvoiceInfo?.customerBankName ?? '',

      customerPersonalIdentificationNumber:
        fetchedInvoiceInfo?.customerPersonalIdentificationNumber ?? '',

      taxRate: fetchedInvoiceInfo?.taxRate ?? systemTaxRate ?? '0',
      total:
        actionType === ACTION_TYPE.ADJUST
          ? 0
          : (fetchedInvoiceInfo?.total ?? 0),
      taxMoney:
        actionType === ACTION_TYPE.ADJUST
          ? 0
          : (fetchedInvoiceInfo?.taxMoney ?? 0),
      totalAfterTax:
        actionType === ACTION_TYPE.ADJUST
          ? 0
          : (fetchedInvoiceInfo?.totalAfterTax ?? 0),
      totalAfterTaxVnese:
        actionType === ACTION_TYPE.ADJUST
          ? ''
          : (fetchedInvoiceInfo?.totalAfterTaxVnese ?? 'Không đồng'),
      discountType: fetchedInvoiceInfo?.discountType ?? 0,
      discountMoney:
        actionType === ACTION_TYPE.ADJUST
          ? 0
          : (fetchedInvoiceInfo?.discountMoney ?? 0),
      discount: fetchedInvoiceInfo?.discount ?? null, // % chiết khấu
      isSent: 0,
      isPayment:
        actionType === ACTION_TYPE.UPDATE
          ? fetchedInvoiceInfo?.isPayment
          : false,
      // : fetchedInvoiceInfo?.isPayment ?? false,
      isTaxReduction: fetchedInvoiceInfo?.isTaxReduction ?? false,
      description: fetchedInvoiceInfo?.description ?? '',
      paymentMethod: fetchedInvoiceInfo?.paymentMethod ?? 1,
      exchangedTotalUnitPriceAfterTax: 0,
      exchangedTotal: fetchedInvoiceInfo?.exchangedTotal ?? 0,
      exchangedTaxMoney: fetchedInvoiceInfo?.exchangedTaxMoney ?? 0,
      exchangedDiscountMoney: 0,
      multipleTaxRate:
        fetchedInvoiceInfo?.multipleTaxRate ??
        invoiceTemplate?.category?.taxType === 2,
      // invoiceTemplate?.category?.taxType > 1,
      companyUnitId: fetchedInvoiceInfo?.companyUnitId,
      extendFields: {},

      advanceAmount:
        fetchedInvoiceInfo?.advanceAmount ??
        ((invoiceTemplate ?? latestInvoiceTemplate)?.category?.code == 'BV'
          ? 0
          : ''),
      patientExemptedAmount:
        fetchedInvoiceInfo?.patientExemptedAmount ??
        ((invoiceTemplate ?? latestInvoiceTemplate)?.category?.code == 'BV'
          ? 0
          : ''),
      hospitalReturnAmount:
        fetchedInvoiceInfo?.hospitalReturnAmount ??
        ((invoiceTemplate ?? latestInvoiceTemplate)?.category?.code == 'BV'
          ? 0
          : ''),
      patientPaidAdditionalAmount:
        fetchedInvoiceInfo?.patientPaidAdditionalAmount ??
        ((invoiceTemplate ?? latestInvoiceTemplate)?.category?.code == 'BV'
          ? 0
          : ''),
      // tự động tính số liệu điều chỉnh
      autoCalculate:
        // Nếu hóa đơn đã có giá trị và sau đó chọn điều chỉnh thì là false
        fetchedInvoiceInfo?.autoCalculate && checkIsAdjustInvoice()
          ? false
          : // nkhi thêm mới thì mặc định là true
            (fetchedInvoiceInfo?.autoCalculate ?? true),
      // !checkIsAdjustInvoice() || fetchedInvoiceInfo?.autoCalculate,
      // thành tiền quy đổi
      exchangedTotalAfterTax: fetchedInvoiceInfo?.exchangedTotalAfterTax ?? 0,
      // Phiếu xuất kho
      buyerName: fetchedInvoiceInfo?.buyerName ?? '',
      invoiceNote: fetchedInvoiceInfo?.invoiceNote ?? '',
      deliveryBy: fetchedInvoiceInfo?.deliveryBy ?? '',
      deliveryOrderBy: fetchedInvoiceInfo?.deliveryOrderBy ?? '',
      buyerTaxCode: fetchedInvoiceInfo?.buyerTaxCode ?? '',
      containerNumber: fetchedInvoiceInfo?.containerNumber ?? '',
      transportationMethod: fetchedInvoiceInfo?.transportationMethod ?? '',
      stockOutFullName: fetchedInvoiceInfo?.stockOutFullName ?? '',
      stockInFullName: fetchedInvoiceInfo?.stockInFullName ?? '',
      fromWarehouseName: fetchedInvoiceInfo?.fromWarehouseName ?? '',
      toWarehouseName: fetchedInvoiceInfo?.toWarehouseName ?? '',
      deliveryOrderDate: fetchedInvoiceInfo?.deliveryOrderDate
        ? dayjs(fetchedInvoiceInfo?.deliveryOrderDate)
        : null,
      // nội bộ
      exporterName: fetchedInvoiceInfo?.exporterName ?? '',
      consigneeUnitName: fetchedInvoiceInfo?.consigneeUnitName ?? '',
      consigneeUnitTaxcode: fetchedInvoiceInfo?.consigneeUnitTaxcode ?? '',
      consigneeUnitAddress: fetchedInvoiceInfo?.consigneeUnitAddress ?? '',
      importerName: fetchedInvoiceInfo?.importerName ?? '',
      invoiceType,
      addressWarehouse:
        fetchedInvoiceInfo?.sellerFullAddress ?? company?.businessPermitAddress,
    },
    // validationSchema: Yup.object({
    //   buyerName: Yup.string().required('Nhập đi!'),
    // }),
    onSubmit: async (values) => {
      console.log('values', values)
      if (!values.invoiceTemplateId) {
        ToastHelper.showError('Chưa chọn ký hiệu hóa đơn')
        return
      }
      // Nếu là hóa đơn máy tính tiền
      if (isFromPOS)
        values = _.omit(values, ['customerAccountNumber', 'customerBankName'])
      //Check thông tin người mua
      if (
        values.customerCompanyName === '' &&
        values.customerName === '' &&
        !isDeliveryBill
      ) {
        ToastHelper.showError(
          'Vui lòng nhập tên người mua hàng hoặc tên đơn vị',
        )
        resetButtonSaveAndSign()
        return
      }
      // Có MST người mua
      if (values.customerTaxCode !== '') {
        if (!regexTaxCode(values.customerTaxCode)) {
          ToastHelper.showError('Mã số thuế không hợp lệ!')
          resetButtonSaveAndSign()
          return
        }
        if (values.customerCompanyName === '') {
          ToastHelper.showError('Vui lòng nhập tên đơn vị')
          resetButtonSaveAndSign()
          return
        } else if (values.customerFullAddress === '') {
          ToastHelper.showError('Vui lòng nhập địa chỉ khách hàng')
          resetButtonSaveAndSign()
          return
        }
      }
      // Bỏ phần kiểm tra tổng tiền (19/08/2024)
      // Có kiểm tra tổng tiền và tổng tiền bằng chữ
      // if (
      //   (!values.totalAfterTax || !values.totalAfterTaxVnese) &&
      //   !partsName.includes('dieu-chinh') &&
      //   !partsName.includes('dieu-chinh-khac-he-thong') &&
      //   values.type !== INVOICE_TYPE.ADJUST &&
      //   isProduct
      // ) {
      //   ToastHelper.showError(
      //     'Thiếu tổng tiền hoặc tổng tiền không được xác thực',
      //   )
      //   resetButtonSaveAndSign()
      //   return
      // }

      //huy
      if (tableData[0].name === '') {
        ToastHelper.showError('Vui lòng nhập thông tin Hàng hoá/ Dịch vụ')
        resetButtonSaveAndSign()
        if (submitAction == 'SaveNoNavigate') {
          setShowModalConfirmSaveToSendInvoice(false)
        }
        return
      }
      // item cuối không có tên nhưng có tiền
      if (
        tableData[tableData.length - 1].totalUnitPrice &&
        !tableData[tableData.length - 1].name
      ) {
        ToastHelper.showError('Vui lòng nhập thông tin Hàng hoá/ Dịch vụ')
        resetButtonSaveAndSign()
        if (submitAction == 'SaveNoNavigate') {
          setShowModalConfirmSaveToSendInvoice(false)
        }
        return
      }

      // Delivery bill validations
      if (isDeliveryBill) {
        if (!values.deliveryOrderDate) {
          values.deliveryOrderDate = dayjs()
        }
        const requiredFilelds = [
          {
            name: 'buyerName',
            label:
              values.invoiceType == 'N'
                ? 'lệnh điều đồng'
                : 'hợp đồng kinh tế số',
            ofType: ['N', 'B'],
          },
          {
            name: 'deliveryOrderDate',
            label: 'ngày',
            ofType: ['N', 'B'],
          },
          {
            name: 'addressWarehouse',
            label: 'Địa chỉ kho xuất hàng',
            ofType: ['N'],
          },
          {
            name: 'consigneeUnitAddress',
            label: 'Địa điểm nhận hàng',
            ofType: ['N'],
          },
          {
            name: 'deliveryBy',
            label: 'tên người vận chuyển',
            ofType: ['B'],
          },
          {
            name: 'transportationMethod',
            label: 'phương tiện vận chuyển',
            ofType: ['N', 'B'],
          },
          {
            name: 'toWarehouseName',
            label: 'trường thông tin Nhập tại kho',
            ofType: ['B'],
          },
          {
            name: 'fromWarehouseName',
            label: 'trường thông tin Xuất tại kho',
            ofType: ['B'],
          },
        ]
        let errFields = []
        requiredFilelds.map((field) => {
          if (
            !values[field.name] &&
            field.ofType.includes(formik.values.invoiceType)
          ) {
            ToastHelper.showError(`Vui lòng nhập ${field.label}`)
            resetButtonSaveAndSign()
            errFields.push(field.name)
          }
        })
        if (errFields.length > 0) return
        if (values.consigneeUnitTaxcode || values.buyerTaxCode) {
          if (
            !regexTaxCode(values.consigneeUnitTaxcode || values.buyerTaxCode)
          ) {
            ToastHelper.showError('Mã số thuế không hợp lệ!')
            resetButtonSaveAndSign()
            return
          }
        }
      }
      let _buyerInfoExtendFields = buyerInfoExtendFields
        .filter(({ forceShow, show }) => forceShow || show)
        .map((item) => ({
          ...item,
          value: values.extendFields[item.name],
        }))

      let _tableDetailExtendFields = tableDetailExtendFields.filter(
        ({ forceShow, show }) => forceShow || show,
      )
      let _tableData = tableData.map((item) => {
        // Nếu là ghi chú diễn giải thì gán lại thực nhập thực xuất
        if (item.actualExport === '') item.actualExport = null
        if (item.actualImport === '') item.actualImport = null
        let extend_fields = _tableDetailExtendFields.map(
          ({ fieldId, name }) => ({
            fieldId,
            name,
            invoice_product_extend_field: {
              value: item[name]?.value ?? item[name]?.defaultValue,
            },
          }),
        )
        return { ...item, extend_fields }
      })
      let tempCurrentIndex = 0
      _tableData = _tableData.map((item) => ({
        ...item,
        stt:
          item.type !== 4 || _.isEmpty(item.name) ? ++tempCurrentIndex : null,
        discountMoney:
          formik.values.discountType === 0 ? null : item.discountMoney,
        discount: formik.values.discountType === 0 ? null : item.discount,
      }))
      //end huy
      values.discount = Utils.parseFormattedNumberToFloat(values.discount)
      if (
        partsName.includes('dieu-chinh') ||
        partsName.includes('dieu-chinh-khac-he-thong') ||
        values.type === INVOICE_TYPE.ADJUST ||
        !values.autoCalculate
      ) {
        values.total = Utils.parseFormattedNumberToFloat(values.total)
        values.totalAfterTax = Utils.parseFormattedNumberToFloat(
          values.totalAfterTax,
        )
        values.taxMoney = Utils.parseFormattedNumberToFloat(values.taxMoney)
      }
      if (typeof values.exchangeRate === 'string') {
        values.exchangeRate = Utils.parseFormattedNumberToFloat(
          values.exchangeRate,
        )
      }

      switch (actionType) {
        case ACTION_TYPE.CREATE: {
          break
        }
        case ACTION_TYPE.REPLACE:
        case ACTION_TYPE.ADJUST: {
          values.type = actionType == ACTION_TYPE.ADJUST ? 2 : 1

          let externalInvoiceId
          if (
            partsName.includes('dieu-chinh-khac-he-thong') ||
            partsName.includes('thay-the-khac-he-thong')
          ) {
            try {
              let resCheck = await invoiceApi.checkExternal(fetchedInvoiceInfo)
              if (resCheck.data == true) {
                const res = await invoiceApi.bulkCreateExternal({
                  invoices: [fetchedInvoiceInfo],
                })

                externalInvoiceId = res?.data?.createInvoiceIds?.at(0)
              } else {
                externalInvoiceId = resCheck.data
              }
            } catch (err) {
              console.log(err)
              return
            } finally {
              resetButtonSaveAndSign()
            }
          }

          values.originalInvoiceId = externalInvoiceId ?? invoiceId
          values.Original =
            partsName.includes('dieu-chinh-khac-he-thong') ||
            partsName.includes('thay-the-khac-he-thong')
              ? fetchedInvoiceInfo
              : invoiceData

          break
        }
        case ACTION_TYPE.UPDATE: {
          updateInvoice(
            values,
            submitAction,
            _tableData,
            company,
            dispatch,
            setAppSpinning,
            invoiceId,
            _buyerInfoExtendFields,
            navigate,
            isFromPOS,
            isDeliveryBill,
            (invoiceId) => {
              setIssueInvoiceFromPosModalInfo({ show: true, invoiceId })
            },
            (invoiceId) => {
              setSendDraftInvoiceModalShow(true)
            },
            resetButtonSaveAndSign(),
          )
          return
        }
        default:
          return
      }

      let signingConfig = {
        signingMethod: company?.activeSigningMethod,
      }
      let invoice = await createNewInvoice(
        values,
        submitAction,
        _tableData,
        company,
        dispatch,
        setAppSpinning,
        navigate,
        _buyerInfoExtendFields,
        signingConfig,
        () => {
          setShowModalConfirmSaveToSendInvoice(false)
          setSendDraftInvoiceModalShow(true)
        },
        () => {
          setShowModalConfirmSaveToSendInvoice(false)
        },
        isFromPOS,
        isDeliveryBill,
        (invoiceId) =>
          setIssueInvoiceFromPosModalInfo({ show: true, invoiceId }),
        resetButtonSaveAndSign,
      )

      if (invoice?.invoiceId) {
        setCreatedInvoiceId(invoice.invoiceId)
      }
      return
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
  })

  useEffect(() => {
    if (
      isAdjustInvoice
        ? formik.values.autoCalculate && formik.values.taxRate === 'KHAC'
        : formik.values.taxRate === 'KHAC' || !formik.values.autoCalculate
          ? true
          : false
    ) {
      let taxMoney = Utils.parseFormattedNumberToFloat(formik.values.taxMoney)
      let total = Utils.parseFormattedNumberToFloat(formik.values.total)
      let totalAfterTax = total + taxMoney
      formik.setFieldValue('taxMoney', taxMoney)
      formik.setFieldValue('totalAfterTax', totalAfterTax)
    } else {
      let taxMoney = Utils.parseFormattedNumberToFloat(formik.values.taxMoney)
      formik.setFieldValue('taxMoney', taxMoney)
    }
  }, [formik.values.taxMoney])

  useEffect(() => {
    if (!formik.values.autoCalculate) {
      let taxMoney = Utils.parseFormattedNumberToFloat(formik.values.taxMoney)
      let total = Utils.parseFormattedNumberToFloat(formik.values.total)
      let totalAfterTax = total + taxMoney
      formik.setFieldValue('total', total)
      formik.setFieldValue('totalAfterTax', totalAfterTax)
    }
  }, [formik.values.total])

  let isAdjustInvoice = false
  isAdjustInvoice = useMemo(() => {
    if (
      partsName.includes('dieu-chinh') ||
      partsName.includes('dieu-chinh-khac-he-thong') ||
      (partsName.includes('cap-nhat') &&
        formik.values.type === INVOICE_TYPE.ADJUST)
    ) {
      return true
    } else {
      return false
    }
  }, [formik.values.type, partsName])

  // Tính toán và set lại giá trị cho tổng tiền và tổng tiền thanh toán
  useEffect(() => {
    let totalAfterTax = formik.values.totalAfterTax || 0
    if (typeof totalAfterTax === 'string' || !formik.values.autoCalculate) {
      totalAfterTax = Utils.parseFormattedNumberToFloat(totalAfterTax) || 0
    }
    let prefix, endSymbol, afterdecimal, unit, separation
    if (formik.values.currency === 'VNĐ') {
      prefix = ''
      endSymbol = ''
      unit = currencies.find(
        (option) => option.value === formik.values.currency,
      )?.name
      afterdecimal = ''
      separation = 'phẩy'
    } else {
      unit = currencies.find(
        (option) => option.code === formik.values.currency,
      )?.VNUnit
      prefix = currencies.find(
        (option) => option.code === formik.values.currency,
      )?.VNPrefix
      endSymbol = currencies.find(
        (option) => option.code === formik.values.currency,
      )?.VNEndSymbol
      afterdecimal = currencies.find(
        (option) => option.code === formik.values.currency,
      )?.VNAfterDecimal
      separation = currencies.find(
        (option) => option.code === formik.values.currency,
      )?.VNSeperation
    }
    let totalAfterTaxVnese = Utils.numberToWords(
      Utils.formatNumberWithSeparators(totalAfterTax), //num
      'vi', // unit
      prefix ?? '', // prefix
      unit, //unit
      separation, // separation
      endSymbol, // endSymbol
      afterdecimal, // afterdecimal
      currency?.zeroInten,
      currency?.zeroInThousand,
      currency?.evenDisplay,
    )
    if (isAdjustInvoice) {
      totalAfterTaxVnese = Utils.changeVneseAdjustInvoice(
        totalAfterTaxVnese,
        totalAfterTax,
        currency?.readMoneyInvoice,
      )
    }
    formik.setFieldValue('totalAfterTax', totalAfterTax)
    formik.setFieldValue('totalAfterTaxVnese', totalAfterTaxVnese)
  }, [formik.values.totalAfterTax, isAdjustInvoice, formik.values.currency])

  // gán lại giá trị cho tổng tiền quy đổi và tổng tiền thanh toán quy đổi
  useEffect(() => {
    const fields = [
      'exchangedTotal',
      'exchangedTotalAfterTax',
      'exchangedTaxMoney',
    ]
    fields.forEach((field) => {
      if (
        typeof formik.values[field] === 'string' ||
        !formik.values.autoCalculate
      ) {
        formik.setFieldValue(
          field,
          Utils.parseFormattedNumberToFloat(formik.values[field]),
        )
      }
    })
    // eslint-disable-next-line
  }, [
    formik.values.autoCalculate,
    formik.values.exchangedTotal,
    formik.values.exchangedTotalAfterTax,
    formik.values.exchangedTaxMoney,
  ])
  const fetchTemplateSerial = async () => {
    let res
    try {
      let params = undefined
      if (isFromPOS) params = { invoiceClassify: 'MTT' }
      else if (isDeliveryBill) params = { invoiceClassify: 'PXK' }

      res = await templateInvoiceApi.getTemplateSerials(params)
    } catch (e) {
      return
    }
    if (res?.data?.length) {
      let serials = res?.data
      serials.map((item) => {
        item.label = item.serial
        item.value = item.invoiceTemplateId
      })
      setTemplateInvoiceSerials(serials)
    } else {
      // ToastHelper.showError('Không lấy được thông tin ký hiệu hoá đơn');
      ToastHelper.showError(
        'Bạn chưa thực hiện đăng ký mẫu hóa đơn hoặc không được phân quyền sử dụng mẫu hóa đơn nào. Hãy thực hiện đăng ký trước khi lập hóa đơn',
      )
    }
  }

  useEffect(() => {
    let invoiceTemplateId = formik.values.invoiceTemplateId
    if (invoiceTemplateId && invoiceExtendFields) {
      setBuyerInfoExtendFields((old) =>
        old.map(({ invoice_templates, ...rest }) => ({
          ...rest,
          invoice_templates,
          show: !!invoice_templates?.find(
            (item) => item.invoiceTemplateId == invoiceTemplateId,
          )?.invoice_template_extend_field?.active,
          forceShow: !!invoice_templates?.find(
            (item) => item.invoiceTemplateId == invoiceTemplateId,
          )?.invoice_template_extend_field?.forceActive,
          value: invoiceExtendFields?.find(
            ({ fieldId }) => fieldId == rest.fieldId,
          )?.invoice_extend_field?.value,
        })),
      )
      setTableDetailExtendFields((old) =>
        old.map(({ invoice_templates, ...rest }) => ({
          ...rest,
          invoice_templates,
          show: !!invoice_templates?.find(
            (item) => item.invoiceTemplateId == invoiceTemplateId,
          )?.invoice_template_extend_field?.active,
          forceShow: !!invoice_templates?.find(
            (item) => item.invoiceTemplateId == invoiceTemplateId,
          )?.invoice_template_extend_field?.forceActive,
        })),
      )
      // lấy hoá đơn phát hành mới nhất của mẫu
    }
    invoiceTemplateId && fetchLatestInvoiceTemplate(invoiceTemplateId)
  }, [formik.values.invoiceTemplateId, invoiceExtendFields])

  useEffect(() => {
    if (buyerInfoExtendFields?.length) {
      let addonsFields = buyerInfoExtendFields
        .filter(({ forceShow, show }) => forceShow || show)
        .reduce(
          (result, field) => ({
            ...result,
            [field.name]: field.value ?? field.defaultValue ?? undefined,
          }),
          {},
        )
      for (let [key, value] of Object.entries(addonsFields)) {
        let originValue =
          formik.values &&
          formik.values.extendFields &&
          formik.values.extendFields[key]
        if (originValue == undefined) {
          formik.setFieldValue(`extendFields.${key}`, value)
        }
      }
    }
  }, [buyerInfoExtendFields])

  useEffect(() => {
    if (tableDetailExtendFields?.length) {
      let addonsFields = tableDetailExtendFields
        .filter(({ forceShow, show }) => forceShow || show)
        .reduce(
          (result, field) => ({
            ...result,
            [field.name]: field.defaultValue || undefined,
          }),
          {},
        )
      setTableData((oldTb) =>
        oldTb.map((row) => {
          let returnValue = { ...row }
          for (let _key in addonsFields) {
            returnValue[_key] = returnValue[_key] ?? {}
            returnValue[_key] = {
              ...returnValue[_key],
              defaultValue: addonsFields[_key],
            }
          }

          return returnValue
        }),
      )
    }
  }, [tableDetailExtendFields])

  // useEffect(() => {
  //   const hasCategory = !!invoiceTemplate?.category
  //   if (hasCategory) {
  //     // chỉ 1 thuế suất
  //     formik.setFieldValue(
  //       'multipleTaxRate',
  //       invoiceTemplate?.category?.taxType > 1,
  //     )
  //     // formik.setFieldValue('taxType', invoiceTemplate?.category?.taxType)
  //   }
  //   // formik.setFieldValue('isTaxReduction', false)
  //   // formik.setFieldValue('taxRate', systemTaxRate)
  // }, [invoiceTemplate])

  useEffect(() => {
    if (formik.values.multipleTaxRate) {
      // formik.setFieldValue('taxRate', '0')
      setDiscountTypeArray(SelectOptions.discountTypeMultipleTaxRate)
      if (formik.values.discountType === 2) {
        // neu laf chiet khau theo tong gia tri rs ve 0
        formik.setFieldValue('discountType', 0)
        let newTableData = [...tableData]
        newTableData.forEach((item) => {
          item.discount = 0
          item.discountMoney = 0
        })
      }
    } else {
      setDiscountTypeArray(SelectOptions.discountType)
    }
  }, [formik.values.multipleTaxRate])

  useEffect(() => {
    if (formik.values.discountType !== 2) {
      formik.values.discount = null
    }
    if (!formik.values.autoCalculate || formik.values.type === undefined) {
      return
    } else {
      calculateAndSetFinalMoney(tableData)
    }
  }, [
    formik.values.taxType,
    formik.values.taxRate,
    tableData,
    formik.values.discountType,
    isChangeTaxRate,
    // formik.values.exchangeRate,
  ])

  useEffect(() => {
    const tempArr = moneyTypes.map((item) => {
      return { ...item, value: item?.code, label: item?.code }
    })
    // lay dnah sach tempArr khong chua currencies
    let newCurrencies = tempArr.filter(
      (item) =>
        !currencies.map((_item) => _item.code).includes(item.code) &&
        item.status === 'ACTIVE',
    )
    // tempArr[0] &&
    //   currencies.filter((item) => item.code === tempArr[0].code).length === 0 &&
    setCurrencies([...currencies, ...newCurrencies])
  }, [moneyTypes])

  useEffect(() => {
    let newTableData = [...tableData]
    const discount = Utils.parseFormattedNumberToFloat(formik.values.discount)
    const taxRate = Utils.parseFormattedNumberToFloat(formik.values.taxRate)
    if (formik.values.discountType === 2) {
      newTableData.forEach((item) => {
        item.discount = discount
        item.discountMoney = (item.totalUnitPrice * discount) / 100
        item.taxMoney =
          ((item.totalUnitPrice - item.discountMoney) * taxRate) / 100
      })
    }

    setTableData(newTableData)
  }, [formik.values.discount])

  useEffect(() => {
    let newTableData = [...tableData]

    if (!formik.values.isTaxReduction) {
      newTableData.forEach((item) => {
        item.taxReductionRate = 0
        item.taxReduction = Utils.roundNumber(0, formatNumber?.foreignCurrency)
        item.taxReductionExchange = Utils.roundNumber(
          0,
          formatNumber?.foreignCurrency,
        )
      })
    }
    if (formik.values.isTaxReduction) {
      newTableData.forEach((item) => {
        let taxReductionRate = item.taxReductionRate ? item.taxReductionRate : 1
        item.taxReductionRate = taxReductionRate
        let taxReduction =
          ((item.totalUnitPrice - item.discountMoney) * taxReductionRate) /
            100 /
            5 ?? 0
        item.taxReduction = Utils.roundNumber(
          taxReduction,
          formatNumber?.foreignCurrency,
        )
        item.taxReductionExchange = Utils.roundNumber(
          taxReduction * formik.values.exchangeRate,
          formatNumber?.foreignCurrency,
        )
      })
    }

    setTableData(newTableData)
  }, [formik.values.isTaxReduction])

  useEffect(() => {
    calculateAndSetExchangedMoneyPerItem()
  }, [formik.values.exchangeRate])

  const calculateAndSetExchangedMoneyPerItem = () => {
    let tempTableData = [...tableData]
    let exchangeRate = Utils.parseFormattedNumberToFloat(
      formik.values.exchangeRate,
    )
    // let exchangeRate = parseInt(formik.values.exchangeRate)
    //Tính tiền quy đổi khi thay đổi đơn vị tiền tệ
    let newTableData = calculateExchangedMoneyTableData(
      tempTableData,
      exchangeRate,
    )

    setTableData(newTableData)
  }

  const displayDate = (date) => {
    const dateObject = new Date(date)

    const month = dateObject.getMonth()
    return (
      <span className="invoice-date">
        {/* {formik.values.date} */}
        Ngày {dateObject.getDate()} tháng {dateObject.getMonth() + 1} năm{' '}
        {dateObject.getFullYear()}
      </span>
    )
  }
  const calculateAndSetFinalMoney = (newTableData) => {
    let { discount, discountType, exchangeRate } = formik.values
    let total = 0
    let taxMoney = 0
    let totalAfterTax = 0
    let discountMoney = 0
    let totalTaxReduction = 0
    newTableData.forEach((item) => {
      if (item?.taxMoney) {
        switch (item?.type) {
          case 1: // type là hàng hoá/ dịch vụ + bình thường
            taxMoney += item?.taxMoney
            break
          case 2: // type là hàng khuyến mại không + tổng tiền
            // taxMoney += item?.taxMoney;
            break
          case 3: // type là chiếu khấu thương mại - bớt đi giá trị
            taxMoney -= item?.taxMoney
            break
          default:
            break
        }
      }
      if (item?.totalUnitPrice) {
        switch (item?.type) {
          case 1: // type là hàng hoá/ dịch vụ + bình thường
            total += item?.totalUnitPrice
            break
          case 2: // type là hàng khuyến mại không + tổng tiền
            break
          case 3: // type là chiếu khấu thương mại - bớt đi giá trị
            total = total - item?.totalUnitPrice
            break
          default:
            break
        }
      }
      if (item?.taxReduction) {
        switch (item?.type) {
          case 1: // type là hàng hoá/ dịch vụ + bình thường
            totalTaxReduction += item?.taxReduction
            break
          case 2: // type là hàng khuyến mại không + tổng tiền
            break
          case 3: // type là chiếu khấu thương mại - bớt đi giá trị
            totalTaxReduction = totalTaxReduction - item?.taxReduction
            break
          default:
            break
        }
      }
      if (item?.discountMoney) {
        switch (item?.type) {
          case 1: // type là hàng hoá/ dịch vụ + bình thường
            discountMoney += item?.discountMoney
            break
          case 2: // type là hàng khuyến mại không + tổng tiền
            // taxMoney += item?.taxMoney;
            break
          case 3: // type là chiếu khấu thương mại - bớt đi giá trị
            // discountMoney -= item?.discountMoney
            break
          default:
            break
        }
      }
    })
    if (
      formik.values.taxRate === 'KHAC' ||
      (actionType === ACTION_TYPE.UPDATE && !isChangeTaxRate && !isChangeTable)
    ) {
      taxMoney = Utils.parseFormattedNumberToFloat(formik.values.taxMoney)
    }

    discountMoney = Utils.roundNumber(
      discountMoney,
      formatNumber?.currencyExchange,
    )
    total = Utils.roundNumber(total, roundingConfig('totalCost'))
    taxMoney = Utils.roundNumber(taxMoney, roundingConfig('totalCost'))
    totalAfterTax = Utils.roundNumber(
      total - discountMoney + taxMoney - totalTaxReduction,
      roundingConfig('totalCost'),
    )
    calculateAndSetFinalExchangedMoney(
      exchangeRate,
      discountMoney,
      total,
      taxMoney,
      totalAfterTax,
      totalTaxReduction,
    )
    formik.setFieldValue('discountMoney', discountMoney)
    formik.setFieldValue('total', total)
    // formik.setFieldValue('exchangedTotal', total * exchangeRate)
    formik.setFieldValue('taxMoney', taxMoney)
    formik.setFieldValue('totalAfterTax', totalAfterTax)
    // formik.setFieldValue('exchangedTotalAfterTax', totalAfterTax * exchangeRate)
    formik.setFieldValue('taxReduction', totalTaxReduction)
    // formik.setFieldValue(
    //   'exchangedTotalTaxReduction',
    //   totalTaxReduction * exchangeRate,
    // )
    setIsChangeTable(false)
  }

  const calculateAndSetFinalExchangedMoney = (
    exchangeRate,
    discountMoney,
    total,
    taxMoney,
    totalAfterTax,
    totalTaxReduction,
  ) => {
    if (typeof exchangeRate === 'string') {
      exchangeRate = Utils.parseFormattedNumberToFloat(exchangeRate)
    }
    formik.setFieldValue('exchangedDiscountMoney', discountMoney * exchangeRate)
    formik.setFieldValue('exchangedTotalAfterTax', totalAfterTax * exchangeRate)
    formik.setFieldValue('exchangedTotal', total * exchangeRate)
    formik.setFieldValue('exchangedTaxMoney', taxMoney * exchangeRate)
    formik.setFieldValue(
      'exchangedTotalTaxReduction',
      totalTaxReduction * exchangeRate,
    )
  }

  const handleRemoveRow = (row) => {
    setIsChangeTable(true)
    let newTableData = tableData.filter((item) => item.no !== row.no)

    for (let i = 0; i < newTableData.length; i++) {
      newTableData[i].no = i + 1
    }
    setTableData(newTableData)
  }
  // eslint-disable-next-line
  const roundingConfig = useCallback((defaultConfig) => {
    if (!formatNumber) return null
    return ['VNĐ', 'VND'].includes(formik.values.currency)
      ? formatNumber[defaultConfig]
      : formatNumber.exchangeTotalCost
  })
  const handleChangeTableData = (e, row, field, type) => {
    setIsChangeTable(true)
    let newTableData = [...tableData]
    let newTableDataLast = newTableData[row.no - 1]
    let { exchangeRate } = formik.values
    if (typeof exchangeRate === 'string') {
      exchangeRate = Utils.parseFormattedNumberToFloat(exchangeRate)
    }
    let taxRate = newTableDataLast.taxRate
    if (
      (taxRate == '0' || taxRate == '5' || taxRate == '8' || taxRate == '10') &&
      (formik.values.taxType === 1 ||
        formik.values.taxType === 2 ||
        (formik.values.taxType === 3 && !formik.values.multipleTaxRate) ||
        formik.values.multipleTaxRate) &&
      parseInt(formik.values?.serial?.charAt(0)) === 1
    ) {
      taxRate = parseInt(taxRate)
    } else if (taxRate !== 'KHAC') {
      taxRate = 0
    }
    var value = e?.floatValue ?? e?.value ?? e?.target?.value
    if (isAdjustInvoice && !formik.values.autoCalculate) {
      newTableDataLast[field] = value
      setTableData(newTableData)
      // return
    }
    switch (type) {
      case 'number':
        // if (e?.floatValue) {
        //   newTableDataLast[field] = e?.floatValue
        // }
        if (e?.target?.value) {
          newTableDataLast[field] = Utils.parseFormattedNumberToFloat(
            e?.target?.value,
          )
        } else {
          if (!formik.values.autoCalculate) {
            newTableDataLast[field] = null
          } else {
            newTableDataLast[field] = 0
          }
        }

        switch (field) {
          case 'discountMoney':
            if (formik.values.discountType != 0) {
              newTableDataLast[field] = e?.target?.value
                ? Utils.parseFormattedNumberToFloat(e.target.value)
                : null
            }
            if (
              !Utils.isObjectEmpty(newTableDataLast?.totalUnitPrice) &&
              newTableDataLast?.totalUnitPrice !== 0
            ) {
              newTableDataLast.discount =
                (newTableDataLast?.discountMoney /
                  newTableDataLast?.totalUnitPrice) *
                100
              if (_.isNumber(taxRate))
                newTableDataLast.taxMoney =
                  ((newTableDataLast.totalUnitPrice -
                    newTableDataLast.discountMoney) *
                    taxRate) /
                  100
              newTableDataLast.totalUnitPriceAfterTax =
                newTableDataLast.totalUnitPrice +
                newTableDataLast.taxMoney -
                newTableDataLast.discountMoney
            }
            break
          case 'discount':
            if (
              !Utils.isObjectEmpty(newTableDataLast?.totalUnitPrice) &&
              newTableDataLast?.totalUnitPrice !== 0
            ) {
              newTableDataLast.discountMoney =
                (newTableDataLast?.discount *
                  newTableDataLast?.totalUnitPrice) /
                100
              if (_.isNumber(taxRate))
                newTableDataLast.taxMoney =
                  ((newTableDataLast.totalUnitPrice -
                    newTableDataLast.discountMoney) *
                    taxRate) /
                  100
              newTableDataLast.totalUnitPriceAfterTax =
                newTableDataLast.totalUnitPrice +
                newTableDataLast.taxMoney -
                newTableDataLast.discountMoney
            }
            break

          case 'totalUnitPrice':
            // chia ngược khi có tổng tiền
            if (formik.values.autoCalculate) {
              if (
                newTableDataLast.quantity &&
                newTableDataLast.quantity !== 0 &&
                persistent === 'quantityPersistent'
              ) {
                /** don gia */
                newTableDataLast.unitPrice =
                  newTableDataLast.totalUnitPrice / newTableDataLast.quantity
                /* don gia sau thue */
                if (_.isNumber(taxRate))
                  newTableDataLast.unitPriceAfterTax =
                    (newTableDataLast.unitPrice *
                      (1 + taxRate - newTableDataLast.discount)) /
                    10
                if (!_.isNumber(taxRate))
                  newTableDataLast.unitPriceAfterTax =
                    newTableDataLast.unitPrice *
                    (100 - newTableDataLast.discount) *
                    10
              }
              if (
                // Nếu là phiếu xuất kho và có phiếu xuất kho
                newTableDataLast.actualExport &&
                newTableDataLast.actualExport !== 0 &&
                persistent === 'quantityPersistent' &&
                isDeliveryBill
              ) {
                newTableDataLast.unitPrice =
                  newTableDataLast.totalUnitPrice /
                  newTableDataLast.actualExport
              } else if (
                // nếu là phiếu xuất kho nội bộ và thực xuất bằng 0
                !newTableDataLast.actualExport &&
                isDeliveryBill &&
                invoiceType == 'N'
              ) {
                newTableDataLast.unitPrice = 0
              }
              if (
                newTableDataLast.unitPrice &&
                persistent === 'priceAfterTaxPersistent'
              ) {
                /** don gia */
                newTableDataLast.quantity = newTableDataLast.actualExport =
                  newTableDataLast.totalUnitPrice / newTableDataLast.unitPrice
              }
              /* tien ck */
              newTableDataLast.discountMoney =
                (newTableDataLast.totalUnitPrice * newTableDataLast.discount) /
                100
              /*  tien vat */
              if (_.isNumber(taxRate))
                newTableDataLast.taxMoney =
                  ((newTableDataLast.totalUnitPrice -
                    newTableDataLast.discountMoney) *
                    taxRate) /
                  100
              /* tien sau thue */
              newTableDataLast.totalUnitPriceAfterTax =
                newTableDataLast.totalUnitPrice +
                newTableDataLast.taxMoney -
                newTableDataLast.discountMoney
              /* Nếu số lượng, đơn giá là 0 thì để là null */
              if (newTableDataLast.quantity === 0)
                newTableDataLast.quantity = null
              if (newTableDataLast.actualExport === 0)
                newTableDataLast.actualExport = null
              if (newTableDataLast.actualImport === 0)
                newTableDataLast.actualImport = null
              if (
                newTableDataLast.unitPrice === 0 &&
                invoiceType !== 'N' &&
                !newTableDataLast.actualExport
              )
                newTableDataLast.unitPrice = null
              if (newTableDataLast.unitPriceAfterTax === 0)
                newTableDataLast.unitPriceAfterTax = null
              if (newTableDataLast.discountMoney === 0)
                newTableDataLast.discountMoney = null
              if (newTableDataLast.discount === 0)
                newTableDataLast.discount = null
            }
            break
          case 'unitPrice':
            console.log('unitPrice')
            if (formik.values.autoCalculate) {
              if (
                newTableDataLast.totalUnitPrice &&
                Utils.isObjectEmpty(
                  isDeliveryBill
                    ? newTableDataLast?.actualExport
                    : newTableDataLast?.quantity,
                )
              ) {
                newTableDataLast.quantity = newTableDataLast.actualExport =
                  newTableDataLast.totalUnitPrice / newTableDataLast.unitPrice
              } else if (
                !Utils.parseFormattedNumberToFloat(
                  isDeliveryBill
                    ? newTableDataLast?.actualExport
                    : newTableDataLast?.quantity,
                )
              ) {
                newTableDataLast.quantity = newTableDataLast.actualExport = 1
              }
              // chia ngược khi có tổng tiền

              // don gia
              newTableDataLast.totalUnitPrice =
                newTableDataLast.unitPrice *
                (isDeliveryBill
                  ? newTableDataLast?.actualExport
                  : newTableDataLast?.quantity)
              // don gia sau thue
              if (_.isNumber(taxRate))
                newTableDataLast.unitPriceAfterTax =
                  (newTableDataLast.unitPrice *
                    (100 + taxRate - newTableDataLast.discount)) /
                  100
              if (!_.isNumber(taxRate))
                newTableDataLast.unitPriceAfterTax =
                  (newTableDataLast.unitPrice /
                    (100 - newTableDataLast.discount)) *
                  100
              // tien ck
              newTableDataLast.discountMoney =
                (newTableDataLast.totalUnitPrice * newTableDataLast.discount) /
                100
              // tien vat
              if (_.isNumber(taxRate))
                newTableDataLast.taxMoney =
                  ((newTableDataLast.totalUnitPrice -
                    newTableDataLast.discountMoney) *
                    taxRate) /
                  100
              // tien sau thue
              newTableDataLast.totalUnitPriceAfterTax =
                newTableDataLast.totalUnitPrice +
                newTableDataLast.taxMoney -
                newTableDataLast.discountMoney
            }
            break
          case 'totalUnitPriceAfterTax':
            // thanh tien = ttst/(1+%Vat)/(1-%ck)
            if (formik.values.autoCalculate) {
              if (_.isNumber(taxRate))
                newTableDataLast.totalUnitPrice =
                  (newTableDataLast.totalUnitPriceAfterTax /
                    (100 + taxRate - newTableDataLast.discount)) *
                  100
              if (!_.isNumber(taxRate))
                newTableDataLast.totalUnitPrice =
                  (newTableDataLast.totalUnitPriceAfterTax -
                    newTableDataLast.taxMoney) /
                  (1 - newTableDataLast.discount / 100)
              if (
                newTableDataLast.quantity &&
                newTableDataLast.quantity !== 0 &&
                persistent === 'quantityPersistent'
              ) {
                // don gia
                newTableDataLast.unitPrice =
                  newTableDataLast.totalUnitPrice / newTableDataLast.quantity
                // don gia sau thue
                if (_.isNumber(taxRate))
                  newTableDataLast.unitPriceAfterTax =
                    newTableDataLast.unitPrice * (1 + taxRate / 100)
                if (!_.isNumber(taxRate))
                  newTableDataLast.unitPriceAfterTax =
                    newTableDataLast.unitPrice
              }
              if (
                newTableDataLast.unitPrice &&
                persistent === 'priceAfterTaxPersistent'
              ) {
                newTableDataLast.quantity =
                  newTableDataLast.totalUnitPrice / newTableDataLast.unitPrice
              }

              // tien ck
              newTableDataLast.discountMoney =
                (newTableDataLast.totalUnitPrice * newTableDataLast.discount) /
                100
              // tien vat
              if (_.isNumber(taxRate))
                newTableDataLast.taxMoney =
                  ((newTableDataLast.totalUnitPrice -
                    newTableDataLast.discountMoney) *
                    taxRate) /
                  100
              /* Nếu số lượng, đơn giá là 0 thì để là null */
              if (newTableDataLast.quantity === 0)
                newTableDataLast.quantity = null
              if (newTableDataLast.unitPrice === 0)
                newTableDataLast.unitPrice = null
              if (newTableDataLast.unitPriceAfterTax === 0)
                newTableDataLast.unitPriceAfterTax = null
              if (newTableDataLast.discountMoney === 0)
                newTableDataLast.discountMoney = null
              if (newTableDataLast.discount === 0)
                newTableDataLast.discount = null
            }
            break
          case 'unitPriceAfterTax': {
            if (formik.values.autoCalculate) {
              if (
                newTableDataLast.totalUnitPriceAfterTax &&
                Utils.isObjectEmpty(newTableDataLast.quantity)
              ) {
                newTableDataLast.quantity =
                  newTableDataLast.totalUnitPriceAfterTax /
                  newTableDataLast.unitPriceAfterTax
              } else if (
                !Utils.parseFormattedNumberToFloat(newTableDataLast?.quantity)
              ) {
                newTableDataLast.quantity = 1
              }
              // don gia
              if (_.isNumber(taxRate))
                newTableDataLast.unitPrice =
                  (newTableDataLast.unitPriceAfterTax /
                    (100 + taxRate - newTableDataLast.discount)) *
                  100
              if (!_.isNumber(taxRate))
                newTableDataLast.unitPrice = newTableDataLast.unitPriceAfterTax
              // thanh tien
              newTableDataLast.totalUnitPrice =
                newTableDataLast.unitPrice * newTableDataLast.quantity
              // tien ck
              newTableDataLast.discountMoney =
                (newTableDataLast.totalUnitPrice * newTableDataLast.discount) /
                100
              // tien vat
              if (_.isNumber(taxRate))
                newTableDataLast.taxMoney =
                  ((newTableDataLast.totalUnitPrice -
                    newTableDataLast.discountMoney) *
                    taxRate) /
                  100
              // tien sau thue
              newTableDataLast.totalUnitPriceAfterTax =
                newTableDataLast.totalUnitPrice +
                newTableDataLast.taxMoney -
                newTableDataLast.discountMoney
            }
            break
          }
          case 'taxMoney':
            break

          default: // tính theo chiều thuận
            if (formik.values.autoCalculate) {
              if (
                !Utils.isObjectEmpty(
                  isDeliveryBill && formik.values.invoiceType === 'N'
                    ? newTableDataLast?.actualExport
                    : newTableDataLast?.quantity,
                ) &&
                !Utils.isObjectEmpty(newTableDataLast?.unitPrice)
              ) {
                if (invoiceSetting?.priceAfterTax) {
                  newTableDataLast.unitPrice =
                    (newTableDataLast?.unitPriceAfterTax /
                      (100 + taxRate - newTableDataLast.discount)) *
                    100
                }
                // thanh tien
                newTableDataLast.totalUnitPrice =
                  (isDeliveryBill && formik.values.invoiceType === 'N'
                    ? newTableDataLast?.actualExport
                    : newTableDataLast?.quantity) * newTableDataLast?.unitPrice
                // tien ck
                newTableDataLast.discountMoney =
                  (newTableDataLast.totalUnitPrice *
                    newTableDataLast.discount) /
                  100
                // tien vat
                if (_.isNumber(taxRate))
                  newTableDataLast.taxMoney =
                    ((newTableDataLast.totalUnitPrice -
                      newTableDataLast.discountMoney) *
                      taxRate) /
                    100
                // tien sau thue
                newTableDataLast.totalUnitPriceAfterTax =
                  newTableDataLast.totalUnitPrice +
                  newTableDataLast.taxMoney -
                  newTableDataLast.discountMoney
              } else if (
                !Utils.isObjectEmpty(
                  isDeliveryBill && formik.values.invoiceType === 'N'
                    ? newTableDataLast?.actualExport
                    : newTableDataLast?.quantity,
                ) &&
                !Utils.isObjectEmpty(newTableDataLast?.totalUnitPrice)
              ) {
                // don gia
                newTableDataLast.unitPrice =
                  newTableDataLast.totalUnitPrice / newTableDataLast.quantity
                if (invoiceSetting?.priceAfterTax) {
                  newTableDataLast.unitPriceAfterTax =
                    newTableDataLast.totalUnitPriceAfterTax /
                    newTableDataLast.quantity
                  newTableDataLast.unitPrice =
                    (newTableDataLast.unitPriceAfterTax /
                      (100 + taxRate - newTableDataLast.discount)) *
                    100
                }
                // don gia sau thue
                newTableDataLast.unitPriceAfterTax =
                  (newTableDataLast.unitPrice *
                    (100 + taxRate - newTableDataLast.discount)) /
                  100
                // tien ck
                newTableDataLast.discountMoney =
                  (newTableDataLast.totalUnitPrice *
                    newTableDataLast.discount) /
                  100
                // tien vat
                if (_.isNumber(taxRate))
                  newTableDataLast.taxMoney =
                    ((newTableDataLast.totalUnitPrice -
                      newTableDataLast.discountMoney) *
                      taxRate) /
                    100
                // tien sau thue
                newTableDataLast.totalUnitPriceAfterTax =
                  newTableDataLast.totalUnitPrice +
                  newTableDataLast.taxMoney -
                  newTableDataLast.discountMoney
              } else if (
                !Utils.isObjectEmpty(newTableDataLast?.unitPrice) &&
                !Utils.isObjectEmpty(newTableDataLast?.totalUnitPrice)
              ) {
                // so luong
                newTableDataLast.quantity =
                  newTableDataLast.totalUnitPrice / newTableDataLast.unitPrice
                // don gia sau thue
                newTableDataLast.unitPriceAfterTax =
                  (newTableDataLast.unitPrice *
                    (100 + taxRate - newTableDataLast.discountMoney)) /
                  100
                // tien ck
                newTableDataLast.discountMoney =
                  (newTableDataLast.totalUnitPrice *
                    newTableDataLast.discount) /
                  100
                // tien vat
                if (_.isNumber(taxRate))
                  newTableDataLast.taxMoney =
                    ((newTableDataLast.totalUnitPrice -
                      newTableDataLast.discountMoney) *
                      taxRate) /
                    100
                // tien sau thue
                newTableDataLast.totalUnitPriceAfterTax =
                  newTableDataLast.totalUnitPrice +
                  newTableDataLast.taxMoney -
                  newTableDataLast.discountMoney
              }
            }
            break
        }
        break

      case 'taxRate':
        if (formik.values.autoCalculate) {
          taxRate = e.value
          newTableDataLast.taxRate = taxRate

          if (
            (taxRate == '0' ||
              taxRate == '5' ||
              taxRate == '8' ||
              taxRate == '10') &&
            (formik.values.taxType === 1 ||
              formik.values.taxType === 2 ||
              (formik.values.taxType === 3 && !formik.values.multipleTaxRate) ||
              formik.values.multipleTaxRate) &&
            parseInt(formik.values?.serial?.charAt(0)) === 1
          ) {
            taxRate = parseInt(taxRate)
            if (showPriceAfterTax) {
              newTableDataLast.taxMoney =
                (newTableDataLast.totalUnitPriceAfterTax / (taxRate + 100)) *
                taxRate
              newTableDataLast.unitPrice =
                (newTableDataLast.unitPriceAfterTax / (100 + taxRate)) * 100
              newTableDataLast.totalUnitPrice =
                newTableDataLast.unitPrice * newTableDataLast.quantity
            }
            if (!showPriceAfterTax) {
              newTableDataLast.taxMoney =
                (newTableDataLast.totalUnitPrice * taxRate) / 100
            }
          } else {
            newTableDataLast.taxMoney = 0
          }
        }
        break

      case 'taxReductionRate':
        var taxReductionRate = e.value
        newTableDataLast.taxReductionRate = taxReductionRate

        // newTableDataLast.taxReduction =
        //   ((newTableDataLast.totalUnitPrice - newTableDataLast.discountMoney) *
        //     newTableDataLast.taxReductionRate) /
        //   100 /
        //   5
        break

      default: //input không phải số
        if (type === 'addRow') {
          if (row.no === tableData.length)
            newTableData = [
              ...newTableData,
              {
                no: tableData.length + 1,
                name: '',
                type: 1,
                discount: parseInt(formik.values.discount ?? 0),
                discountMoney: 0,
                unit: '',
                unitPrice: 0,
                quantity: 0,
                totalUnitPrice: 0,
                unitPriceAfterTax: 0,
                totalUnitPriceAfterTax: 0,
                taxRate: formik.values.multipleTaxRate
                  ? systemTaxRate
                  : formik.values?.taxRate,
                taxMoney: 0,
                productId: 0,
              },
            ]
        }
        if (field === 'name') {
          const textarea = e.target
          if (textarea.scrollHeight > 40) {
            textarea.style.height = 'auto'
            const parentTextArea = textarea.parentNode
            parentTextArea.style.height = '100%'
            textarea.style.height = textarea?.scrollHeight + 'px'
          }
        }
        if (field.startsWith('extendField')) {
          newTableDataLast[field].value = e.target.value
          break
        }

        newTableDataLast[field] = e.target.value
        break
    }
    if (
      field === 'unit' ||
      field === 'inventoryItemCode' ||
      field === 'specification'
    ) {
      newTableDataLast[field] = value
    }
    // case taxMoney đã xử lý ở trên
    if (field !== 'taxMoney') {
      if (!formik.values.isTaxReduction) newTableDataLast.taxReductionRate = 0
      newTableDataLast.taxReduction =
        ((newTableDataLast.totalUnitPrice - newTableDataLast.discountMoney) *
          newTableDataLast.taxReductionRate) /
        100 /
        5
      // check taxRate có phải number k
      if (_.isNumber(taxRate))
        newTableDataLast.taxMoney =
          ((newTableDataLast.totalUnitPrice - newTableDataLast.discountMoney) *
            taxRate) /
          100
    }
    // làm tròn số
    newTableDataLast.quantity =
      newTableDataLast.quantity &&
      Utils.roundNumber(newTableDataLast.quantity, formatNumber?.quantity)
    newTableDataLast.unitPriceAfterTax =
      newTableDataLast.unitPriceAfterTax &&
      Utils.roundNumber(
        newTableDataLast.unitPriceAfterTax,
        roundingConfig('unitPrice'),
      )
    newTableDataLast.totalUnitPriceAfterTax = Utils.roundNumber(
      newTableDataLast.totalUnitPriceAfterTax,
      roundingConfig('totalAmount'),
    )
    newTableDataLast.unitPrice =
      newTableDataLast.unitPrice &&
      Utils.roundNumber(newTableDataLast.unitPrice, roundingConfig('unitPrice'))
    newTableDataLast.totalUnitPrice = Utils.roundNumber(
      newTableDataLast.totalUnitPrice,
      roundingConfig('totalAmount'),
    )
    newTableDataLast.discount =
      newTableDataLast.discount &&
      Utils.roundNumber(newTableDataLast.discount, formatNumber?.ratio)
    newTableDataLast.discountMoney =
      newTableDataLast.discountMoney &&
      Utils.roundNumber(
        newTableDataLast.discountMoney,
        formatNumber?.currencyExchange,
      )
    newTableDataLast.taxMoney = Utils.roundNumber(
      newTableDataLast.taxMoney,
      roundingConfig('totalAmount'),
    )
    newTableDataLast.taxReduction = Utils.roundNumber(
      newTableDataLast.taxReduction,
      formatNumber?.currencyExchange,
    )
    // tính tiền quy đổi cho mỗi row
    newTableDataLast.exchangedTotalUnitPrice = Utils.roundNumber(
      newTableDataLast?.totalUnitPrice * exchangeRate,
      formatNumber?.foreignCurrency,
    )
    newTableDataLast.exchangedTaxMoney = Utils.roundNumber(
      newTableDataLast?.taxMoney * exchangeRate,
      formatNumber?.foreignCurrency,
    )
    newTableDataLast.exchangedDiscountMoney = Utils.roundNumber(
      newTableDataLast?.discountMoney * exchangeRate,
      formatNumber?.foreignCurrency,
    )
    newTableDataLast.taxReductionExchange = Utils.roundNumber(
      newTableDataLast?.taxReduction * exchangeRate,
      formatNumber?.foreignCurrency,
    )
    setTableData(newTableData)
  }

  const handleChangeGTGT = (taxRate) => {
    setIsChangeTable(true)
    const { exchangeRate } = formik.values
    let newTableData = [...tableData]
    formik.setFieldValue('taxRate', taxRate)
    newTableData.forEach((item) => {
      item.taxRate = taxRate
      let intTaxRate
      if (
        (taxRate == '0' ||
          taxRate == '5' ||
          taxRate == '8' ||
          taxRate == '10') &&
        (formik.values.taxType === 1 ||
          formik.values.taxType === 2 ||
          (formik.values.taxType === 3 && !formik.values.multipleTaxRate) ||
          formik.values.multipleTaxRate) &&
        parseInt(formik.values?.serial?.charAt(0)) === 1
      ) {
        intTaxRate = parseInt(taxRate)
      } else if (taxRate !== 'KHAC') {
        intTaxRate = 0
      }
      if (showPriceAfterTax) {
        if (_.isNumber(intTaxRate))
          item.unitPrice = item.unitPriceAfterTax / (1 + intTaxRate / 100)
        if (!_.isNumber(intTaxRate)) item.unitPrice = item.unitPriceAfterTax
        item.totalUnitPrice = item.unitPrice * item.quantity
      }
      item.discountMoney = (item.discount * item.totalUnitPrice) / 100
      item.taxMoney = _.isNumber(intTaxRate)
        ? ((item.totalUnitPrice - item.discountMoney) * intTaxRate) / 100
        : item.taxMoney
      item.exchangedTaxMoney = item.taxMoney * exchangeRate
      item.exchangedDiscountMoney = item.discountMoney * exchangeRate
      item.exchangedTotal = item.totalUnitPrice * exchangeRate
      item.exchangedTotalUnitPriceAfterTax =
        item.totalUnitPriceAfterTax * exchangeRate
    })
    setTableData(newTableData)
  }

  const handleSelectDiscountType = (discountType) => {
    let newTableData = [...tableData]

    newTableData.map((item) => {
      let taxRate = item.taxRate
      let taxMoney = 0
      if (formik.values.multipleTaxRate) {
        taxRate = parseInt(taxRate)
      } else if (
        (item.taxRate == '0' ||
          item.taxRate == '5' ||
          item.taxRate == '8' ||
          item.taxRate == '10') &&
        (formik.values.taxType === 1 ||
          formik.values.taxType === 2 ||
          (formik.values.taxType === 3 && !formik.values.multipleTaxRate) ||
          formik.values.multipleTaxRate) &&
        parseInt(formik.values?.serial?.charAt(0)) === 1
      ) {
        taxRate = parseInt(taxRate)
      } else if (taxRate !== 'KHAC') {
        taxRate = 0
      }
      if (_.isNumber(taxRate)) taxMoney = (item.totalUnitPrice * taxRate) / 100

      item.discount = 0
      item.discountMoney = 0
      item.exchangedDiscountMoney = 0
      item.taxMoney = taxMoney
    })

    setTableData(newTableData)
    formik.setFieldValue('discount', 0)
    formik.setFieldValue('discountMoney', 0)
    formik.setFieldValue('exchangedDiscountMoney', 0)
    formik.setFieldValue('discountType', discountType)
  }

  const handleChangeTypeTableData = (typeValue, row) => {
    setIsChangeTable(true)
    let newTableData = [...tableData]
    newTableData[row.no - 1].type = typeValue
    if (typeValue === 3) {
      newTableData[row.no - 1]['discount'] = 0
      newTableData[row.no - 1]['discountMoney'] = 0
      newTableData[row.no - 1]['exchangedDiscountMoney'] = 0
    }
    // reset các giá trị về rỗng khi type là diễn giải
    if (typeValue === 4) {
      const ignoreArray = ['no', 'name', 'type']
      for (let field in newTableData[row.no - 1]) {
        if (!ignoreArray.includes(field)) {
          newTableData[row.no - 1][field] = ''
        }
      }
    }
    setTableData(newTableData)
  }

  const handleConfirmSaveToSendInvoice = () => {
    submitAction = 'SaveNoNavigate'
    console.log('formik', formik)
    formik.submitForm()
  }

  const handleConfirmSaveDuplicateName = () => {
    formik.submitForm()
  }

  const handleAddRow = useCallback(
    (row, newTableData) => {
      setIsChangeTable(true)
      if (row.no === tableData.length) {
        newTableData = [
          ...newTableData,
          {
            no: tableData.length + 1,
            name: '',
            type: 1,
            discount: parseInt(formik.values.discount ?? 0),
            discountMoney: 0,
            unit: '',
            unitPrice: 0,
            quantity: 0,
            actualExport: 0,
            actualImport: 0,
            totalUnitPrice: 0,
            taxRate: formik.values.multipleTaxRate
              ? systemTaxRate
              : formik.values?.taxRate,
            taxReductionRate: 1,
            unitPriceAfterTax: 0,
            totalUnitPriceAfterTax: 0,
            taxMoney: 0,
            productId: 0,
          },
        ]
      }
      setTableData(newTableData)
    },
    [
      tableData.length,
      formik.values.discount,
      formik.values.multipleTaxRate,
      formik.values?.taxRate,
      systemTaxRate,
    ],
  )

  const handleChangeProductNameTableData = useCallback(
    (name, row, type) => {
      let newTableData = [...tableData]
      const row_name = name.target?.value ?? name
      newTableData[row.no - 1].name = row_name
      if (type === 'addRow' && row_name.length > 0) {
        return handleAddRow(row, newTableData)
      }
      return setTableData(newTableData)
    },
    [handleAddRow, tableData],
  )

  const handleSelectProduct = useCallback(
    (product, row, type) => {
      let exchangeRate = Utils.parseFormattedNumberToFloat(
        formik.values.exchangeRate,
      )
      let newTableData = [...tableData]
      let newTableDataLast = newTableData[row.no - 1]
      newTableDataLast.name = product?.name ?? ''
      if (newTableDataLast.type === 4) return
      newTableDataLast.unit = product?.unit ?? ''
      newTableDataLast.productId = product?.productId ?? 0
      let taxRate = formik.values.taxRate
      if (formik.values.multipleTaxRate) {
        newTableDataLast.taxRate = product?.vat ?? '0'
        taxRate = parseInt(newTableDataLast.taxRate)
      } else if (
        (newTableDataLast.taxRate == '0' ||
          newTableDataLast.taxRate == '5' ||
          newTableDataLast.taxRate == '8' ||
          newTableDataLast.taxRate == '10') &&
        (formik.values.taxType === 1 ||
          formik.values.taxType === 2 ||
          (formik.values.taxType === 3 && !formik.values.multipleTaxRate) ||
          formik.values.multipleTaxRate) &&
        parseInt(formik.values?.serial?.charAt(0)) === 1
      ) {
        taxRate = parseInt(newTableDataLast.taxRate)
      } else if (taxRate !== 'KHAC') {
        taxRate = 0
      }
      newTableDataLast.discount = 0
      if (
        formik.values.discountType === DISCOUNT_TYPE.EACH_ITEM &&
        product?.discountRate
      ) {
        newTableDataLast.discount =
          newTableDataLast.type === 3 ? 0 : (product?.discountRate ?? 0)
      }

      // if (
      //   newTableDataLast.taxRate === 'KCT' ||
      //   newTableDataLast.taxRate === 'KHAC' ||
      //   newTableDataLast.taxRate === 'KKKNT'
      // ) {
      //   taxRate = newTableDataLast.taxRate
      // }
      newTableDataLast.quantity = 1

      // nếu là pxk kiêm vận chuyển nội bộ thì mới gán
      if (isDeliveryBill && formik.values.invoiceType === 'N')
        newTableDataLast.actualExport = newTableDataLast.actualImport = 1
      if (formik.values.isTaxReduction) newTableDataLast.taxReductionRate = 1
      if (!formik.values.isTaxReduction) newTableDataLast.taxReductionRate = 0
      // if (typeof taxRate !== 'number') {
      //   taxRate = 0
      // }
      if (product.priceAfterTax) {
        newTableDataLast.unitPriceAfterTax = product?.price ?? 0
        //tinh don gia truoc thue
        newTableDataLast.unitPrice = _.isNumber(taxRate)
          ? newTableDataLast.unitPriceAfterTax / (1 + taxRate / 100)
          : newTableDataLast.unitPriceAfterTax
      }
      if (!product.priceAfterTax) {
        newTableDataLast.unitPrice = Number(product?.price) ?? 0
        //tinh don gia sau thue
        newTableDataLast.unitPriceAfterTax = _.isNumber(taxRate)
          ? newTableDataLast.unitPrice * (1 + taxRate / 100)
          : newTableDataLast.unitPrice
      }
      // tinh tien chiet khau
      newTableDataLast.discountMoney =
        newTableDataLast.type === 3
          ? 0
          : (newTableDataLast.unitPrice * newTableDataLast.discount) / 100
      // vat
      if (_.isNumber(taxRate))
        newTableDataLast.taxMoney =
          ((newTableDataLast.unitPrice - newTableDataLast.discountMoney) *
            taxRate) /
          100
      //tổng tiền
      newTableDataLast.totalUnitPrice =
        newTableDataLast.unitPrice * newTableDataLast.quantity
      // tổng tiền sau thuế, ck
      newTableDataLast.totalUnitPriceAfterTax =
        newTableDataLast.totalUnitPrice -
        newTableDataLast.discountMoney +
        newTableDataLast.taxMoney
      // tổng tiền chiết khấu quy đổi
      newTableDataLast.exchangedDiscountMoney =
        newTableDataLast.discountMoney * exchangeRate
      // thành tiền quy đổi
      newTableDataLast.exchangedTotalUnitPrice =
        newTableDataLast.totalUnitPrice * exchangeRate
      // tiền thuế GTGT được giảm
      newTableDataLast.taxReduction =
        ((newTableDataLast.totalUnitPrice - newTableDataLast.discountMoney) *
          newTableDataLast.taxReductionRate) /
        100 /
        5
      // tiền thuế GTGT được giảm quy đổi
      newTableDataLast.taxReductionExchange =
        newTableDataLast.taxReduction * exchangeRate

      // làm tròn số
      newTableDataLast.quantity = Utils.roundNumber(
        newTableDataLast.quantity,
        formatNumber?.quantity,
      )
      newTableDataLast.unitPriceAfterTax = Utils.roundNumber(
        newTableDataLast.unitPriceAfterTax,
        formatNumber?.unitPrice,
      )
      newTableDataLast.totalUnitPriceAfterTax = Utils.roundNumber(
        newTableDataLast.totalUnitPriceAfterTax,
        formatNumber?.totalAmount,
      )
      newTableDataLast.unitPrice = Utils.roundNumber(
        newTableDataLast.unitPrice,
        roundingConfig('unitPrice'),
      )
      newTableDataLast.totalUnitPrice = Utils.roundNumber(
        newTableDataLast.totalUnitPrice,
        formatNumber?.totalAmount,
      )
      newTableDataLast.discount = Utils.roundNumber(
        newTableDataLast.discount,
        formatNumber?.ratio,
      )
      newTableDataLast.discountMoney = Utils.roundNumber(
        newTableDataLast.discountMoney,
        formatNumber?.currencyExchange,
      )
      newTableDataLast.taxMoney = Utils.roundNumber(
        newTableDataLast.taxMoney,
        formatNumber?.totalCost,
      )
      newTableDataLast.taxReduction = Utils.roundNumber(
        newTableDataLast.taxReduction,
        formatNumber?.currencyExchange,
      )

      // tính tiền quy đổi cho mỗi row
      newTableDataLast.exchangedTotalUnitPrice = Utils.roundNumber(
        newTableDataLast.exchangedTotalUnitPrice,
        formatNumber?.foreignCurrency,
      )
      newTableDataLast.exchangedTaxMoney = Utils.roundNumber(
        newTableDataLast?.taxMoney * exchangeRate,
        formatNumber?.foreignCurrency,
      )
      newTableDataLast.exchangedDiscountMoney = Utils.roundNumber(
        newTableDataLast?.discountMoney * exchangeRate,
        formatNumber?.foreignCurrency,
      )
      newTableDataLast.taxReductionExchange = Utils.roundNumber(
        newTableDataLast?.taxReduction * exchangeRate,
        formatNumber?.foreignCurrency,
      )

      if (type === 'addRow' && newTableDataLast.name.length > 0) {
        return handleAddRow(row, newTableData)
      }
      return setTableData(newTableData)
    },
    [handleAddRow, tableData],
  )

  // blur khỏi ô thực xuất
  const handleOnActualExportBlur = (e, row) => {
    const tableDataClone = [...tableData]
    const rowIndex = row.no - 1
    tableDataClone[rowIndex].actualImport = Utils.parseFormattedNumberToFloat(
      e?.target?.value,
    )
    setTableData(tableDataClone)
  }

  const columns = useMemo(() => {
    /** @type {import('antd/es/table').ColumnProps<any>[]} */
    let ret = [
      {
        title: 'STT',
        dataIndex: 'no',
        key: 'STT',
        width: 10,
        align: 'center',
        render: (text, row) => (
          <div className="d-flex justify-content-center">{row.no}</div>
        ),
      },
      {
        title: 'Tính chất HHDV',
        hidden: !checkedHHDV,
        dataIndex: 'type',
        key: 'type',
        width: 80,
        align: 'center',
        render: (_name, row) => (
          <CustomAntSelect
            options={
              isDeliveryBill
                ? SelectOptions.typeOfGoodInDeliveryBill
                : SelectOptions.typeOfGood
            }
            value={
              row?.type
                ? _.find(SelectOptions.typeOfGood, { value: row?.type })
                : SelectOptions.typeOfGood[0]
            }
            placeholder="Chọn tính chất hhdv"
            allowClear={false}
            showSearch={false}
            onChange={(value) => handleChangeTypeTableData(value, row)}
            showArrow={false}
            bordered={false}
          />
        ),
      },
      {
        title: isDeliveryBill
          ? 'Tên nhãn hiệu, quy cách, phẩm chất vật tư'
          : 'Tên hàng hoá/Dịch vụ',
        dataIndex: 'good_name',
        key: 'good_name',
        width: 200,
        align: 'center',
        render: (_, row) => (
          <GoodsAutoComplete
            name={'good_name'}
            rowData={row}
            onChangeHandler={handleSelectProduct}
            onBlurHandler={handleChangeProductNameTableData}
          />
        ),
      },
      {
        title: 'Mã số',
        dataIndex: 'inventoryItemCode',
        width: 120,
        align: 'center',
        hidden: !isDeliveryBill,
        render: (_, row) => (
          <div className="w-100 input-table-cover">
            <BaseTextFieldNoFormik
              showBorder={false}
              disabled={row?.type === 4 ? true : false}
              rounded={row?.type === 4 ? false : true}
              transparent={row?.type === 4 ? false : true}
              textAlign={'center'}
              inputValue={row.inventoryItemCode}
              onChange={(e) => {
                handleChangeTableData(e, row, 'inventoryItemCode')
              }}
              value={row?.inventoryItemCode}
            />
          </div>
        ),
      },
      {
        title: 'Quy cách',
        dataIndex: 'specification',
        width: 120,
        align: 'center',
        hidden:
          (isDeliveryBill && formik.values?.invoiceType === 'N') ||
          !isDeliveryBill,
        render: (_, row) => (
          <div className="w-100 input-table-cover">
            <BaseTextFieldNoFormik
              showBorder={false}
              value={row?.specification || ''}
              disabled={row?.type === 4 ? true : false}
              rounded={row?.type === 4 ? false : true}
              transparent={row?.type === 4 ? false : true}
              textAlign={'center'}
              inputValue={row.specification}
              onChange={(e) => {
                handleChangeTableData(e, row, 'specification')
              }}
            />
          </div>
        ),
      },
      {
        title: 'ĐVT',
        dataIndex: 'unit',
        key: 'unit',
        width: 60,
        align: 'center',
        render: (_, row) => (
          <div className="w-100 input-table-cover">
            <BaseTextFieldNoFormik
              onChange={(e) => {
                setInputValue(e.target.value)
                handleChangeTableData(e, row, 'unit')
              }}
              showBorder={false}
              value={row?.unit || ''}
              disabled={row?.type === 4 ? true : false}
              rounded={row?.type === 4 ? false : true}
              transparent={row?.type === 4 ? false : true}
              textAlign={'center'}
              inputValue={row.unit}
              // multiLine={true}
            />
          </div>
        ),
      },
      {
        title: (
          <div className="d-flex flex-column align-items-center">
            <Button
              type="text"
              shape="circle"
              className="align-self-end"
              icon={
                persistent === 'quantityPersistent' ? (
                  <LockOutlined />
                ) : (
                  <UnlockOutlined />
                )
              }
              onClick={() => {
                console.log(persistent)
                setPersistent((prev) =>
                  prev === 'quantityPersistent'
                    ? 'priceAfterTaxPersistent'
                    : 'quantityPersistent',
                )
              }}
            ></Button>

            <Typography.Title level={5} className="">
              Số lượng
            </Typography.Title>
          </div>
        ),
        dataIndex: 'quantity',
        key: 'quantity',
        width: 100,
        align: 'center',
        hidden: isDeliveryBill && formik.values?.invoiceType === 'N',
        render: (_, row) => (
          <div className="w-100 input-table-cover">
            <InputNumberNoFormik
              systemConfigType="quantity"
              showBorder={false}
              onChange={(e) => {
                setIsChangeTaxRate(true)
                handleChangeTableData(e, row, 'quantity', 'number')
              }}
              value={row?.quantity}
              disabled={row?.type === 4 ? true : false}
              transparent={row?.type === 4 ? false : true}
              rounded={row?.type === 4 ? false : true}
              textAlign={'center'}
              allowNegative={isAdjustInvoice ? true : false}
            />
          </div>
        ),
      },
      {
        title: 'Số lượng',
        width: 120,
        align: 'center',
        // CHỉ hiển thị khi đó là phiếu xuất kho nội bộ
        hidden: !isDeliveryBill || formik.values?.invoiceType === 'B',
        children: [
          {
            title: 'Thực xuất',
            width: 60,
            align: 'center',
            render: (_, row) => (
              <div className="w-100 input-table-cover">
                <InputNumberNoFormik
                  additionalInputClassName={'pr-5'}
                  systemConfigType="quantity"
                  showBorder={false}
                  onChange={(e) => {
                    handleChangeTableData(e, row, 'actualExport', 'number')
                  }}
                  onBlur={(e) => handleOnActualExportBlur(e, row)}
                  value={row?.actualExport}
                  disabled={row?.type === 4 ? true : false}
                  transparent={row?.type === 4 ? false : true}
                  rounded={row?.type === 4 ? false : true}
                  textAlign={'right'}
                  allowNegative={isAdjustInvoice ? true : false}
                />
              </div>
            ),
          },
          {
            title: 'Thực nhập',
            width: 60,
            align: 'center',
            render: (_, row) => (
              <div className="w-100 input-table-cover">
                <InputNumberNoFormik
                  additionalInputClassName={'pr-5'}
                  systemConfigType="quantity"
                  showBorder={false}
                  onChange={(e) => {
                    handleChangeTableData(e, row, 'actualImport', 'number')
                  }}
                  value={row?.actualImport}
                  disabled={row?.type === 4 ? true : false}
                  transparent={row?.type === 4 ? false : true}
                  rounded={row?.type === 4 ? false : true}
                  textAlign={'right'}
                  allowNegative={isAdjustInvoice ? true : false}
                />
              </div>
            ),
          },
        ],
      },
      {
        title: () => (
          <div className="d-flex flex-column align-items-center">
            <Button
              type="text"
              shape="circle"
              className="align-self-end"
              icon={
                persistent === 'priceAfterTaxPersistent' ? (
                  <LockOutlined />
                ) : (
                  <UnlockOutlined />
                )
              }
              onClick={() => {
                console.log(persistent)
                setPersistent((prev) =>
                  prev === 'priceAfterTaxPersistent'
                    ? 'quantityPersistent'
                    : 'priceAfterTaxPersistent',
                )
              }}
            ></Button>
            <Typography.Title level={5} className="">
              Đơn giá sau thuế
            </Typography.Title>
          </div>
        ),
        dataIndex: 'unitPriceAfterTax',
        hidden: !invoiceSetting?.priceAfterTax || isDeliveryBill,
        width: 120,
        align: 'center',
        render: (_, row) => (
          <div className="w-100 input-table-cover column-money">
            <InputNumberNoFormik
              systemConfigType={
                !['VNĐ', 'VND'].includes(formik.values.currency)
                  ? 'exchangeTotalCost'
                  : 'unitPrice'
              }
              showBorder={false}
              // onValueChange={(e, sourceInfo) => {
              //   if (sourceInfo.source === 'event') {
              //     handleChangeTableData(e, row, 'unitPriceAfterTax', 'number')
              //   }
              // }}
              onChange={(e) => {
                setIsChangeTaxRate(true)
                handleChangeTableData(e, row, 'unitPriceAfterTax', 'number')
              }}
              value={row?.unitPriceAfterTax}
              disabled={row?.type === 4 ? true : false}
              transparent={row?.type === 4 ? false : true}
              rounded={row?.type === 4 ? false : true}
              textAlign={'right'}
              allowNegative={isAdjustInvoice ? true : false}
              additionalInputClassName={'pr-5'}
            />
          </div>
        ),
      },
      {
        title: 'Thành tiền sau thuế',
        dataIndex: 'totalUnitPriceAfterTax',
        key: 'totalUnitPriceAfterTax',
        hidden: !invoiceSetting?.priceAfterTax || isDeliveryBill,
        width: 100,
        align: 'right',
        render: (_, row) => (
          <div className="w-100 input-table-cover">
            <InputNumberNoFormik
              additionalInputClassName={'pr-5'}
              systemConfigType={
                !['VNĐ', 'VND'].includes(formik.values.currency)
                  ? 'exchangeTotalCost'
                  : 'totalAmount'
              }
              showBorder={false}
              onChange={(e) => {
                setIsChangeTaxRate(true)
                handleChangeTableData(
                  e,
                  row,
                  'totalUnitPriceAfterTax',
                  'number',
                )
              }}
              value={row?.totalUnitPriceAfterTax}
              disabled={row?.type === 4 ? true : false}
              transparent={row?.type === 4 ? false : true}
              rounded={row?.type === 4 ? false : true}
              textAlign={'right'}
              allowNegative={isAdjustInvoice ? true : false}
            />
          </div>
        ),
      },
      {
        title: 'Đơn giá',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        width: 90,
        align: 'right',
        render: (_, row) => (
          <div className="w-100 input-table-cover column-money">
            <InputNumberNoFormik
              systemConfigType={
                !['VNĐ', 'VND'].includes(formik.values.currency)
                  ? 'exchangeTotalCost'
                  : 'unitPrice'
              }
              // systemConfigType={
              //   formik.values.exchangeRate !== 1 ? 'currencyExchange' : null
              // }
              showBorder={false}
              // onValueChange={(e, sourceInfo) => {
              //   if (sourceInfo.source === 'event') {
              //     handleChangeTableData(e, row, 'unitPrice', 'number')
              //   }
              // }}
              onChange={(e) => {
                setIsChangeTaxRate(true)
                handleChangeTableData(e, row, 'unitPrice', 'number')
              }}
              additionalInputClassName={'pr-5'}
              value={row?.unitPrice}
              disabled={row?.type === 4 ? true : false}
              transparent={row?.type === 4 ? false : true}
              rounded={row?.type === 4 ? false : true}
              textAlign={'right'}
              allowNegative={isAdjustInvoice ? true : false}
            />
          </div>
        ),
      },
      {
        title: 'Thành tiền',
        dataIndex: 'totalUnitPrice',
        key: 'totalUnitPrice',
        width: 100,
        align: 'right',
        render: (_, row) => (
          <div className="w-100 input-table-cover">
            <InputNumberNoFormik
              systemConfigType={
                !['VNĐ', 'VND'].includes(formik.values.currency)
                  ? 'exchangeTotalCost'
                  : 'totalAmount'
              }
              showBorder={false}
              // onValueChange={(e, sourceInfo) => {
              //   if (sourceInfo.source === 'event') {
              //     handleChangeTableData(e, row, 'totalUnitPrice', 'number')
              //   }
              // }}
              onChange={(e) => {
                setIsChangeTaxRate(true)
                handleChangeTableData(e, row, 'totalUnitPrice', 'number')
              }}
              additionalInputClassName={'pr-5'}
              value={row?.totalUnitPrice}
              disabled={row?.type === 4 ? true : false}
              transparent={row?.type === 4 ? false : true}
              rounded={row?.type === 4 ? false : true}
              textAlign={'right'}
              allowNegative={isAdjustInvoice ? true : false}
            />
          </div>
        ),
      },
    ]
    ret = ret.filter((item) => !item.hidden)

    if (formik.values.currency !== 'VNĐ') {
      ret.push({
        title: 'Thành tiền quy đổi',
        dataIndex: 'exchangedTotalUnitPrice',
        key: 'exchangedTotalUnitPrice',
        width: 100,
        align: 'right',
        render: (_, row) => (
          <div className="w-100 input-table-cover">
            <InputNumberNoFormik
              systemConfigType="currencyExchange"
              showBorder={false}
              // onValueChange={(e, sourceInfo) => {
              //   if (sourceInfo.source === 'event') {
              //     handleChangeTableData(
              //       e,
              //       row,
              //       'exchangedTotalUnitPrice',
              //       'number',
              //     )
              //   }
              // }}
              onChange={(e) => {
                setIsChangeTaxRate(true)
                handleChangeTableData(
                  e,
                  row,
                  'exchangedTotalUnitPrice',
                  'number',
                )
              }}
              value={row?.exchangedTotalUnitPrice || 0}
              disabled={formik.values.autoCalculate ? true : false}
              transparent={false}
              rounded={false}
              textAlign={'right'}
              allowNegative={isAdjustInvoice ? true : false}
            />
          </div>
        ),
      })
    }

    if (formik.values.discountType === 1) {
      ret.push(
        {
          title: 'Tỉ lệ CK (%)',
          key: '',
          width: 130,
          align: 'center',
          render: (_, row) => (
            <div className="w-100 input-table-cover">
              <InputNumberNoFormik
                systemConfigType="ratio"
                showBorder={false}
                maxLength={formatNumber?.ratio + 4}
                // format={Utils.limitIntergerPath}
                // onValueChange={(e, sourceInfo) => {
                //   if (sourceInfo.source === 'event') {
                //     if (e?.floatValue > 100) {
                //       e.floatValue = 100
                //       e.formattedValue = '100'
                //       e.value = '100'
                //     }
                //     if (e?.floatValue < 0) {
                //       e.floatValue = 0
                //       e.formattedValue = '0'
                //       e.value = '0'
                //     }

                //     handleChangeTableData(e, row, 'discount', 'number')
                //   }
                // }}
                onChange={(e) => {
                  if (
                    Utils.parseFormattedNumberToFloat(e.target?.value) > 100
                  ) {
                    e.target.value = '100'
                  }
                  if (Utils.parseFormattedNumberToFloat(e?.floatValue) < 0) {
                    e.target.value = '0'
                  }
                  setIsChangeTaxRate(true)
                  handleChangeTableData(e, row, 'discount', 'number')
                }}
                // onBlur={(e) => {
                //   console.log(e)
                //   if (Utils.parseFormattedNumberToFloat(e.target.value) > 100) {
                //     e.target.value = '100'
                //   }
                //   if (Utils.parseFormattedNumberToFloat(e?.floatValue) < 0) {
                //     e.target.value = '0'
                //   }
                //   handleChangeTableData(e, row, 'discount', 'number')
                // }}
                value={row?.discount}
                disabled={row?.type === 4 || row?.type === 3 ? true : false}
                transparent={row?.type === 4 || row?.type === 3 ? false : true}
                rounded={row?.type === 4 || row?.type === 3 ? false : true}
                textAlign={'center'}
                allowNegative={isAdjustInvoice ? true : false}
              />
            </div>
          ),
        },
        {
          title: 'Tiền CK',
          key: '',
          width: 100,
          align: 'center',
          render: (_, row) => (
            <div className="w-100 input-table-cover">
              <InputNumberNoFormik
                systemConfigType="currencyExchange"
                showBorder={false}
                // onValueChange={(e, sourceInfo) => {
                //   if (sourceInfo.source === 'event') {
                //     handleChangeTableData(e, row, 'discountMoney', 'number')
                //   }
                // }}
                onChange={(e) => {
                  setIsChangeTaxRate(true)
                  handleChangeTableData(e, row, 'discountMoney', 'number')
                }}
                value={row?.discountMoney}
                disabled={row?.type === 4 || row?.type === 3 ? true : false}
                transparent={row?.type === 4 || row?.type === 3 ? false : true}
                rounded={row?.type === 4 || row?.type === 3 ? false : true}
                textAlign={'center'}
                allowNegative={isAdjustInvoice ? true : false}
              />
            </div>
          ),
        },
      )
    }

    if (formik.values.currency !== 'VNĐ' && formik.values.discountType === 1) {
      ret.push({
        title: 'Tiền CK quy đổi',
        width: 200,
        align: 'center',
        render: (_, row) => (
          <div className="w-100 input-table-cover">
            <InputNumberNoFormik
              systemConfigType="foreignCurrency"
              showBorder={false}
              // onValueChange={(e, sourceInfo) => {
              //   if (sourceInfo.source === 'event') {
              //     handleChangeTableData(
              //       e,
              //       row,
              //       'exchangedDiscountMoney',
              //       'number',
              //     )
              //   }
              // }}
              onChange={(e) => {
                setIsChangeTaxRate(true)
                handleChangeTableData(
                  e,
                  row,
                  'exchangedDiscountMoney',
                  'number',
                )
              }}
              value={row?.exchangedDiscountMoney || 0}
              disabled={true}
              transparent={false}
              rounded={false}
              textAlign={'center'}
              allowNegative={isAdjustInvoice ? true : false}
            />
          </div>
        ),
      })
    }

    if (
      parseInt(formik.values?.serial?.charAt(0)) === 2 &&
      formik.values.isTaxReduction === true
    ) {
      ret.push({
        title: '% Tính thuế',
        width: 100,
        align: 'center',
        render: (_, row) => (
          <Select
            styles={customSelectTableCellStyle}
            className="w-100"
            classNamePrefix="select"
            options={SelectOptions.TaxReductionRate}
            value={
              row?.taxReductionRate
                ? SelectOptions.TaxReductionRate.find(
                    (option) =>
                      option.value.toString() ===
                      row?.taxReductionRate.toString(),
                  )
                : SelectOptions.TaxReductionRate[0]
            }
            onChange={(selectedOption) => {
              setIsChangeTaxRate(true)
              handleChangeTableData(
                selectedOption,
                row,
                'taxReductionRate',
                'taxReductionRate',
              )
            }}
            // defaultValue={SelectOptions.typeOfGood[0]}
            name="color"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            }}
            menuPortalTarget={document.querySelector('body')}
            menuPosition="fixed"
            menuPlacement="auto"
            isDisabled={row?.type === 4 ? true : false}
          />
        ),
      })

      ret.push({
        title: 'Tiền thuế GTGT được giảm',
        key: '',
        width: 120,
        align: 'center',
        render: (_, row) => (
          <div className="w-100 input-table-cover">
            <InputNumberNoFormik
              systemConfigType="currencyExchange"
              showBorder={false}
              // onValueChange={(e, sourceInfo) => {
              //   if (sourceInfo.source === 'event') {
              //     handleChangeTableData(e, row, 'taxReduction', 'number')
              //   }
              // }}
              onChange={(e) => {
                setIsChangeTaxRate(true)
                handleChangeTableData(e, row, 'taxReduction', 'number')
              }}
              value={row?.taxReduction}
              disabled={true}
              transparent={row?.type === 4 ? false : true}
              rounded={row?.type === 4 ? false : true}
              textAlign={'right'}
              allowNegative={isAdjustInvoice ? true : false}
            />
          </div>
        ),
      })
      if (formik.values.currency !== 'VNĐ') {
        ret.push({
          title: 'Tiền thuế GTGT được giảm quy đổi',
          key: '',
          width: 120,
          align: 'center',
          render: (_, row) => (
            <div className="w-100 input-table-cover">
              <InputNumberNoFormik
                systemConfigType="currencyExchange"
                showBorder={false}
                // onValueChange={(e, sourceInfo) => {
                //   if (sourceInfo.source === 'event') {
                //     handleChangeTableData(e, row, 'taxReduction', 'number')
                //   }
                // }}
                value={row?.taxReductionExchange}
                disabled={true}
                transparent={row?.type === 4 ? false : true}
                rounded={row?.type === 4 ? false : true}
                textAlign={'right'}
                allowNegative={isAdjustInvoice ? true : false}
              />
            </div>
          ),
        })
      }
    }

    if (
      parseInt(formik.values?.serial?.charAt(0)) === 1 &&
      (formik.values.taxType === 2 ||
        (formik.values.taxType === 3 && formik.values.multipleTaxRate))
    ) {
      ret.push({
        title: '% Thuế',
        width: 100,
        align: 'center',
        render: (_, row) => (
          <Select
            styles={customSelectTableCellStyle}
            className="w-100"
            classNamePrefix="select"
            options={SelectOptions.GTGT}
            value={
              row?.taxRate
                ? SelectOptions.GTGT.find(
                    (option) =>
                      option.value.toString() === row?.taxRate.toString(),
                  )
                : SelectOptions.GTGT[0]
            }
            onChange={(selectedOption) => {
              console.log(selectedOption.value, row?.taxRate)
              if (selectedOption.value != row?.taxRate) {
                setIsChangeTaxRate(true)
              }
              handleChangeTableData(selectedOption, row, 'taxRate', 'taxRate')
            }}
            // defaultValue={SelectOptions.typeOfGood[0]}
            name="color"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            }}
            menuPortalTarget={document.querySelector('body')}
            menuPosition="fixed"
            menuPlacement="auto"
            isDisabled={row?.type === 4 ? true : false}
          />
        ),
      })

      ret.push({
        title: 'Tiền thuế',
        key: '',
        width: 120,
        align: 'center',
        render: (_, row) => (
          <div className="w-100 input-table-cover">
            <InputNumberNoFormik
              systemConfigType="totalCost"
              showBorder={false}
              // onValueChange={(e, sourceInfo) => {
              //   if (sourceInfo.source === 'event') {
              //     handleChangeTableData(e, row, 'taxMoney', 'number')
              //   }
              // }}
              onChange={(e) => {
                setIsChangeTaxRate(true)
                handleChangeTableData(e, row, 'taxMoney', 'number')
              }}
              value={row?.taxMoney}
              // disabled={row?.taxRate === 'KHAC' || autoCalculate ? false : true}
              // disabled={false}
              disabled={
                isAdjustInvoice
                  ? formik.values.autoCalculate
                  : row?.taxRate !== 'KHAC'
              }
              transparent={row?.type === 4 ? false : true}
              rounded={row?.type === 4 ? false : true}
              textAlign={'right'}
              allowNegative={isAdjustInvoice ? true : false}
            />
          </div>
        ),
      })
      if (formik.values.currency !== 'VNĐ') {
        ret.push({
          title: 'Tiền thuế quy đổi',
          key: '',
          width: 120,
          align: 'right',
          render: (_, row) => (
            <div className="w-100 input-table-cover">
              <InputNumberNoFormik
                systemConfigType="foreignCurrency"
                showBorder={false}
                onChange={(e) => {
                  setIsChangeTaxRate(true)
                  handleChangeTableData(e, row, 'exchangedTaxMoney', 'number')
                }}
                value={row?.exchangedTaxMoney}
                disabled={row?.taxRate === 'KHAC' ? false : true}
                transparent={row?.type === 4 ? false : true}
                rounded={row?.type === 4 ? false : true}
                textAlign={'right'}
                allowNegative={isAdjustInvoice ? true : false}
              />
            </div>
          ),
        })
      }
    }

    tableDetailExtendFields.forEach((item) => {
      if (item?.forceShow || item?.show) {
        ret.push({
          title: item.label,
          dataIndex: item.name,
          key: item.name,
          // width: 100,
          align: 'center',
          render: (_, row) => (
            <div className="w-100 input-table-cover">
              <BaseTextFieldNoFormik
                // onChange={(e) => {
                //   handleChangeTableData(e, row, item.name)
                // }}
                onChange={(e) => {
                  handleChangeTableData(e, row, item.name)
                }}
                transparent={true}
                showBorder={false}
                inputValue={
                  row[item.name]?.value ??
                  row[item.name]?.defaultValue ??
                  item.defaultValue
                }
              />
            </div>
          ),
        })
      }
    })

    ret.push({
      title: '',
      dataIndex: 'option',
      key: 'option',
      width: 50,
      align: 'center',
      render: (_, row) => (
        <div className="d-flex justify-content-center">
          {row.no === tableData.length && (
            <div
              className="d-flex h-100 align-items-center cursor-pointer h-100 icon-table"
              style={{ zIndex: '1' }}
              onClick={() => {
                handleAddRow(row, [...tableData])
              }}
            >
              <i className="far fa-plus" style={{ color: 'green' }}></i>
            </div>
          )}
          <div
            className="d-flex h-100 align-items-center cursor-pointer h-100 icon-table ml-1"
            style={{ zIndex: '1' }}
            onClick={() => {
              if (tableData.length !== 1) {
                handleRemoveRow(row)
              }
            }}
          >
            <i className="far fa-trash-alt" style={{ color: 'red' }}></i>
          </div>
        </div>
      ),
    })

    return ret
  }, [
    checkedHHDV,
    tableData,
    tableDetailExtendFields,
    invoiceSetting,
    formik.values.discountType,
    formik.values.currency,
    formik.values.multipleTaxRate,
    formik.values.taxType,
    formik.values.serial,
    formik.values.isTaxReduction,
    isAdjustInvoice,
    formik.values.autoCalculate,
    persistent,
  ])

  const navigateHandler = () => {
    if (isFromPOS)
      return '/hoa-don-dau-ra/hoa-don/may-tinh-tien/danh-sach-hoa-don'
    if (isDeliveryBill) return '/hoa-don-dau-ra/hoa-don/phieu-xuat-kho'
    return '/hoa-don-dau-ra/hoa-don/danh-sach-hoa-don'
  }

  return (
    <div className="w-100 h-100 d-flex flex-column ">
      <div className="d-flex align-items-center justify-content-between container-xxl mb-4">
        <label style={{ fontSize: 24, fontWeight: '700', color: 'black' }}>
          {invoiceId ? 'Sửa thông tin hóa đơn' : 'Lập hoá đơn'}
        </label>
        <CreatedInvoiceUnit
          value={formik.values.companyUnitId}
          onSelect={(val) => formik.setFieldValue('companyUnitId', val)}
        />
      </div>

      <div className="container-xxl create-invoice-cover flex-fill">
        <div className="create-invoice card card-custom">
          <div className="d-flex justify-content-center">
            <h4
              className="invoice-title font-weight-boldest"
              style={{
                letterSpacing: '0.5px',
                fontSize: 20,
                fontWeight: '700',
              }}
            >
              {formik.values.invoiceTemplateName}
              {/* HOÁ ĐƠN GIÁ TRỊ GIA TĂNG */}
            </h4>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <div
              className="d-flex justify-content-center my-3"
              style={{ width: 'fit-content' }}
            >
              <DatePicker
                className="d-flex justify-content-center"
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                minDate={
                  latestInvoiceTemplate?.date
                    ? new Date(latestInvoiceTemplate?.date)
                    : new Date(dayjs().year(), 0, 1)
                }
                customInput={
                  <div className="d-flex justify-content-center">
                    {displayDate(formik.values.date)}
                  </div>
                }
                // showIcon={true}
                todayButton={<button>Hôm nay</button>}
                // readOnly={screenAction == 'xem'}
                selected={new Date(formik.values.date)}
                disabled={isDeliveryBill && actionType !== 'create'}
                onChange={(date) => {
                  const newDate = dayjs(
                    date,
                    // 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Indochina Time)',
                  ).format('YYYY-MM-DD HH:mm:ss')
                  formik.setFieldValue('date', newDate)
                  // changeRegistrationProperty(
                  //   dayjs(
                  //     date,
                  //     'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Indochina Time)',
                  //   ).format('YYYY-MM-DD HH:mm:ss'),
                  // )
                }}
              />
            </div>
          </div>
          {isDeliveryBill ? (
            <DeliveryGeneralInfo
              formik={formik}
              currentCompany={company}
              buyerInfoExtendFields={buyerInfoExtendFields}
              templateInvoiceSerials={templateInvoiceSerials}
              actionType={actionType}
              originalInvoiceInfo={fetchedInvoiceInfo}
              invoiceTemplateId={latestInvoice?.invoiceTemplateId}
              tableData={tableData}
              setTableData={setTableData}
              isTaxReduction={formik.values.isTaxReduction}
              onCheckTaxReduction={onCheckTaxReduction}
              systemTaxRate={systemTaxRate}
              latestInvoice={latestInvoice}
              invoiceType={formik.values.invoiceType}
            />
          ) : (
            <InvoiceGeneralInfo
              formik={formik}
              currentCompany={company}
              buyerInfoExtendFields={buyerInfoExtendFields}
              templateInvoiceSerials={templateInvoiceSerials}
              actionType={actionType}
              originalInvoiceInfo={
                (actionType == ACTION_TYPE.UPDATE &&
                  fetchedInvoiceInfo?.Original) ||
                fetchedInvoiceInfo
              }
              invoiceTemplateId={latestInvoice?.invoiceTemplateId}
              tableData={tableData}
              setTableData={setTableData}
              isTaxReduction={formik.values.isTaxReduction}
              onCheckTaxReduction={onCheckTaxReduction}
              systemTaxRate={systemTaxRate}
              isFromPOS={isFromPOS}
            />
          )}

          <InvoiceTableGeneralInfo
            formik={formik}
            currencies={currencies}
            discountTypeArray={discountTypeArray}
            handleSelectDiscountType={handleSelectDiscountType}
            onChangeCheckHHDV={onChangeCheckHHDV}
            checkedHHDV={checkedHHDV}
            onChangePayment={onChangePayment}
            onToggleMulTaxRate={onToggleMulTaxRate}
            mulTaxRate={formik.values.multipleTaxRate}
            checkedPayment={formik.values.isPayment}
            autoCalculate={formik.values.autoCalculate}
            persistent={persistent}
            setPersistent={setPersistent}
            currency={currency}
            invoiceType={formik.values.invoiceType}
          />

          <Table
            dataSource={tableData}
            columns={columns}
            bordered
            checkbox
            pagination={false}
            scroll={{
              x: 1200,
            }}
          />

          <div className="d-flex flex-row justify-content-between align-items-start mt-5">
            <SelectGeneralDiscountAndTaxRate
              formik={formik}
              handleChangeGTGT={handleChangeGTGT}
              formatNumber={formatNumber}
            />
            <FinalMoneyDisplay
              formik={formik}
              partsName={partsName}
              autoCalculate={formik.values.autoCalculate}
              isAdjustInvoice={isAdjustInvoice}
            />
          </div>

          {templateInvoiceSerials?.find(
            ({ invoiceTemplateId }) =>
              invoiceTemplateId == formik.values.invoiceTemplateId,
          )?.category?.code == 'BV' && <TableHospitalInfo formik={formik} />}
          {/* <div className="d-flex flex-row align-items-center w-100 mt-5">
            <MoneyToTextDisplay formik={formik} />
          </div> */}
        </div>

        <SendInvoiceModal
          invoiceId={invoiceId || createdInvoiceId}
          invoice={formik.values}
          show={sendDraftInvoiceModalShow}
          onHide={() => setSendDraftInvoiceModalShow(false)}
        />

        <ModalConfirm
          show={showModalConfirmSaveToSendInvoice}
          setShow={setShowModalConfirmSaveToSendInvoice}
          header="Xác nhận"
          content="Bạn cần lưu dữ liệu trước khi gửi hóa đơn nháp. Bạn có muốn lưu không?"
          handleConfirm={handleConfirmSaveToSendInvoice}
        />

        <ModalConfirm
          show={showModalConfirmDuplicateName}
          setShow={setShowModalConfirmDuplicateName}
          header="Xác nhận"
          content="Hoá đơn có hàng hoá trùng tên, bạn có muốn tiếp tục không?"
          handleConfirm={handleConfirmSaveDuplicateName}
        />

        <AddCustomFieldModal
          invoiceTemplateId={formik.values.invoiceTemplateId}
          tableDetailExtendFields={tableDetailExtendFields}
          setTableDetailExtendFields={setTableDetailExtendFields}
          buyerInfoExtendFields={buyerInfoExtendFields}
          setBuyerInfoExtendFields={setBuyerInfoExtendFields}
          show={AddCustomFieldModalShow}
          onHide={() => setAddCustomFieldModalShow(false)}
          fetchExtendFields={fetchExtendFields}
        />

        <IssueInvoiceFromPosModal
          invoice={{
            ...formik.values,
            invoiceId: issueInvoiceFromPosModalInfo.invoiceId,
          }}
          onIssueSuccess={() =>
            navigate('/hoa-don-dau-ra/hoa-don/may-tinh-tien/danh-sach-hoa-don')
          }
          show={issueInvoiceFromPosModalInfo.show}
          onHide={() =>
            setIssueInvoiceFromPosModalInfo({ show: false, invoiceId: null })
          }
          onShow={() =>
            setIssueInvoiceFromPosModalInfo((prev) => ({ ...prev, show: true }))
          }
        />
      </div>
      <InvoiceFooter
        ref={invoiceFooterRef}
        buttonAddExtensionField={true}
        buttonCancel={true}
        buttonSendDraftInvoice={true}
        buttonPreview={true}
        buttonCreate={true}
        buttonSaveAndSign={true}
        buttonCreateOnClick={() => {
          submitAction = 'Save'
          // hàng hoá dịch vụ trùng tên thì thông báo
          if (hasDuplicateValueForKey(tableData, 'name')) {
            setShowModalConfirmDuplicateName(true)
            return
          }
          formik.submitForm()
        }}
        buttonSaveAndSignOnClick={() => {
          setInforActionSaveAndSign({ disabled: true })
          submitAction = 'SaveAndSign'

          if (hasDuplicateValueForKey(tableData, 'name')) {
            setShowModalConfirmDuplicateName(true)
            return
          }
          formik.submitForm()
        }}
        buttonAddExtensionFieldOnClick={() => {
          setAddCustomFieldModalShow(!AddCustomFieldModalShow)
        }}
        buttonSendDraftInvoiceOnClick={() => {
          setShowModalConfirmSaveToSendInvoice(true)
        }}
        buttonCancelOnClick={() => {
          navigate(navigateHandler())
        }}
        //huy
        buttonPreviewOnClick={() => {
          submitAction = 'Preview'
          formik.submitForm()
        }}
        inforActionSaveAndSign={inforActionSaveAndSign}
        setInforActionSaveAndSign={setInforActionSaveAndSign}
      />
    </div>
  )
}

export default CreateInvoice
