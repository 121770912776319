import Empty from 'general/components/Empty'
import AppResource from 'general/constants/AppResource'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'
import ProcessHomeScreen from './screens/ProcessHomeScreen'

Process.propTypes = {}

function Process(props) {
  // MARK: --- Params ---
  const { t } = useTranslation()

  return (
    <div className="">
      <Routes>
        {/* <Route path="" element={<Navigate to="quy-trinh-nghiep-vu" />} /> */}
        {/* <Route path="" element={<ProcessHomeScreen />} /> */}

        <Route
          path="*"
          element={
            <Container fluid className="h-100 d-flex">
              <Row className="justify-content-center align-items-center flex-grow-1">
                <Empty
                  text={t('PageNotFound')}
                  buttonText={t('Refresh')}
                  visible={false}
                  imageEmpty={AppResource.icons.icEmptyBox}
                />
              </Row>
            </Container>
          }
        />
      </Routes>
    </div>
  )
}

export default Process
