import axiosClient from './axiosClient'

const TvanHandleApi = {
  getListInvoice: (params) => {
    const url = '/tvanHandle/listInvoices'
    return axiosClient.get(url, { params })
  },

  moveToSendError: (params) => {
    const url = '/tvanHandle/moveToSendError'
    return axiosClient.post(url, params)
  },

  updateMessage: (params) => {
    const url = '/tvanHandle/updateMessage'
    return axiosClient.post(url, params)
  },

  tvanCheckMessages: (params) => {
    const url = '/tvanHandle/checkMessages'
    return axiosClient.post(url, { params })
  },
}

export default TvanHandleApi
