import CustomButton from 'general/components/Button/CustomButton'
import PropTypes from 'prop-types'
import './styles.scss'
import Utils from 'general/utils/Utils'
import _ from 'lodash'

TicketFooter.propTypes = {
  buttonAddExtensionField: PropTypes.bool,
  buttonSendDraftInvoice: PropTypes.bool,
  buttonPreview: PropTypes.bool,
  buttonCancel: PropTypes.bool,
  buttonSaveAndSign: PropTypes.bool,
  buttonCreate: PropTypes.bool,
  buttonPrint: PropTypes.bool,
  buttonAddExtensionFieldOnClick: PropTypes.func,
  buttonSendDraftInvoiceOnClick: PropTypes.func,
  buttonPreviewOnClick: PropTypes.func,
  buttonCancelOnClick: PropTypes.func,
  buttonSaveAndSignOnClick: PropTypes.func,
  buttonCreateOnClick: PropTypes.func,
  buttonPrintOnClick: PropTypes.func,
}

TicketFooter.defaultProps = {
  buttonAddExtensionField: false,
  buttonSendDraftInvoice: false,
  buttonPreview: false,
  buttonCancel: false,
  buttonSaveAndSign: false,
  buttonCreate: false,
  buttonPrint: false,
}
function TicketFooter(props) {
  const {
    buttonPrintOnClick,
    buttonCancelOnClick,
    buttonSaveOnClick,
    buttonSaveAndSignOnClick,
    totalTicketQuantity,
    totalTicketPrice,
    inforSaveAndSign,
  } = props

  return (
    <div>
      {_.isNumber(totalTicketQuantity) &&
        _.isNumber(totalTicketPrice) &&
        totalTicketQuantity > 0 && (
          <div className="ticketTotalPrice">
            <span style={{ fontWeight: '500' }}>
              Số lượng vé:{' '}
              <span style={{ fontWeight: '700', fontSize: '16px' }}>
                {totalTicketQuantity && Utils.formatNumber(totalTicketQuantity)}
              </span>
            </span>

            <span style={{ fontWeight: '500' }}>
              Tổng tiền thanh toán:{' '}
              <span style={{ fontWeight: '700', fontSize: '16px' }}>
                {totalTicketPrice && Utils.formatNumber(totalTicketPrice)}
              </span>
            </span>
          </div>
        )}
      <div className="ticketFooter">
        <div
          className="d-flex flex-row justify-content-start"
          style={{ gap: '10px' }}
        >
          Đã xuất trong ngày
        </div>
        <div
          className="d-flex flex-row justify-content-end"
          style={{ gap: '10px' }}
        >
          <CustomButton
            text="Huỷ bỏ"
            backgroundColor="white"
            // showBorder={false}
            color="#041847"
            width="120px"
            borderColor="#ccc"
            onClick={buttonCancelOnClick}
            height="36px"
          />
          <CustomButton
            text="Lưu nháp"
            backgroundColor="#1877f2"
            showBorder={false}
            color="white"
            width="120px"
            onClick={buttonSaveOnClick}
            height="36px"
          />
          <CustomButton
            disabled={inforSaveAndSign.disabled}
            loading={inforSaveAndSign.disabled}
            text="Xuất vé"
            backgroundColor="#28A745"
            showBorder={false}
            color="white"
            width="120px"
            onClick={buttonSaveAndSignOnClick}
            height="36px"
          />
        </div>
      </div>
    </div>
  )
}

export default TicketFooter
