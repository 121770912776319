import CustomDataTable from 'general/components/CustomDataTable'
import { useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import { shallowEqual } from 'react-redux'

export const InvoiceTable = (props) => {
  const {
    dataSource,
    isLoading,
    defaultColumns,
    filterParams,
    setFilterParams,
  } = props
  const { selectedInvoices } = useAppSelector(
    (state) => state.invoice,
    shallowEqual,
  )

  return (
    <>
      <CustomDataTable
        columns={defaultColumns}
        dataSource={dataSource?.tableData}
        isLoading={isLoading}
        isClickable
        // handleDoubleClickRow={(row) => viewPdfInvoice(row)}
        pagination={{
          total: dataSource?.total,
          rowsPerPage: filterParams.limit,
          currentPage: filterParams.page,
          onChangePage: (page) => {
            setFilterParams({
              page,
            })
          },
          onChangeRowsPerPage: (limit) => {
            setFilterParams({
              limit,
              page: 1,
            })
          },
        }}
        isSelectable
        selectedRows={{
          //   clearSelectedRows: toggledClearRows,
          //   handleSelectedRows: ({ selectedRows }) =>
          //     dispatch(setSelectedInvoices(selectedRows)),
          selectableRowSelected: (row) => _.includes(selectedInvoices, row),
        }}
      />
    </>
  )
}
