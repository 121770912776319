import errAnnouceApi from 'api/errAnnouceApi'
import { setAppNotInstalledToolNotiModalShow } from 'app/appSlice'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import { default as ToastHelper } from 'general/helpers/ToastHelper'
import { default as Utils } from 'general/utils/Utils'

export const updateErrAnnouce = async (
  submitAction,
  tableData,
  values,
  dispatch,
  setAppSpinning,
  navigate,
  currentAccount,
  signingConfig,
  setInforActionSaveAndSign,
) => {
  try {
    const res = await errAnnouceApi.update({
      ...values,
      ticketsInfo: tableData,
      invoiceType: 'TICKET',
    })
    if (res?.result === 'success') {
      ToastHelper.showSuccess('Cập nhật thông báo nháp thành công')
      // navigate('/hoa-don-dau-ra/tem-ve/xu-ly-ve/thong-bao-sai-sot')
      switch (submitAction) {
        case 'Save': {
          navigate('/hoa-don-dau-ra/tem-ve/xu-ly-ve/thong-bao-sai-sot')
          break
        }
        case 'SaveAndSign': {
          let errorAnnoucementId = values.errorAnnoucementId

          let { signingMethod } = signingConfig
          if (signingMethod == 'HSM') {
            await errAnnouceApi.hsmSignErrAnnouce({
              errorAnnoucementId,
            })

            ToastHelper.showSuccess('Ký thông báo sai sót thành công')
            navigate('/hoa-don-dau-ra/tem-ve/xu-ly-ve/thong-bao-sai-sot')
          } else {
            let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
            const urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},message300,${errorAnnoucementId},${currentAccount?.taxCode}`
            Utils.openToolSignErrAnnouce(urlSchema, accessToken, () =>
              dispatch(setAppNotInstalledToolNotiModalShow(true)),
            )
          }

          break
        }
      }
    }
  } catch (err) {
    console.log(err)
  } finally {
    dispatch(setAppSpinning(false))
  }
}
