import DropdownHeaderAccountInfo from 'features/Others/components/DropdownHeaderAccountInfo'
import DropdownHeaderHelpMenuInfo from 'features/Others/components/DropdownHeaderHelpMenuInfo'
import DropdownHeaderMenuInfo from 'features/Others/components/DropdownHeaderMenuInfo'
import DropdownHeaderNotification from 'features/Others/components/DropdownHeaderNotification'
import KTBSDropdown, {
  KTBSDropdowAutoCloseBehavior,
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown'
import { KTTooltipPositions } from 'general/components/OtherKeenComponents/KTTooltip'
import KTTooltip from 'general/components/eInvoiceComponents/KTTooltip'
import UserHelper from 'general/helpers/UserHelper'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import useRouter from 'hooks/useRouter'
import { useTranslation } from 'react-i18next'

function TopBarHeader() {
  const { t } = useTranslation()
  const currentLoggedInUser = useAppSelector(
    (state) => state.auth?.currentAccount,
  )
  const dataCart = useAppSelector((state) => state?.cart.cartItems)
  const { navigate } = useRouter()

  return (
    <div className="topbar">
      {/* Top bar */}
      <div className="topbar">
        {/* <div className="topbar-item">
          <KTTooltip text={t('Procedure')} position={KTTooltipPositions.auto}>
            <div
              className="btn btn-icon btn-clean btn-lg mr-1"
              onClick={() => {
                navigate('/hoa-don-dau-ra/quy-trinh-nghiep-vu')
              }}
            >
              <i className="fa-regular fa-lightbulb-on" />
            </div>
          </KTTooltip>
        </div> */}
        <KTBSDropdown
          contentEl={<DropdownHeaderNotification />}
          toggleElement={
            <div className="topbar-item">
              <KTTooltip
                text={t('Notification')}
                position={KTTooltipPositions.auto}
              >
                <div className="btn btn-icon btn-clean btn-lg mr-1">
                  <i className="fa-regular fa-bell" />
                </div>
              </KTTooltip>
            </div>
          }
          alignment={KTBSDropdownAlignments.end}
          autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
        />
        <div className="topbar-item">
          <KTTooltip text={t('FAQ')} position={KTTooltipPositions.auto}>
            <div className="btn btn-icon btn-clean btn-lg mr-1">
              <i className="fa-regular fa-circle-question" />
            </div>
          </KTTooltip>
        </div>
        <KTBSDropdown
          contentEl={<DropdownHeaderHelpMenuInfo />}
          toggleElement={
            <div className="topbar-item">
              <KTTooltip
                text={t('UserManual')}
                position={KTTooltipPositions.auto}
              >
                <div className="btn btn-icon btn-clean btn-lg mr-1">
                  <i className="fa-regular fa-comment-dots" />
                </div>
              </KTTooltip>
            </div>
          }
          alignment={KTBSDropdownAlignments.end}
          autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
        />
        <KTBSDropdown
          contentEl={<DropdownHeaderMenuInfo />}
          toggleElement={
            <div className="topbar-item">
              <KTTooltip text={t('System')} position={KTTooltipPositions.auto}>
                <div className="btn btn-icon btn-clean btn-lg mr-1">
                  <i className="fa-regular fa-grid-2" />
                </div>
              </KTTooltip>
            </div>
          }
          alignment={KTBSDropdownAlignments.end}
          autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
        />

        <KTBSDropdown
          // contentEl={<DropdownHeaderMenuInfo />}
          toggleElement={
            <div className="topbar-item">
              <KTTooltip
                text={t('Giỏ hàng')}
                position={KTTooltipPositions.auto}
              >
                <div
                  variant=""
                  className="btn btn-icon btn-clean btn-lg mr-1"
                  onClick={() => {
                    navigate('/hoa-don-dau-ra/gio-hang')
                  }}
                >
                  <i className=" fa-regular fa-cart-shopping"></i>
                  {dataCart?.length > 0 && (
                    <div
                      className="position-absolute d-flex align-items-center justify-content-center"
                      style={{
                        backgroundColor: '#e92e4e',
                        fontSize: '9px',
                        fontWeight: '600',
                        lineHeight: '10px',
                        height: '15px',
                        width: '15px',
                        borderRadius: '50%',
                        top: '0px',
                        left: '20px',
                      }}
                    >
                      <div className="m-auto text-white">
                        {dataCart?.length > 9 ? '9+' : dataCart?.length}
                      </div>
                    </div>
                  )}
                </div>
              </KTTooltip>
            </div>
          }
          alignment={KTBSDropdownAlignments.end}
          autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
        />

        {/* border */}
        <div className="border-icon"></div>

        <KTBSDropdown
          contentEl={<DropdownHeaderAccountInfo />}
          toggleElement={
            <div className="topbar-item ml-2">
              <KTTooltip text={t('Account')} position={KTTooltipPositions.auto}>
                <div className="btn btn-icon btn-clean d-flex align-items-center btn-lg">
                  <div className="symbol symbol-circle symbol-35 bg-white overflow-hidden">
                    <div className="symbol-label bg-primary">
                      <img
                        className="w-100 h-100 object-fit-cover"
                        loading="lazy"
                        alt="avatar"
                        src={Utils.getFullUrl(
                          UserHelper.getAvatar(currentLoggedInUser),
                        )}
                      />
                    </div>
                  </div>
                </div>
              </KTTooltip>
            </div>
          }
          alignment={KTBSDropdownAlignments.end}
          autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
        />
      </div>
    </div>
  )
}

export default TopBarHeader
