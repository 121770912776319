export default {
  phoneNumber: /^((\+84|0[235789])[0-9]{6,9}|1[89]00[0-9]{6})$/,
  fax: /^(?:\d{10}|\d{10}-\d{3})$/,
  taxCode: /^\d{10}(-\d{3})?$/,
  website:
    /((((http|ftp|https):\/{2})|www.)+(([0-9a-z_-]+\.)+(aero|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mn|mn|mo|mp|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|nom|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ra|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw|arpa)(:[0-9]+)?((\/([~0-9a-zA-Z\#\+\%@\.\/_-]+))?(\?[0-9a-zA-Z\+\%@\/&\[\];=_-]+)?)?))\b/,
  noSpecialChar: /^[^\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/,
  mstRegex: /^(\d{10})(?:-(\d{3}))?$/,
}
export const REGEX_MST =
  /^([0-9]{10}|[0-9]{13}|[0-9]{10}[-][0-9]{3}|[a-zA-Z0-9.]{8})$/g
export const REGEX_CMND_CCCD = /^([0-9]{9}|[0-9]{12})$/g
export const REGEX_EMAIL_MAX = /^[\w\.]{3,32}@([\w\-]+\.)+[\w]{2,4}$/
export const regexLong = {
  REGEX_PHONE_NUMBER:
    /^((0)|(84))((3[2-9])|(5[25689])|(7[06-9])|(8[1-9])|(9[0-46-9]))(\d){7}$/,
  REGEX_LIST_EMAIL:
    /^(?:[a-zA-Z0-9._%+-]+@gmail\.com\s*;\s*)*[a-zA-Z0-9._%+-]+@gmail\.com\s*$/,
}
export const REGEX_IDENTIFY = {
  REGEX_PASSPORT: /^[A-Za-z0-9]{8,9}$/,
  REGEX_CMND: /^[0-9]{9}$/,
  REGEX_CCCD: /^[0-9]{12}$/,
  REGEX: /^([A-Za-z0-9]{8}|[0-9]{9}|[0-9]{12})$/,
}

export const REGEX_TVAN_MESSAGE = /V0312303803([A-F0-9]{32})/
