import { Space, Tag, Tooltip, Typography } from 'antd'
import { PERIOD } from 'general/constants/AppConstants'
import SelectOptions from 'general/constants/SelectOptions'
import Utils from 'general/utils/Utils'
import {
  every,
  filter,
  find,
  has,
  isArray,
  isEmpty,
  isNull,
  map,
  upperFirst,
} from 'lodash'
import { Fragment, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

/**
 *
 * @param {{
 *  defaultValues: Array<FilterDefaultValueProp>,
 *  values: Object,
 *  handleCloseTag: (formName: string, value: any) => void,
 *  canSelectTime: boolean,
 * }} params
 */
export default function FilteredListContainer({
  defaultValues,
  values,
  handleCloseTag,
  canSelectTime,
}) {
  const { t } = useTranslation()
  const titleText = useMemo(
    () =>
      `Từ ${
        canSelectTime
          ? values.startDate?.format('L HH:mm')
          : values.startDate?.format('L')
      } đến ${
        canSelectTime
          ? values.endDate?.format('L HH:mm')
          : values.endDate?.format('L')
      }`,
    [values.endDate, values.startDate, canSelectTime],
  )
  const renderTag = useCallback(
    () =>
      map(defaultValues, ({ formName, alias, options, separator }) => {
        const hasMultiple = isArray(formName)
        if (hasMultiple) {
          const doesntHasOptions =
            filter(formName, (name) => !has(values, name)).length > 0
          const valuesHasNull = every(formName, (name) => isNull(values[name]))
          if (doesntHasOptions || valuesHasNull) return null

          const val = map(formName, (name, idx) =>
            isNull(values[name]) && idx === 0
              ? 'Đầu'
              : isNull(values[name]) && idx === 1
                ? 'Cuối'
                : values[name],
          )
          return (
            <Fragment key={'pill-' + formName}>
              <Tag
                prefixCls="summary-filter"
                closable
                onClose={() => handleCloseTag(formName, null)}
                bordered={false}
                closeIcon={
                  <i className="fa-solid fa-times text-danger fa-lg"></i>
                }
                className="d-flex justify-content-between align-items-center badge badge-light text-info gap-2 w-100"
              >
                <Typography.Text strong type="secondary" className="fs-6">
                  {upperFirst(alias)}
                </Typography.Text>
                {val.join(separator ? Utils.cn(' ', separator, ' ') : ' - ')}
              </Tag>
            </Fragment>
          )
        } else {
          const formikCurrentValues = values[formName]
          const doesntHasOptions = !has(values, formName)
          const hasNull = isNull(formikCurrentValues)
          if (doesntHasOptions || hasNull) return null
          const isMultiple = isArray(formikCurrentValues)
          //Check if value is an array then find label of each value
          let label = isMultiple
            ? map(
                formikCurrentValues,
                (val) => find(options, { value: val })?.label,
              ).join(', ')
            : find(options, {
                value: formikCurrentValues,
              })?.label
          if (!options && isEmpty(label)) label = values[formName]
          if (isEmpty(label)) return null
          return (
            <Fragment key={'pill-' + alias}>
              <Tag
                prefixCls="summary-filter"
                closable
                onClose={() => handleCloseTag(formName, null)}
                bordered={false}
                closeIcon={
                  <i className="fa-solid fa-times text-danger fa-lg"></i>
                }
                className="d-flex justify-content-between align-items-center badge badge-light text-info gap-2 w-100"
              >
                <Typography.Text strong type="secondary" className="fs-6">
                  {upperFirst(alias)}:
                </Typography.Text>
                <Typography.Text
                  style={{
                    color: 'inherit',
                    fontSize: '12px',
                    maxWidth: '12rem',
                  }}
                  ellipsis={{
                    tooltip: {
                      title: label,
                      placement: 'topRight',
                    },
                  }}
                >
                  {label}
                </Typography.Text>
              </Tag>
            </Fragment>
          )
        }
      }),
    [defaultValues, handleCloseTag, values],
  )

  return (
    <>
      <Space wrap align="center" size={'small'}>
        <div className="d-flex align-items-center justify-content-center">
          <Tag
            prefixCls="summary-filter"
            bordered={false}
            className="d-flex justify-content-between align-items-center badge badge-light text-info gap-2 w-100"
          >
            <Typography.Text strong type="secondary" className="fs-6">
              Ngày HĐ:
            </Typography.Text>
            <Tooltip title={titleText}>
              {
                find(SelectOptions.periodFilterOptions, {
                  value: values?.period,
                })?.label
              }
            </Tooltip>
          </Tag>
          {renderTag()}
        </div>
      </Space>
    </>
  )
}
