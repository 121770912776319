import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import accountApi from 'api/accountApi'
import authApi from 'api/authApi'
import { updateAxiosAccessToken } from 'api/axiosClient'
import AppConfigs from 'general/constants/AppConfigs'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import wsHelperInstance from 'general/helpers/WebSocketHelper'
import { useAppSelector } from 'hooks/useRedux'
import { shallowEqual } from 'react-redux'

const initialResourceStatistics = {
  quantity: 0,
  dateExpired: '',
}

// MARK: --- Thunks ---
export const thunkSignIn = createAsyncThunk('auth/sign-in', async (params) => {
  const res = await authApi.signIn(params)
  return res
})

export const thunkLogOut = createAsyncThunk('auth/log-out', async (params) => {
  const res = await authApi.logOut(params)
  return res
})

export const thunkGetAccountProfile = createAsyncThunk(
  'account/profile',
  async (params) => {
    const res = await accountApi.getAccountProfile(params)
    return res
  },
)

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isSigningIn: false,
    signedIn: false,
    currentAccount: {},
    currentCompany: {},
    activeInvoice: {},
    resource: {
      ticket: initialResourceStatistics,
      invoice: initialResourceStatistics,
      tncn: initialResourceStatistics,
    },
    isOnlineStatus: false,
  },
  reducers: {
    setOnlineStatus: (state, action) => {
      state.isOnlineStatus = action.payload
    },
    setActiveInvoice: (state, action) => {
      state.activeInvoice = action.payload
    },
  },
  extraReducers: (builder) => {
    // sign in
    builder.addCase(thunkSignIn.pending, (state) => {
      state.isSigningIn = true
    })
    builder.addCase(thunkSignIn.rejected, (state) => {
      state.isSigningIn = false
    })
    builder.addCase(thunkSignIn.fulfilled, (state, action) => {
      state.isSigningIn = false
      state.signedIn = true
      const {
        account,
        accessToken,
        company,
        activeInvoice,
        resource,
        qlbhAccessToken,
        accessTokenExpired,
      } = action.payload
      if (account && accessToken) {
        localStorage.setItem(PreferenceKeys.accessToken, accessToken)
        localStorage.setItem(PreferenceKeys.qlbhAccessToken, qlbhAccessToken)
        localStorage.setItem(
          PreferenceKeys.accessTokenExpired,
          accessTokenExpired,
        )
        localStorage.setItem(
          PreferenceKeys.savedLoginFullname,
          account.fullname,
        )
        Object.keys(localStorage).map((key) => {
          if (key.startsWith('gFilter') || key.startsWith('filter_param')) {
            localStorage.removeItem(key)
          }
        })
        state.currentAccount = account
        state.currentCompany = company
        state.activeInvoice = activeInvoice
        state.resource = resource
        updateAxiosAccessToken(accessToken)
      }

      const { email } = account
      if (email && accessToken) {
        wsHelperInstance.loginByToken(email, accessToken)
      }
    })

    // log out
    builder.addCase(thunkLogOut.fulfilled, (state, action) => {
      state.currentAccount = {}
      state.currentCompany = {}
      state.signedIn = false
      const { status } = action.payload
      if (status === 204) {
        // Tạm đóng thao tác của đăng xuất=> bởi vì đăng xuất chậm và nếu server chết ko đăng xuất được
        // localStorage.removeItem(PreferenceKeys.accessToken)
        // localStorage.removeItem(PreferenceKeys.qlbhAccessToken)
        // localStorage.removeItem(PreferenceKeys.accessTokenExpired)
        // Object.keys(localStorage).map((key) => {
        //   if (
        //     key.startsWith(AppConfigs.packageName) ||
        //     key.startsWith('gFilter')
        //   ) {
        //     localStorage.removeItem(key)
        //   }
        // })
        // state.currentAccount = {}
        // state.currentCompany = {}
        // state.signedIn = false
        // window.location.href = '/dang-nhap'
      }
    })

    // get account profile
    builder.addCase(thunkGetAccountProfile.fulfilled, (state, action) => {
      const { account, company, activeInvoice, resource } = action.payload
      if (account && company && activeInvoice) {
        state.currentAccount = account
        state.currentCompany = company
        state.signedIn = true
        state.activeInvoice = activeInvoice
        state.resource = resource
        const { accessToken, qlbhAccessToken, accessTokenExpired } = account
        if (accessToken) {
          localStorage.setItem(PreferenceKeys.accessToken, accessToken)
          localStorage.setItem(PreferenceKeys.qlbhAccessToken, qlbhAccessToken)
          localStorage.setItem(
            PreferenceKeys.accessTokenExpired,
            accessTokenExpired,
          )
          updateAxiosAccessToken(accessToken)
        }

        if (account?.email) {
          wsHelperInstance.loginByToken(
            account.email,
            accessToken || localStorage.getItem(PreferenceKeys.accessToken),
          )
        }
      }
    })
  },
})

const { reducer, actions } = authSlice
export const { setOnlineStatus, setActiveInvoice } = actions
export const useAuth = () => useAppSelector((s) => s.auth, shallowEqual)
export default reducer
