import { useTranslation } from 'react-i18next'
import { TableCustom, ButtonSave } from '../../../TaxDeduction/styled'
import {
  TitleRight,
  ContainerSearch,
  ContainerTable,
  DivRight,
  DivListFunction,
  ContainerFunction,
  FormOutlinedIcon,
  DivFunction,
  DeleteOutlinedIcon,
  CopyOutlinedIcon,
  SpanNotYetReleased,
  SpanNotYetReleasedTd,
  SendOutlinedIcon,
  DownloadOutlinedIcon,
  FilePdfOutlinedIcon,
  FileExcelOutlinedIcon,
  PrinterOutlinedIcon,
  StopOutlinedIcon,
} from '../../styled'
// import AppResource from 'general/constants/AppResource'
// import { downloadZip, embedPDF, extractFields } from 'general/utils/Utils'
import { useEffect, useMemo, useState } from 'react'
import { Dropdown, Empty, Menu, Space } from 'antd'
import { PlusOutlined, SendOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import {
  thunkCancelDeductionDocuments,
  thunkExportFileDeductionDocuments,
  thunkFindTaxDeductionDocuments,
  thunkRemoveTaxDeductionDocumentsDefault,
  thunkUpdateSignTaxDeductionDocuments,
} from 'features/TaxDeductionDocuments/taxDeductionDocumentsSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from 'hooks/useRedux'
import ToastHelper from 'general/helpers/ToastHelper'
import AlertTaxDectionDocumentsUpdate from '../Alert'
import Utils, { padNumber, removeItemByKeys } from 'general/utils/Utils'
import { DIGITAL_SIGN_METHOD } from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import { thunkGetAccountProfile } from 'app/authSlice'
import { thunkGetCompanyProfile } from 'features/System/systemSlice'
import { saveAs } from 'file-saver'
import { at } from 'lodash'
function SearchTaxDeductionDocuments() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [dataSource, setDataSource] = useState([])
  const [dataActive, setDataActive] = useState({})
  const [visibleCancel, setVisibleCancel] = useState(null)
  const [inforItemTable, setInforItemTable] = useState({})
  const [openModalAlertTemplateRemove, setOpenModalAlertTemplateRemove] =
    useState(false)
  const [typeSign, setTypeSign] = useState()
  const [auth, setAuth] = useState({})
  const [panningDataSource, setPanningDataSource] = useState({
    page: 1,
    limit: 20,
    totalItems: 0,
    totalPages: 0,
  })

  const findDataSource = async () => {
    try {
      const data = unwrapResult(
        await dispatch(
          thunkFindTaxDeductionDocuments({
            status: 1,
            page: panningDataSource.page,
            limit: panningDataSource.limit,
          }),
        ),
      )
      setDataSource(data.items)
      setPanningDataSource({
        ...panningDataSource,
        totalItems: data.totalItems,
        totalPages: data.totalPages,
      })
    } catch (error) {}
  }

  const remove = async () => {
    const data = unwrapResult(
      await dispatch(thunkRemoveTaxDeductionDocumentsDefault(dataActive)),
    )
    if (data.message === 'success') {
      setOpenModalAlertTemplateRemove(false)
      setDataActive({})
      ToastHelper.showSuccess(data?.data)
      findDataSource()
    } else if (data.message === 'error') {
      ToastHelper.showError(data?.data)
    }
  }

  const cancel = async () => {
    const data = unwrapResult(
      await dispatch(thunkCancelDeductionDocuments(dataActive)),
    )
    if (data.message === 'success') {
      setVisibleCancel(false)
      setDataActive({})
      ToastHelper.showSuccess(data?.data)
      findDataSource()
    } else if (data.message === 'error') {
      ToastHelper.showError(data?.data)
    }
  }

  const saveData = async (typeSign, taxCode, templatePaperId) => {
    try {
      const dataSave = unwrapResult(
        await dispatch(
          thunkUpdateSignTaxDeductionDocuments({
            templatePaperId,
            status: 1,
            typeSign,
          }),
        ),
      )
      if (dataSave?.message === 'success') {
        if (typeSign === DIGITAL_SIGN_METHOD.HSM) {
          if (dataSave?.typeSignError) {
            ToastHelper.showWarning(`${dataSave?.data}`)
          } else {
            ToastHelper.showSuccess(
              `${t('taxDeductionDocuments.messageSuscessSaveTemplate')}`,
            )
          }

          findDataSource()
        } else if (typeSign === DIGITAL_SIGN_METHOD.USB) {
          ToastHelper.showSuccess(
            `${t('taxDeductionDocuments.messageSuscessSaveTemplate')}`,
          )
          let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
          let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${taxCode},${templatePaperId},TemplatePaper`
          console.log('urlSchema', urlSchema)
          Utils.openToolSignTemplatePaper(urlSchema, accessToken)
          // findDataSource()
        } else {
        }
      } else if (dataSave?.message === 'error') {
        ToastHelper.showError(dataSave?.data)
      }
    } catch (error) {
    } finally {
      setDataInforItemTable(
        inforItemTable,
        'exportFileSign',
        templatePaperId,
        false,
      )
    }
  }

  useEffect(() => {
    findDataSource()
  }, [panningDataSource.page, panningDataSource.limit])

  useMemo(() => {
    const fetchData = async () => {
      const data = await dispatch(thunkGetAccountProfile())
      if (data?.payload) {
        const dataCompany = await dispatch(
          thunkGetCompanyProfile({
            companyId: data?.payload?.company?.companyId,
          }),
        )
        setTypeSign(dataCompany?.payload?.activeSigningMethod)
      }
      setAuth(data?.payload?.company)
    }
    fetchData()
  }, [dispatch])

  const exportFile = async (typeFile, companyId, templatePaperId, view) => {
    const dataFile = await dispatch(
      thunkExportFileDeductionDocuments({
        typeFile,
        companyId,
        templatePaperId,
      }),
    )
    if (typeFile == 'XML') {
      setDataInforItemTable(
        inforItemTable,
        'exportFileXML',
        templatePaperId,
        false,
      )
    } else {
      if (view) {
        setDataInforItemTable(
          inforItemTable,
          'exportFilePrin',
          templatePaperId,
          false,
        )
      } else {
        setDataInforItemTable(
          inforItemTable,
          'exportFilePDF',
          templatePaperId,
          false,
        )
      }
    }

    if (dataFile?.payload?.data && dataFile.payload.fileName) {
      const { data, fileName } = dataFile.payload
      if (data.type === 'Buffer') {
        const byteArray = new Uint8Array(data.data)
        const blob = new Blob([byteArray], {
          type: typeFile == 'XML' ? 'application/xml' : 'application/pdf',
        })
        if (view) {
          const url = URL.createObjectURL(blob)
          window.open(url, '_blank')
        } else {
          saveAs(blob, fileName)
        }
      } else {
        ToastHelper.showError('Invalid buffer data')
      }
    }
  }

  const setDataInforItemTable = (inforItemTable, nameKey, key, value) => {
    const inforItemTableTemp = {
      ...inforItemTable,
      [nameKey]: {
        ...inforItemTable?.[nameKey],
        [key]: value, // Dùng dynamic key
      },
    }
    console.log(inforItemTableTemp)
    setInforItemTable(inforItemTableTemp)
  }

  const createMenu = (templatePaperId, companyId) => (
    <Menu>
      <Menu.Item
        disabled={inforItemTable?.exportFilePDF?.[templatePaperId]}
        key="1"
        onClick={() => {
          setDataInforItemTable(
            inforItemTable,
            'exportFilePDF',
            templatePaperId,
            true,
          )
          exportFile('PDF', companyId, templatePaperId)
        }}
      >
        <b>
          <FilePdfOutlinedIcon
            style={{
              color: 'red',
              marginRight: '10px',
              position: 'relative',
              top: '-2px',
              display: 'inline',
            }}
          />
          PDF
        </b>
      </Menu.Item>
      <Menu.Item
        disabled={inforItemTable?.exportFileXML?.[templatePaperId]}
        key="2"
        onClick={() => {
          setDataInforItemTable(
            inforItemTable,
            'exportFileXML',
            templatePaperId,
            true,
          )
          exportFile('XML', companyId, templatePaperId)
        }}
      >
        <b>
          <FileExcelOutlinedIcon
            style={{
              color: '#304ffd',
              marginRight: '10px',
              position: 'relative',
              top: '-2px',
              display: 'inline',
            }}
          />
          XML
        </b>
      </Menu.Item>
    </Menu>
  )

  const columns = [
    {
      title: t('taxDeductionDocuments.year'),
      width: 50,
      className: 'center-header',
      render: (text, record, index) => (
        <div className="center-cell">{record?.symbolYear}</div>
      ),
    },
    {
      title: t('taxDeductionDocuments.template'),
      width: 100,
      className: 'center-header',
      render: (text, record, index) => (
        <div className="left-cell">{record.templatePaperName}</div>
      ),
    },
    {
      title: t('taxDeductionDocuments.formNumber'),
      width: 100,
      className: 'center-header',
      render: (text, record, index) => (
        <div className="left-cell">{record.formNumber}</div>
      ),
    },
    {
      title: t('taxDeductionDocuments.code'),
      width: 100,
      className: 'center-header',
      render: (text, record, index) => (
        <div className="left-cell">{`${record.symbolCode}/${record.symbolYear}/${record.symbolType}`}</div>
      ),
    },
    {
      title: t('taxDeductionDocuments.nextDocumentNumber'),
      width: 100,
      className: 'center-header',
      render: (text, record, index) => (
        <div className="left-cell">
          {padNumber(record.documentCount + 1, 7)}
        </div>
      ),
    },
    {
      title: t('taxDeductionDocuments.status'),
      width: 100,
      className: 'center-header',
      render: (text, record, index) => {
        let listFunction = [
          {
            key: 'remove',
            title: t('taxDeductionDocuments.remove'),
            icon: (
              <DeleteOutlinedIcon
                display="inherit"
                onClick={() => {
                  setDataActive({
                    templatePaperId: record.templatePaperId,
                    companyId: record.companyId,
                  })
                  setOpenModalAlertTemplateRemove(true)
                }}
              />
            ),
          },

          {
            key: 'photo',
            title: t('taxDeductionDocuments.in'),
            icon: (
              <PrinterOutlinedIcon
                disabled={
                  inforItemTable?.exportFilePrin?.[record.templatePaperId]
                }
                display="inherit"
                onClick={() => {
                  setDataInforItemTable(
                    inforItemTable,
                    'exportFilePrin',
                    record.templatePaperId,
                    true,
                  )
                  exportFile('PDF', record.companyId, record.templatePaperId, 1)
                }}
              />
            ),
          },

          {
            key: 'copy',
            title: t('taxDeductionDocuments.copy'),
            icon: (
              <CopyOutlinedIcon
                display="inherit"
                onClick={() => {
                  navigate(
                    `/hoa-don-dau-ra/chung-tu-tncn/thiet-lap/mau/nhan-ban/${record.templatePaperId}`,
                  )
                }}
              />
            ),
          },
          {
            key: 'edit',
            title: t('taxDeductionDocuments.editt'),
            icon: (
              <FormOutlinedIcon
                display="inherit"
                onClick={() => {
                  navigate(
                    `/hoa-don-dau-ra/chung-tu-tncn/thiet-lap/mau/sua/${record.templatePaperId}`,
                  )
                }}
              />
            ),
          },
          {
            key: 'cancelDocuments',
            title: t('taxDeductionDocuments.cancelT'),
            icon: (
              <StopOutlinedIcon
                color="red !important"
                display="inherit"
                onClick={() => {
                  setDataActive({
                    templatePaperId: record.templatePaperId,
                  })
                  setVisibleCancel(true)
                }}
              />
            ),
          },
          {
            key: 'export',
            title: t('taxDeductionDocuments.exportTemplate'),
            icon: (
              <Space direction="vertical">
                <Space wrap>
                  <Dropdown
                    overlay={createMenu(
                      record.templatePaperId,
                      record.companyId,
                    )}
                    placement="bottomLeft"
                  >
                    <DownloadOutlinedIcon
                      display="inherit"
                      onClick={() => {}}
                    />
                  </Dropdown>
                </Space>
              </Space>
            ),
          },
          {
            key: 'release',
            title: t('taxDeductionDocuments.pt'),
            icon: (
              <SendOutlinedIcon
                disabled={
                  inforItemTable?.exportFileSign?.[record.templatePaperId]
                }
                color="#007aff !important"
                display="inherit"
                onClick={() => {
                  setDataInforItemTable(
                    inforItemTable,
                    'exportFileSign',
                    record.templatePaperId,
                    true,
                  )
                  saveData(typeSign, auth?.taxCode, record.templatePaperId)
                }}
              />
            ),
          },
        ]
        if (record.status === 1) {
          listFunction = removeItemByKeys(
            [...listFunction],
            ['edit', 'remove', 'release'],
          )
        } else if (record.status === 0) {
          listFunction = removeItemByKeys(
            [...listFunction],
            ['cancelDocuments'],
          )
        } else if (record.status === 2) {
          listFunction = removeItemByKeys(
            [...listFunction],
            ['remove', 'cancelDocuments'],
          )
        }
        return (
          <ContainerFunction className="left-cell">
            <DivListFunction className="listFuntion">
              {listFunction.map((item, indexl) => (
                <DivFunction
                  title={item.title}
                  key={indexl}
                  right={`${indexl * 40}px`}
                >
                  {item.icon}
                </DivFunction>
              ))}
            </DivListFunction>
            <SpanNotYetReleasedTd
              color={
                record.status == 1
                  ? '#019160'
                  : record.status == 2
                    ? '#e61d1d'
                    : '#000000'
              }
              backGroundColor={
                record.status == 1
                  ? '#ebfef7'
                  : record.status == 2
                    ? '#ffcdcd'
                    : '#f1f3f5'
              }
            >
              {record.status == 1
                ? 'Đã phát hành'
                : record.status == 2
                  ? 'Ngừng phát hành'
                  : 'Chưa phát hành'}
            </SpanNotYetReleasedTd>
          </ContainerFunction>
        )
      },
    },
  ]

  //   useEffect(() => {

  //   }, [dataSource])

  return (
    <>
      <ContainerSearch>
        <div className="pt-4 row">
          <div className="col-sm-6">
            <TitleRight>
              {t('taxDeductionDocuments.titleSearchRight')}
            </TitleRight>
          </div>
          <DivRight className="col-sm-6">
            <ButtonSave
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                navigate(`/hoa-don-dau-ra/chung-tu-tncn/thiet-lap/mau/them`)
              }}
            >
              {t('taxDeductionDocuments.add')}
            </ButtonSave>
          </DivRight>
        </div>
        <ContainerTable>
          <TableCustom
            dataSource={dataSource}
            columns={columns}
            locale={{
              emptyText: <Empty description={t('taxDeduction.noData')} />,
            }}
            marginRight={'0px'}
            marginLeft={'0px'}
            pagination={
              panningDataSource.totalPages > 1
                ? {
                    current: panningDataSource.page,
                    pageSize: panningDataSource.limit,
                    total: panningDataSource.totalItems,
                  }
                : false
            }
            onChange={(pagination) => {
              setPanningDataSource({
                ...pagination,
                page: pagination.current,
                limit: pagination.pageSize,
              })
            }}
          />
        </ContainerTable>
      </ContainerSearch>
      <AlertTaxDectionDocumentsUpdate
        titleCancel={t('taxDeductionDocuments.no')}
        titlePrimary={t('taxDeductionDocuments.remove')}
        openModalAlert={openModalAlertTemplateRemove}
        titleAlert={t('taxDeductionDocuments.titlerRemoveTemplate')}
        messageAlert={t('taxDeductionDocuments.messageRemoveTemplate')}
        setOpenModalAlert={(value) => {
          setOpenModalAlertTemplateRemove(value)
        }}
        onClickPrimary={() => {
          remove()
        }}
        colorButtonSave={'#ffffff'}
        backGroundButtonSave={'#e61d1d'}
        backGroundButtonSaveHover={'#cc0202!important'}
      />

      {/* cancel document */}
      <AlertTaxDectionDocumentsUpdate
        titleCancel={t('taxDeductionDocuments.cancel')}
        titlePrimary={t('taxDeductionDocuments.canceld')}
        openModalAlert={visibleCancel}
        titleAlert={t('taxDeduction.notification')}
        setOpenModalAlert={(value) => {
          setVisibleCancel(value)
        }}
        onClickPrimary={() => {
          cancel()
        }}
        colorButtonSave={'#ffffff'}
        backGroundButtonSave={'#e61d1d'}
        backGroundButtonSaveHover={'#cc0202!important'}
        messageAlert={t('taxDeduction.messageCancel')}
        nameActive={dataActive?.code}
      />
    </>
  )
}
export default SearchTaxDeductionDocuments
