import { default as SelectOptions } from 'general/constants/SelectOptions'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
import _ from 'lodash'

export const defaultValues = (serials) => [
  // {
  //   label: 'Mã số thuế',
  //   alias: 'Mã số thuế',
  //   formName: 'taxCode',
  //   // options: serials,
  //   value: null,
  //   // multiselect: true,
  //   colSpan: 'FULL',
  //   restrict: {
  //     type: 'string',
  //   },
  // },
  {
    label: 'Ký hiệu',
    alias: 'Ký hiệu',
    formName: 'serials',
    options: serials,
    value: _.isEmpty(serials) ? null : (serials[0]?.value ?? null),
    multiselect: true,
  },
  {
    label: 'Loại hóa đơn',
    alias: 'Loại hóa đơn',
    formName: 'classify',
    options: SelectOptions.invoiceClassify,
    value: null,
  },
  {
    label: ['Hoá đơn từ số', 'Đến số'],
    formName: ['fromNumberInvoice', 'toNumberInvoice'],
    alias: 'Số hoá đơn: Từ',
    separator: 'đến',
    value: [null, null],
    restrict: {
      type: 'number',
      min: 1,
      max: 99999999,
    },
  },
  {
    label: 'Mã số thuế',
    alias: 'Mã số thuế',
    formName: 'sellerTaxCode',
    placeholder: 'Nhập mã số thuế người bán',
    value: null,
  },
  {
    label: 'Trạng thái phát hành',
    alias: 'Trạng thái HĐ',
    formName: 'issueStatus',
    options: SelectOptions.issueStatus,
    value: null,
  },
  {
    alias: 'Trạng thái gửi CQT',
    label: 'Trạng thái gửi CQT',
    formName: 'sendTaxStatus',
    value: null,
    options: SelectOptions.sendTaxStatus,
  },
  {
    label: 'Loại hoá đơn',
    alias: 'Loại HĐ',
    formName: 'types',
    options: SelectOptions.invoiceType,
    value: null,
  },
  // {
  //   label: 'Tên hàng hóa',
  //   alias: 'Hàng hóa',
  //   formName: 'goodsName',
  //   options: goodsOption,
  //   value: null,
  // },
]

export const filterOptions = [
  {
    label: 'Số hóa đơn',
    value: 'no',
  },
  {
    label: 'Mã tra cứu',
    value: 'lookupCode',
  },
  {
    label: 'Mã CQT',
    value: 'taxAuthorityCode',
  },
  {
    label: 'Mã thông điệp',
    value: 'messageId',
  },
]

// utils
export const renderInvoiceType = (type) => {
  const { color, text } = InvoiceHelper.renderInvoiceType(type)
  return (
    <div className={`badge badge-light-${color}`}>{_.upperFirst(text)}</div>
  )
}
