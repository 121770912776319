import AppConfigs from './AppConfigs'

// All app preference keys
const PreferenceKeys = {
  // token
  accessToken: `${AppConfigs.packageName}_accessToken`,
  qlbhAccessToken: `${AppConfigs.packageName}_qlbhAccessToken`,
  accessTokenExpired: `${AppConfigs.packageName}_accessTokenExpired`,
  // login info
  savedLoginEmail: `${AppConfigs.packageName}_savedLoginEmail`,
  savedLoginTaxCode: `${AppConfigs.packageName}_savedLoginTaxCode`,
  savedLoginPassword: `${AppConfigs.packageName}_savedLoginPassword`,
  savedLoginFullname: `${AppConfigs.packageName}_savedLoginFullname`,
  savedResetPasswordUsername: `${AppConfigs.packageName}_savedResetPasswordUsername`,
  savedResetPasswordTaxCode: `${AppConfigs.packageName}_savedResetPasswordTaxCode`,

  sellerInfoMaxWidth: `${AppConfigs.packageName}_sellerInfoMaxWidth`,
  buyerInfoMaxWidth: `${AppConfigs.packageName}_buyerInfoMaxWidth`,
  invoiceInfoOtherMaxWidth: `${AppConfigs.packageName}_invoiceInfoOtherMaxWidth`,

  // category
  savedColumnsCompanyUnits: `${AppConfigs.packageName}_savedColumnsCompanyUnits`,
  savedColumnsTitle: `${AppConfigs.packageName}_savedColumnsTitle`,
  savedColumnsCustomer: `${AppConfigs.packageName}_savedColumnsCustomer`,
  savedColumnsEmployee: `${AppConfigs.packageName}_savedColumnsEmployee`,
  savedColumnsGoodsGroup: `${AppConfigs.packageName}_savedColumnsGoodsGroup`,
  savedColumnsGoods: `${AppConfigs.packageName}_savedColumnsGoods`,
  savedColumnsMoneyType: `${AppConfigs.packageName}_savedColumnsMoneyType`,
  // report
  savedColumnsUsedInvoice: `${AppConfigs.packageName}_savedColumnsUsedInvoice`,
  savedColumnsDetailInvoice: `${AppConfigs.packageName}_savedColumnsDetailInvoice`,
  listReportTicket: `${AppConfigs.packageName}_listReportTicket`,
  // system
  savedColumnsUser: `${AppConfigs.packageName}_savedColumnsUser`,
  // tranfer history
  savedColumnsTaxMessageRegistration: `${AppConfigs.packageName}_savedColumnsTaxMessageRegistration`,
  savedColumnsRegistration: `${AppConfigs.packageName}_savedColumnsRegistration`,
  savedColumnsInvoiceTemplate: `${AppConfigs.packageName}_savedColumnsInvoiceTemplate`,
  savedColumnsInvoice: `${AppConfigs.packageName}_savedColumnsInvoice`,
  savedColumnsPxk: `${AppConfigs.packageName}_savedColumnsPxk`,
  //Tem vé
  listTemplateTicket: `${AppConfigs.packageName}_listTemplateTicket`,
  listIssusedTicket: `${AppConfigs.packageName}_listIssusedTicket`,
  canceledTicket: `${AppConfigs.packageName}_canceledTicket`,
  routeScreen: `${AppConfigs.packageName}_routeScreen`,
  TvanHandle: `${AppConfigs.packageName}_TvanHandle`,
}

export default PreferenceKeys
